import { ReducersTypes } from "constants/ReducersTypes";
import moment from "moment";
import { getUXSettingsFromStorage } from "utils";
import { getDateByRangeInterval } from "utils/dates";
import { getLocaleString } from "utils/localization/locale";

export const initialCarrierActivityListFilters = {
  page: 1,
  sort_by: "line_price",
  sort_order: "desc",
  from_date: moment().format("MM/DD/YYYY"),
  to_date: moment().format("MM/DD/YYYY"),
  interval: "Today",
  status: "all",
  supplierSelect: null,
};

export const ANALYTICS_CARRIER_ACTIVITY_COLUMNS = {
  [getLocaleString("vendor.activity_number", "Activity Number")]: true,
  [getLocaleString("carrieractivity.activity_date", "Activity Date")]: true,
  [getLocaleString("carrieractivity.approved_date", "Approved Date")]: false,
  [getLocaleString("carrieractivity.reject_date", "Reject Date")]: false,
  [getLocaleString("data_refresh.supplier", "Supplier")]: false,
  [getLocaleString("vendor.driver_name", "Driver Name")]: true,
  [getLocaleString("vendor.service_description", "Service Description")]: true,
  [getLocaleString("vendor.service_code", "Service Code")]: true,
  [getLocaleString("vendor.dispatch_type", "Dispatch Type")]: true,
  [getLocaleString("vendor.container_chassis", "Container/Chassis")]: true,
  [getLocaleString("vendor.pickup_location", "Pickup Location")]: true,
  [getLocaleString("vendor.delivery_location", "Delivery Location")]: true,
  [getLocaleString("common.amount", "Amount")]: true,
  [getLocaleString("vendor.comments", "Comments")]: false,
  [getLocaleString("vendor.pickup_date", "Pickup Date")]: false,
  [getLocaleString("common.status", "Status")]: true,
};

let savedFilters = getUXSettingsFromStorage(
  "ANALYTICS_CARRIER_ACTIVITY_FILTERS"
);
if (savedFilters && savedFilters.interval) {
  const { interval, from_date, to_date } = savedFilters;
  const [sd, ed] = getDateByRangeInterval(interval, from_date, to_date);
  savedFilters = {
    ...savedFilters,
    from_date: sd,
    to_date: ed,
  };
}

const initialState = {
  activityList: [],
  isFetching: false,
  filters: {
    page: 1,
    label: "",
    from_date: moment().format("MM/DD/YYYY"),
    to_date: moment().format("MM/DD/YYYY"),
    status: "all",
    client_supplier_rel_id: "",
    ...savedFilters,
  },
  exportedData: [],
  showexport: false,
  exporting: false,
};

const carrierActivity = (state = initialState, { type, payload }) => {
  switch (type) {
    case ReducersTypes.SET_CARRIER_ACTIVITY_LIST:
      return {
        ...state,
        ...payload.data,
      };
    case ReducersTypes.SET_CARRIER_ACTIVITY_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload.data,
        },
      };
    case ReducersTypes.RESET_CARRIER_ACTIVITY:
      return {
        ...initialState,
        filters: {
          ...initialState.filters,
        },
      };
    default:
      return state;
  }
};

export default carrierActivity;
