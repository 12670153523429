export const MIMETYPE = {
  jpg: "jpg",
  jpeg: "jpeg",
  png: "png",
  tif: "tiff",
  tiff: "tiff",
  "image/png": "image/png",
  "image/jpg": "image/jpg",
  "image/jpeg": "image/jpeg",
  pdf: "pdf",
  "application/pdf": "pdf",
  doc: "msword",
  docx: "msword",
  ppt: "ppt",
  pptx: "pptx",
  txt: "txt",
  text: "text",
  rtf: "rtf",
};

export const EXTENSIONS = {
  jpg: "jpg",
  jpeg: "jpeg",
  png: "png",
  tif: "tif",
  tiff: "tiff",
  "image/png": "png",
  "image/jpg": "jpg",
  "image/jpeg": "jpeg",
  pdf: "pdf",
  "application/pdf": "pdf",
  doc: "doc",
  docx: "docx",
  ppt: "ppt",
  pptx: "pptx",
  txt: "txt",
  text: "text",
  rtf: "rtf",
};

export const SUPPORTED_FILETYPES = [
  "jpg",
  "image/jpeg",
  "jpeg",
  "image/jpeg",
  "png",
  "image/png",
  "pdf",
  "application/pdf",
  "txt",
  "text",
];
