import { instanceWithRetry } from "actions/axiosInstance";
import Toaster from "components/Toaster";
import { ReducersTypes } from "constants/ReducersTypes";
import get from "lodash/get";
import { handleAPIError } from "./setters";
import {
  columnWidthCustomer,
  CUSTOMER_LIST_COLUMNS,
  initialCustomerListFilters,
  UNAPPLIED_PAYMENTS_CREDIT_FLAGS,
  CUSTOMER_INVOICE_DETAILS_COLUMNS,
  CUSTOMER_LIST_COLUMNS_ORDER,
} from "containers/customers/listnew/reducer.js";
import {
  TASK_LIST_COLUMNS,
  initialDashboardTaskColumnsOrder,
  initialTaskFilters,
} from "containers/dashboard/reducer";
import {
  REPORTS_PAYMENT_COLUMNS,
  REPORTS_PAYMENT_COLUMNS_ORDER,
} from "containers/analytics-report/Payments/reducer";
import {
  columnWidthVendor,
  BILL_CREDIT_FLAGS,
  supplierListColumns,
  supplierListColumnOrder,
} from "containers/vendors/list/reducer";
import { initialVendorListFilters } from "containers/vendors/list/filter";
import {
  initialJournalFilter,
  INITIAL_JOURNAL_COLUMNS_ORDER,
} from "containers/journal-entries/reducer";
import { initialEmailListFilters } from "containers/analytics-report/Email/reducer";
import { initialActivityFilters } from "containers/analytics-report/Activity/Activity";
import { initialPaymentListFilters } from "containers/analytics-report/Payments/FilterNExport";
import {
  initialPayrollFilters,
  PAYROLL_LIST_COLUMN_ORDER,
} from "containers/payroll/PayrollList";
import {
  initialCustomerDetailsFilters,
  CUSTOMER_AR_PAYMENT_COLUMNS,
  CUSTOMER_INVOICE_LIST_COLUMNS_ORDER,
  CUSTOMER_DETAILS_CREDIT_MEMO_COLUMNS_ORDER,
  CUSTOMER_AR_PAYMENT_COLUMNS_ORDER,
  CUSTOMER_DEPOSIT_COLUMNS_ORDER,
} from "containers/customers/reducer";
import {
  INITIAL_PAYABLE_LIST_COLUMNS_ARRANGEMENT,
  PAYABLE_LIST_COLUMNS,
} from "containers/payables/reducer";
import {
  RECEIVABLE_LIST_COLUMNS,
  RECEIVABLE_LIST_COLUMNS_ORDER,
} from "containers/receivables/reducer";
import { NOTES_LIST_COLUMNS } from "containers/analytics-report/Notes/reducer";
import uniqBy from "lodash/uniqBy";
import { store } from "store";
import TokenManager from "utils/TokenManager";
import {
  initialNotesListFilters,
  NOTES_LIST_COLUMNS_ORDER,
} from "containers/analytics-report/Notes/reducer";
import { initialSmsListFilters } from "containers/analytics-report/Sms/reducer";
import {
  initialCarrierActivityListFilters,
  ANALYTICS_CARRIER_ACTIVITY_COLUMNS,
} from "containers/analytics-report/CarrierActivity/reducer";
import {
  REPORTS_PHONE_COLUMNS_ORDER,
  initialCallListFilters,
} from "containers/analytics-report/Phone/reducer";
import {
  VENDOR_DETAILS_INVOICE_LIST_COLUMNS,
  VENDOR_DETAILS_CREDIT_LIST_COLUMNS,
} from "containers/vendors/details/reducer";
import { BACKLOG_LIST_COLUMNS, BACKLOG_LIST_COLUMNS_ORDER, initialBacklogFilters } from "containers/backlog/reducer";
import { JOURNAL_LIST_COLUMNS } from "containers/journal-entries/reducer";
import { initialReceivablesFilters } from "containers/receivables/reducer";
import { initialPayablesFilters } from "containers/payables/reducer";
import { initialCreditApplicationFilters } from "containers/analytics-report/CreditApplication/CreditApplicationActivity";
import omit from "lodash/omit";
import { initialPurchaseOrderColumnOrder } from "containers/purchaseOrder/reducer";
import {
  VENDOR_DETAILS_CREDIT_COLUMNS_ORDER,
  VENDOR_DETAILS_INVOICE_LIST_COLUMNS_ORDER,
  VENDOR_DETAILS_NONINVOICE_LIST_COLUMNS_ORDER,
} from "containers/vendors/details/reducer";
import { initialFilters } from "containers/cash_application/cash_application";

const FILTERS_CONSTANT = [
  "OB_FILTERS",
  "PO_FILTERS",
  "NON_AP_FILTERS",
  "PAYABLE_FILTERS",
  "TASK_LIST_FILTERS",
  "RECEIVABLE_FILTERS",
  "EXPORT_ALL_COLUMNS",
  "PAYROLL_LIST_FILTERS",
  "BANK_ACCOUNT_FILTERS",
  "SUPPLIER_LIST_FILTERS",
  "ANALYTICS_SMS_FILTERS",
  "INSIGHTS_GRAPH_FILTERS",
  "CASH_APPLICATION_FILES",
  "JOURNAL_INFLOW_FILTERS",
  "ANALYTICS_CALL_FILTERS",
  "JOURNAL_OUTFLOW_FILTERS",
  "ANALYTICS_EMAIL_FILTERS",
  "ANALYTICS_NOTES_FILTERS",
  "CUSTOMER_LISTING_FILTERS",
  "SUPPLIER_DETAILS_FILTERS",
  "CUSTOMER_DETAILS_FILTERS",
  "ANALYTICS_ACTIVITY_FILTERS",
  "ANALYTICS_PAYMENTS_FILTERS",
  "COMPARE_SCENARIO_SELECTION",
  "ANALYTICS_CREDIT_APPLICATION",
  "PAYABLES_SELECTED_CUSTOM_FILTERS",
  "SUPPLIER_DETAILS_NON_INV_FILTERS",
  "ANALYTICS_CARRIER_ACTIVITY_FILTERS",
  "RECEIVABLE_SELECTED_CUSTOM_FILTERS",
];

const columnsConstantAndDataMap = {
  SUPPLIER_LIST_COLUMNS: supplierListColumns,
  NOTES_LIST_COLUMNS,
  PAYABLE_LIST_COLUMNS,
  RECEIVABLE_LIST_COLUMNS,
  CUSTOMER_LIST_COLUMNS,
  UNAPPLIED_PAYMENTS_CREDIT_FLAGS,
  BILL_CREDIT_FLAGS,
  REPORTS_PAYMENT_COLUMNS,
  ANALYTICS_CARRIER_ACTIVITY_COLUMNS,
  VENDOR_DETAILS_INVOICE_LIST_COLUMNS,
  VENDOR_DETAILS_CREDIT_LIST_COLUMNS,
  BACKLOG_LIST_COLUMNS,
  TASK_LIST_COLUMNS,
  CUSTOMER_INVOICE_DETAILS_COLUMNS,
  CUSTOMER_AR_PAYMENT_COLUMNS,
  JOURNAL_LIST_COLUMNS,
  JOURNAL_COLUMNS_ORDER: INITIAL_JOURNAL_COLUMNS_ORDER,
  PAYABLE_LIST_COLUMNS_ORDER: INITIAL_PAYABLE_LIST_COLUMNS_ARRANGEMENT,
  RECEIVABLE_LIST_COLUMNS_ORDER: RECEIVABLE_LIST_COLUMNS_ORDER,
  SUPPLIER_LIST_COLUMNS_ORDER: supplierListColumnOrder,
  DASHBOARD_TASK_COLUMNS_ORDER: initialDashboardTaskColumnsOrder,
  PO_LIST_COLUMNS_ORDER: initialPurchaseOrderColumnOrder,
  NOTES_LIST_COLUMNS_ORDER,
  PAYROLL_LIST_COLUMN_ORDER,
  BACKLOG_LIST_COLUMNS_ORDER,
};

const AVAILABLE_UX_CONSTS = [
  "EMAIL_TEMPLATES",
  "LAST_ACTIVE_PATH",
  "DASHBOARD_GRAPHS",
  "DASHBOARD_SIDEBAR",
  "TASK_LIST_COLUMNS",
  "BILL_CREDIT_FLAGS",
  "NOTES_LIST_COLUMNS",
  "SHOW_CUSTOMER_ROLE",
  "TABLE_COLUMN_WIDTHS",
  "JOURNAL_LIST_COLUMNS",
  "BACKLOG_LIST_COLUMNS",
  "PAYABLE_LIST_COLUMNS",
  "CUSTOMER_LIST_COLUMNS",
  "SUPPLIER_LIST_COLUMNS",
  "CASHFLOW_AP_COLLAPSED",
  "CASHFLOW_AR_COLLAPSED",
  "CUSTOMER_LISTING_PAGE",
  "RECEIVABLE_LIST_COLUMNS",
  "REPORTS_PAYMENT_COLUMNS",
  "CASHFLOW_SIDEBAR_EXPANDED",
  "NAVIGATION_PANEL_EXPANDED",
  "CUSTOMER_AR_PAYMENT_COLUMNS",
  "SETTINGS_ACCORDIAN_BANK_ACCOUNT",
  "UNAPPLIED_PAYMENTS_CREDIT_FLAGS",
  "CUSTOMER_INVOICE_DETAILS_COLUMNS",
  "CUSTOMER_DETAILS_SIDEBAR_EXPANDED",
  "SUPPLIER_DETAILS_SIDEBAR_EXPANDED",
  "ANALYTICS_CARRIER_ACTIVITY_COLUMNS",
  "VENDOR_DETAILS_INVOICE_LIST_COLUMNS",
  "VENDOR_DETAILS_CREDIT_LIST_COLUMNS",
  "PAYABLE_LIST_COLUMNS_ORDER",
  "RECEIVABLE_LIST_COLUMNS_ORDER",
  "JOURNAL_COLUMNS_ORDER",
  "DASHBOARD_TASK_COLUMNS_ORDER",
  "PO_LIST_COLUMNS_ORDER",
  "SUPPLIER_LIST_COLUMNS_ORDER",
  "CUSTOMER_LIST_COLUMNS_ORDER",
  "NOTES_LIST_COLUMNS_ORDER",
  "REPORTS_PAYMENT_COLUMNS_ORDER",
  "CUSTOMER_INVOICE_LIST_COLUMNS_ORDER",
  "CUSTOMER_DETAILS_CREDIT_MEMO_COLUMNS_ORDER",
  "CUSTOMER_AR_PAYMENT_COLUMNS_ORDER",
  "CUSTOMER_DEPOSIT_COLUMNS_ORDER",
  "PAYROLL_LIST_COLUMN_ORDER",
  "VENDOR_DETAILS_INVOICE_LIST_COLUMNS_ORDER",
  "VENDOR_DETAILS_NONINVOICE_LIST_COLUMNS_ORDER",
  "VENDOR_DETAILS_CREDIT_COLUMNS_ORDER",
  "REPORTS_PHONE_COLUMNS_ORDER",
  "BACKLOG_LIST_COLUMNS_ORDER",
  ...FILTERS_CONSTANT,
];

export const AVAILABLE_UX_DATA = {
  OB_FILTERS: initialBacklogFilters,
  PO_FILTERS: "null",
  NON_AP_FILTERS: "null",
  EMAIL_TEMPLATES: {
    CUSTOMER_EMAIL_TEMPLATE: {
      name: "null",
      body: "null",
      subject: "null",
    },
    VENDOR_EMAIL_TEMPLATE: {
      name: "null",
      body: "null",
      subject: "null",
    },
  },
  PAYABLE_FILTERS: initialPayablesFilters,
  LAST_ACTIVE_PATH: "/",
  DASHBOARD_GRAPHS: "null",
  RECEIVABLE_FILTERS: initialReceivablesFilters,
  DASHBOARD_SIDEBAR: "0",
  DASHBOARD_TASK_COLUMNS_ORDER: initialDashboardTaskColumnsOrder,
  NOTES_LIST_COLUMNS: NOTES_LIST_COLUMNS,
  PAYABLE_LIST_COLUMNS: PAYABLE_LIST_COLUMNS,
  PAYABLE_LIST_COLUMNS_ORDER: INITIAL_PAYABLE_LIST_COLUMNS_ARRANGEMENT,
  CASHFLOW_AP_COLLAPSED: "null",
  CASHFLOW_AR_COLLAPSED: "null",
  SHOW_CUSTOMER_ROLE: "0",
  CUSTOMER_LIST_COLUMNS: CUSTOMER_LIST_COLUMNS,
  CUSTOMER_INVOICE_DETAILS_COLUMNS: CUSTOMER_INVOICE_DETAILS_COLUMNS,
  UNAPPLIED_PAYMENTS_CREDIT_FLAGS: UNAPPLIED_PAYMENTS_CREDIT_FLAGS,
  BILL_CREDIT_FLAGS: BILL_CREDIT_FLAGS,
  BACKLOG_LIST_COLUMNS: BACKLOG_LIST_COLUMNS,
  SUPPLIER_LIST_COLUMNS: supplierListColumns,
  RECEIVABLE_LIST_COLUMNS: RECEIVABLE_LIST_COLUMNS,
  RECEIVABLE_LIST_COLUMNS_ORDER: RECEIVABLE_LIST_COLUMNS_ORDER,
  NAVIGATION_PANEL_EXPANDED: "0",
  CASHFLOW_SIDEBAR_EXPANDED: "1",
  CUSTOMER_DETAILS_SIDEBAR_EXPANDED: "0",
  SUPPLIER_DETAILS_SIDEBAR_EXPANDED: "0",
  SETTINGS_ACCORDIAN_BANK_ACCOUNT: "null",
  JOURNAL_INFLOW_FILTERS: initialJournalFilter,
  JOURNAL_OUTFLOW_FILTERS: initialJournalFilter,
  REPORTS_PAYMENT_COLUMNS: REPORTS_PAYMENT_COLUMNS,
  TASK_LIST_COLUMNS: TASK_LIST_COLUMNS,
  TABLE_COLUMN_WIDTHS: {
    CUSTOMER_LISTING_COLUMN_WIDTH: columnWidthCustomer,
    VENDOR_LISTING_COLUMN_WIDTH: columnWidthVendor,
  },
  CUSTOMER_LISTING_FILTERS: initialCustomerListFilters,
  TASK_LIST_FILTERS: initialTaskFilters,
  CUSTOMER_LISTING_PAGE: 1,
  SUPPLIER_LIST_FILTERS: initialVendorListFilters,
  SUPPLIER_DETAILS_NON_INV_FILTERS: "null",
  SUPPLIER_DETAILS_FILTERS: "null",
  CUSTOMER_DETAILS_FILTERS: initialCustomerDetailsFilters,
  PAYROLL_LIST_FILTERS: initialPayrollFilters,
  ANALYTICS_EMAIL_FILTERS: initialEmailListFilters,
  ANALYTICS_ACTIVITY_FILTERS: initialActivityFilters,
  ANALYTICS_PAYMENTS_FILTERS: initialPaymentListFilters,
  ANALYTICS_NOTES_FILTERS: initialNotesListFilters,
  ANALYTICS_CALL_FILTERS: initialCallListFilters,
  ANALYTICS_SMS_FILTERS: initialSmsListFilters,
  ANALYTICS_CARRIER_ACTIVITY_FILTERS: initialCarrierActivityListFilters,
  ANALYTICS_CARRIER_ACTIVITY_COLUMNS,
  VENDOR_DETAILS_INVOICE_LIST_COLUMNS,
  VENDOR_DETAILS_CREDIT_LIST_COLUMNS,
  CUSTOMER_AR_PAYMENT_COLUMNS,
  JOURNAL_LIST_COLUMNS,
  ANALYTICS_CREDIT_APPLICATION: initialCreditApplicationFilters,
  JOURNAL_COLUMNS_ORDER: INITIAL_JOURNAL_COLUMNS_ORDER,
  PO_LIST_COLUMNS_ORDER: initialPurchaseOrderColumnOrder,
  REPORTS_PAYMENT_COLUMNS_ORDER,
  CUSTOMER_INVOICE_LIST_COLUMNS_ORDER: CUSTOMER_INVOICE_LIST_COLUMNS_ORDER,
  CUSTOMER_LIST_COLUMNS_ORDER,
  CUSTOMER_DETAILS_CREDIT_MEMO_COLUMNS_ORDER,
  CUSTOMER_AR_PAYMENT_COLUMNS_ORDER,
  CUSTOMER_DEPOSIT_COLUMNS_ORDER,
  PAYROLL_LIST_COLUMN_ORDER,
  VENDOR_DETAILS_INVOICE_LIST_COLUMNS_ORDER:
    VENDOR_DETAILS_INVOICE_LIST_COLUMNS_ORDER,
  VENDOR_DETAILS_NONINVOICE_LIST_COLUMNS_ORDER,
  VENDOR_DETAILS_CREDIT_COLUMNS_ORDER,
  REPORTS_PHONE_COLUMNS_ORDER,
  BACKLOG_LIST_COLUMNS_ORDER,
  CASH_APPLICATION_FILES: initialFilters,
};

export const ux_reset_filters = () => {
  const resetFilters = {
    ...FILTERS_CONSTANT.reduce(
      (acc, cur) => ({
        ...acc,
        [cur]: "null",
      }),
      {}
    ),
    ...columnsConstantAndDataMap,
  };
  return Promise.resolve(
    store.dispatch({
      type: ReducersTypes.SET_UX,
      payload: resetFilters,
    })
  );
};

export const ux_reset_columns = () => {
  store.dispatch({
    type: ReducersTypes.SET_UX,
    payload: Object.keys(columnsConstantAndDataMap).reduce((acc, cur) => {
      return {
        ...acc,
        [cur]: columnsConstantAndDataMap[cur],
      };
    }, {}),
  });
};

export const ux_refresh = () => {
  instanceWithRetry.get("user/settings/ux").then((resp) => {
    const data = {
      data_from_api: uniqBy(
        get(resp, "data.result.user_interface_setting", []),
        "ux_type"
      ),
    };
    uniqBy(get(resp, "data.result.user_interface_setting", []), "ux_type")
      .filter((x) => x.ux_type)
      .forEach((obj) => {
        data[obj.ux_type] = obj.value;
      });
    store.dispatch({
      type: ReducersTypes.SET_UX,
      payload: {
        ...AVAILABLE_UX_DATA,
        ...data,
      },
    });
  });
};

export const call_arlisting_api = () => {
  instanceWithRetry
    .get("arrep")
    .then((response) => {
      store.dispatch({
        type: ReducersTypes.SET_UX,
        payload: { AR_REP: response.data.result },
      });
    })
    .catch((e) => {
      handleAPIError(e);
    });
};

export const check_ux_properties = () => {
  if (
    !TokenManager.isCustomer() &&
    !TokenManager.getIsAP_REP() &&
    !TokenManager.isVendor()
  ) {
    call_arlisting_api();
  }
  const _store = store.getState();
  const ux_data = get(_store, "ux", []) || [];
  const uxConstsData = AVAILABLE_UX_CONSTS.reduce((acc, cur) => {
    if (!ux_data.hasOwnProperty(cur)) {
      acc = {
        ...acc,
        [cur]: AVAILABLE_UX_DATA[cur],
      };
    }
    return acc;
  }, {});

  const columnsData = Object.entries(columnsConstantAndDataMap).reduce(
    (acc, cur) => {
      const [key, value] = cur;
      if (ux_data && ux_data[key] && ux_data[key] !== " " && value) {
        acc = {
          ...acc,
          ...checkforColumns(key, value, ux_data),
        };
      }
      return acc;
    },
    {}
  );
  store.dispatch({
    type: ReducersTypes.SET_UX,
    payload: {
      ...columnsData,
      ...uxConstsData,
    },
  });
};

export const checkforColumns = (columnProperty, columnDefination, data) => {
  let COLUMNS_LIST = { ...data[columnProperty] };
  let finalColumns = { ...columnDefination };
  const currentColumnList = Object.keys(COLUMNS_LIST).map((v) =>
    v.toLocaleLowerCase()
  );
  Object.keys(columnDefination).forEach((column) => {
    const columnLower = column.toLocaleLowerCase();
    if (!currentColumnList.includes(columnLower)) {
      finalColumns[column] = columnDefination[column];
    } else {
      finalColumns[column] = COLUMNS_LIST[column];
      COLUMNS_LIST = omit(COLUMNS_LIST, [column]);
    }
  });
  return {
    [columnProperty]: { ...finalColumns, ...COLUMNS_LIST },
  };
};

export const ux_setbool = (key) => {
  if (!AVAILABLE_UX_CONSTS.includes(key)) {
    Toaster("used invalid ux constant");
    return;
  }
  let _value = getuxvalue(key);
  _value = ux_istrue(_value);
  _value = _value ? "0" : "1";

  if (getuxvalue(key) === _value) {
    return;
  }
  store.dispatch({
    type: ReducersTypes.SET_UX,
    payload: { [key]: _value },
  });
};

export const setcolumndata = (key, value) => {
  const colData = { ...getuxvalue(key[0]), [key[1]]: value };
  value = colData;
  store.dispatch({
    type: ReducersTypes.SET_UX,
    payload: { [key[0]]: value },
  });
};

export const setuxvalue = (key, value) => {
  if (!AVAILABLE_UX_CONSTS.includes(key)) {
    Toaster("Used invalid ux constant", "error");
    return;
  }

  store.dispatch({
    type: ReducersTypes.SET_UX,
    payload: { [key]: value },
  });
};

export const getuxvalueapi = (key, value) => {
  const uxid = getuxid(key);
  return uxid
    ? instanceWithRetry.post("user/settings/ux?mutate=1", {
        user_interface_setting: { value: value || "", id: uxid },
      })
    : instanceWithRetry.post("user/settings/ux?mutate=1", {
        user_interface_setting: {
          ux_type: key,
          value: value || "null",
        },
      });
};

export const resetux = () => {
  store.dispatch({
    type: ReducersTypes.RESET_UX,
  });
};

export const uxempty = () => {
  const _store = store.getState();
  return !get(_store, `ux.data_from_api`, "");
};

export const ux_istrue = (value) =>
  value === 1 || value === "1" ? true : false;

export const getuxvalue = (key) => {
  const _store = store.getState();
  const uxDataLocal = get(_store, "ux", {});
  return uxDataLocal[key] === "null" ||
    uxDataLocal[key] === " " ||
    uxDataLocal[key] === "{}"
    ? null
    : uxDataLocal[key];
};

export const getuxid = (key) => {
  try {
    const _store = store.getState();
    const data_from_api = get(_store, `ux.data_from_api`, []);
    return get(
      data_from_api.find((x) => x.ux_type === key),
      "id",
      ""
    );
  } catch (e) {
    return null;
  }
};

export const syncUserSettings = (refetchSettings = true) => {
  if (uxempty()) {
    return Promise.resolve();
  }
  const _store = store.getState();
  const uxDataLocal = get(_store, "ux", {});
  const uxDataFromAPI = get(_store, `ux.data_from_api`, "");
  const current_path = `${window.location.pathname}${window.location.hash}`;
  const requestObjectExisting = uxDataFromAPI.map((d) => {
    return {
      id: d.id,
      value:
        d.ux_type === "LAST_ACTIVE_PATH"
          ? current_path
          : uxDataLocal[d.ux_type],
    };
  });
  const excludeKeys = ["data_from_api", "AR_REP"];
  const requestObjectNewUXKey = Object.keys(uxDataLocal)
    .filter((d) => !excludeKeys.includes(d))
    .filter((d) => !uxDataFromAPI.some((e) => e.ux_type === d));
  const requestObjectNew = requestObjectNewUXKey
    ? requestObjectNewUXKey.map((key) => ({
        ux_type: key,
        value: uxDataLocal[key] || "null",
      }))
    : [];

  return instanceWithRetry
    .post("user/settings/ux?mutate=1", {
      user_interface_setting: [...requestObjectExisting, ...requestObjectNew],
    })
    .then((res) => {
      if (refetchSettings) {
        ux_refresh();
      }
      return res;
    })
    .catch((e) => {
      handleAPIError(e);
      return;
    });
};
