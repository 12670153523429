import { ReducersTypes } from "constants/ReducersTypes";
export const initUserState = {
  showFilter: false,
  resetFilter: false,
  userFlag: null,
  currPage: 1,
  filters: {
    status: "",
    role_ids: "",
    id: "",
  },
  usermodel: {
    id: 0,
    name: "",
    department: "",
    role: [],
    entities: [],
    email: "",
  },
  isFetching: false,
  addInProgress: false,
  selectedEntities: {},
  userData: [],
  usedCustomerInitials: [],
  selectedCustomer: [],
  selectedTableUser: [],
  userPassword: {
    newPassword: "",
    confirmPassword: "",
  },
  showError: false,
  isProcessing: false,
  selectedUser: null,
};

export const settingsUser = (state = { ...initUserState }, action) => {
  switch (action.type) {
    case ReducersTypes.FETCH_USERS_LIST:
      return {
        ...state,
        userData: action.payload.userData || state.userData,
        usedCustomerInitials:
          action.payload.usedCustomerInitials || state.usedCustomerInitials,
        selectedEntities:
          action.payload.selectedEntities || state.selectedEntities,
        isFetching: action.payload.isFetching,
        showFilter: action.payload.showFilter,
      };
    case ReducersTypes.SET_USER_STATE:
      let data = {};
      for (const [key, value] of Object.entries(action.payload)) {
        data[key] = value;
      }
      data = { ...state, ...data };
      return data;

    case ReducersTypes.SET_USER_FILTER:
      return {
        ...state,
        filters: action.payload,
      };
    case ReducersTypes.SET_ADD_USER_DATA:
      return {
        ...state,
        usermodel: action.payload,
      };
    default:
      return state;
  }
};
export default settingsUser;
