import { instance } from "actions/axiosInstance";
import { handleAPIError } from "handlers/setters";
import { ReducersTypes } from "constants/ReducersTypes";
import get from "lodash/get";
import { generateQueryString } from "utils/query";
import { trimText } from "utils/strings";

const setData = (type, data) => ({
  type,
  payload: {
    data,
  },
});

export const exportData = (params) => (dispatch) => {
  const association =
    params.association === "Customer" ? "customer" : "supplier";
  const URL = `${association}/note${generateQueryString({
    ...params,
    association: "",
  })}`;
  dispatch(setData(ReducersTypes.SET_NOTES_LIST, { exporting: true }));
  return instance.get(URL);
};

export const getNotesList = (params) => (dispatch) => {
  const association =
    params.association === "Customer" ? "customer" : "supplier";
  const URL = `${association}/note${generateQueryString({
    ...params,
    association: "",
  })}`;
  dispatch(setData(ReducersTypes.SET_NOTES_LIST, { isFetching: true }));
  instance
    .get(URL)
    .then((response) => {
      dispatch(
        setData(ReducersTypes.SET_NOTES_LIST, {
          isFetching: false,
          listData: get(response, "data.result", []),
          total: get(response, "data.total"),
          pagination: {
            next_num: get(response, "data.next_num", 0),
            totalPages: get(response, "data.pages", 0),
            prev_num: get(response, "data.prev_num", 0),
          },
        })
      );
    })
    .catch((e) => {
      dispatch(
        setData(ReducersTypes.SET_NOTES_LIST, {
          isFetching: false,
        })
      );
      if (e) {
        handleAPIError(e);
      }
    });
};

export const getUsersList = (text, setIsFetching) => (dispatch) => {
  const URL = `client/user?search=${trimText(text)}`;
  if (text) {
    setIsFetching(true);
    instance
      .get(URL)
      .then((res) => {
        setIsFetching(false);
        dispatch(
          setData(ReducersTypes.SET_NOTES_LIST, {
            usersList: res.data.result,
          })
        );
      })
      .catch((e) => handleAPIError(e));
  } else {
    setIsFetching(false);
    dispatch(
      setData(ReducersTypes.SET_NOTES_LIST, {
        usersList: [],
      })
    );
  }
};

export const resetFilter = (filters) => (dispatch) => {
  dispatch(setData(ReducersTypes.RESET_NOTES_LIST, { ...filters }));
};

export const setNotesData = (data, reducertype) => (dispatch) => {
  dispatch(setData(reducertype, data));
};
