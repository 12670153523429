import React from "react";
import get from "lodash/get";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Toaster from "components/Toaster";
import { RenderTemplate3 } from "components/InvoicingTemplates/Template3";
import {
  setconfirmationboxData,
  resetconfirmationboxData,
} from "components/ConfirmationBox";
import {
  getInvoicingFields,
  updateInvoicingFields,
  deleteInvocingFields,
} from "containers/settings/payables/actions";
import Button from "components/Button";

export const invoiceTemplates = [
  { name: "Default template", id: 1 },
  { name: "Basic template", id: 2 },
  { name: "User's custom template", id: 3 },
  { name: "Advanced Invoice Template", id: 4 },
  { name: "Commercial Invoice Template", id: 5 },
  { name: "Medical Invoice Template", id: 6 },
  { name: "Standard Invoice Template", id: 7 },
  { name: "POS Sale Invoice Template", id: 8 },
  { name: "Cash Sale Memo Template", id: 9 },
  { name: "Goods Supplier Invoice Template", id: 10 },
  { name: "Logistics Invoice Template", id: 11 },
  { name: "Freight Carrier Invoice Template", id: 12 },
  { name: "Serrala Custom Template", id: 13 },
];

const fieldTypes = [
  { name: "", type: "String", id: 1 },
  { name: "", type: "Numeric", id: 2 },
  { name: "", type: "Free Text", id: 3 },
  { name: "", type: "Date", id: 4 },
  { name: "", type: "Dropdown", id: 5 },
  { name: "", type: "Checkbox", id: 6 },
  { name: "", type: "Radio", id: 7 },
];

const paymentTerms = [
  { name: "Default payment terms", id: 1 },
  { name: "Basic payment terms", id: 2 },
  { name: "User's custom payment terms", id: 3 },
  { name: "Detailed payment terms", id: 4 },
];
const defaultFormFields = {
  1: [
    { type: 1, id: 1, name: "Customer Name*" },
    { type: 5, id: 2, name: "Payment Terms", options: paymentTerms },
    { type: 1, id: 3, name: "Invoice Number*" },
    { type: 1, id: 4, name: "P.O. Number" },
    { type: 1, id: 5, name: "Sales Rep" },
    { type: 4, id: 6, name: "Invoice Date*" },
    { type: 4, id: 7, name: "Due Date*" },
  ],
  2: [
    { type: 1, id: 1, name: "Customer Name*" },
    { type: 3, id: 2, name: "Billed To" },
    { type: 3, id: 3, name: "Ship To" },
    { type: 5, id: 4, name: "Payment Terms" },
    { type: 1, id: 5, name: "Invoice Number*" },
    { type: 1, id: 6, name: "P.O. Number" },
    { type: 1, id: 7, name: "Sales Rep" },
    { type: 4, id: 8, name: "Invoice Date*" },
    { type: 4, id: 9, name: "Due Date*" },
    { type: 3, id: 10, name: "Terms & Conditions" },
  ],
  3: [
    { type: 1, id: 1, name: "Customer Name*" },
    { type: 5, id: 2, name: "Payment Terms", options: paymentTerms },
    { type: 1, id: 3, name: "Invoice Number*" },
    { type: 1, id: 4, name: "P.O. Number" },
    { type: 1, id: 5, name: "Sales Rep" },
    { type: 4, id: 6, name: "Invoice Date*" },
    { type: 4, id: 7, name: "Due Date*" },
    { type: 3, id: 8, name: "Notes" },
  ],
  4: [
    { type: 1, id: 1, name: "Customer Name*" },
    { type: 5, id: 2, name: "Payment Terms", options: paymentTerms },
    { type: 1, id: 3, name: "Invoice Number*" },
    { type: 1, id: 4, name: "P.O. Number" },
    { type: 1, id: 5, name: "Sales Rep" },
    { type: 4, id: 6, name: "Invoice Date*" },
    { type: 4, id: 7, name: "Due Date*" },
  ],
  5: [
    { type: 1, id: 1, name: "Customer Name*" },
    { type: 5, id: 2, name: "Payment Terms", options: paymentTerms },
    { type: 1, id: 3, name: "Invoice Number*" },
    { type: 1, id: 4, name: "P.O. Number" },
    { type: 1, id: 5, name: "Sales Rep" },
    { type: 4, id: 6, name: "Invoice Date*" },
    { type: 4, id: 7, name: "Due Date*" },
  ],
  6: [
    { type: 1, id: 1, name: "Customer Name*" },
    { type: 5, id: 2, name: "Payment Terms", options: paymentTerms },
    { type: 1, id: 3, name: "Invoice Number*" },
    { type: 1, id: 4, name: "P.O. Number" },
    { type: 1, id: 5, name: "Sales Rep" },
    { type: 4, id: 6, name: "Invoice Date*" },
    { type: 4, id: 7, name: "Due Date*" },
  ],
  7: [
    { type: 1, id: 1, name: "Patient Name*" },
    { type: 5, id: 2, name: "Payment Terms", options: paymentTerms },
    { type: 1, id: 3, name: "Invoice Number*" },
    { type: 1, id: 4, name: "P.O. Number" },
    { type: 1, id: 5, name: "Sales Rep" },
    { type: 4, id: 6, name: "Invoice Date*" },
    { type: 4, id: 7, name: "Due Date*" },
  ],
  8: [
    { type: 1, id: 1, name: "Customer Name*" },
    { type: 5, id: 2, name: "Payment Terms", options: paymentTerms },
    { type: 1, id: 3, name: "Invoice Number*" },
    { type: 1, id: 4, name: "P.O. Number" },
    { type: 1, id: 5, name: "Sales Rep" },
    { type: 4, id: 6, name: "Invoice Date*" },
    { type: 4, id: 7, name: "Due Date*" },
  ],
};

const translations = {
  en: {
    "Customer Name*": "Customer Name*",
    "Payment Terms": "Payment Terms",
    "Invoice Number*": "Invoice Number*",
    "Invoice Date*": "Invoice Date*",
    "Due Date*": "Due Date*",
    "P.O. Number": "P.O. Number",
    "Sales Rep": "Sales Rep",
    "Ship To": "Ship To",
    "Terms & Conditions": "Terms & Conditions",
    "Billed To": "Billed To",
    Notes: "Notes",
    "Default payment terms": "Default payment terms",
    "Basic payment terms": "Basic payment terms",
    "User's custom payment terms": "User's custom payment terms",
    "Detailed payment terms": "Detailed payment terms",
  },
  rh: {
    "Customer Name*": "Имя Клиента*",
    "Payment Terms": "Условия оплаты",
    "Invoice Number*": "Номер счета*",
    "Invoice Date*": "Дата счета*",
    "Due Date*": "Срок оплаты*",
    "P.O. Number": "Номер заказа",
    "Sales Rep": "Торговый представитель",
    "Ship To": "Доставить",
    "Terms & Conditions": "Правила и условия",
    "Billed To": "Кому выставлен счет",
    Notes: "Заметки",
    "Default payment terms": "Условия оплаты по умолчанию",
    "Basic payment terms": "Основные условия оплаты",
    "User's custom payment terms": "Пользовательские условия оплаты",
    "Detailed payment terms": "Подробные условия оплаты",
  },
  fr: {
    "Customer Name*": "Nom du client*",
    "Payment Terms": "Modalités de paiement",
    "Invoice Number*": "Numéro de facture*",
    "Invoice Date*": "Date de facturation*",
    "Due Date*": "Date d'échéance*",
    "P.O. Number": "Numéro de bon de commande",
    "Sales Rep": "Représentant commercial",
    "Ship To": "Envoyez à",
    "Terms & Conditions": "Termes et conditions",
    "Billed To": "Facturé à",
    Notes: "Remarques",
    "Default payment terms": "Conditions de paiement par défaut",
    "Basic payment terms": "Conditions de paiement de base",
    "User's custom payment terms":
      "Conditions de paiement personnalisées de l'utilisateur",
    "Detailed payment terms": "Modalités de paiement détaillées",
  },
  zh: {
    "Customer Name*": "顾客姓名*",
    "Payment Terms": "付款条件",
    "Invoice Number*": "发票号码*",
    "Invoice Date*": "发票日期*",
    "Due Date*": "截止日期*",
    "P.O. Number": "订单号",
    "Sales Rep": "销售代表",
    "Ship To": "运送到",
    "Terms & Conditions": "条款和条件",
    "Billed To": "付款人",
    Notes: "注释",
    "Default payment terms": "默认付款条件",
    "Basic payment terms": "基本付款条件",
    "User's custom payment terms": "用户自定义支付条件",
    "Detailed payment terms": "详细付款条件",
  },
  jp: {
    "Customer Name*": "顧客名*",
    "Payment Terms": "支払い条件",
    "Invoice Number*": "請求書番号*",
    "Invoice Date*": "請求書の日付*",
    "Due Date*": "期日*",
    "P.O. Number": "P.O.番号",
    "Sales Rep": "営業担当者",
    "Ship To": "送り先",
    "Terms & Conditions": "利用規約",
    "Billed To": "に請求",
    Notes: "ノート",
    "Default payment terms": "デフォルトの支払い条件",
    "Basic payment terms": "基本的な支払い条件",
    "User's custom payment terms": "ユーザーのカスタム支払い条件",
    "Detailed payment terms": "詳細な支払い条件",
  },
};

class InvoicingTemplates extends React.Component {
  state = {
    mode: "update",
    language: "en",
    loading: false,
    requestObject: null,
    fieldArray: [{ type: "", id: 1, name: "", options: [] }],
  };

  componentDidMount() {
    document.title = "Invoicing Automation - Serrala";
  }

  renderFields = (d) => {
    const { language } = this.state;
    return (
      <div className="row" key={d.name}>
        <div className="col-md-6">
          <div className="form-group mb-3">
            <div className="row">
              <div className="col-md-6">
                <input
                  key={language}
                  defaultValue={get(
                    translations,
                    `${language}[${d.name}]`,
                    d.name
                  )}
                  type="text"
                  className="form-control t-12"
                  placeholder="Enter Field Name"
                />
              </div>
              <div className="col-md-5">
                <select className="form-control t-12" defaultValue={d.type}>
                  <option disabled selected value="">
                    Select Field Type
                  </option>
                  {fieldTypes.map((d) => (
                    <option value={d.id} key={d.id}>
                      {d.type}
                    </option>
                  ))}
                </select>
                {get(d, "options", []).length ? (
                  <div className="mt-2">
                    <span className="t-12">Options: </span>
                    {get(d, "options", []).map((v) => (
                      <input
                        key={language}
                        defaultValue={get(
                          translations,
                          `${language}[${v.name}]`,
                          v.name
                        )}
                        type="text"
                        className="form-control t-12 mt-1"
                        placeholder="Enter Option"
                      />
                    ))}
                  </div>
                ) : null}
              </div>
              <div className="col-md-1" title="Remove Field">
                <span
                  className="text-danger cursor-pointer"
                  onClick={() =>
                    this.setState({
                      fieldArray: this.state.fieldArray.filter(
                        (e) => e.id !== d.id
                      ),
                    })
                  }
                >
                  <i className="fa fa-trash-o t-15" aria-hidden="true" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { mode, requestObject } = this.state;
    return (
      <div className="row arworkflow-main" id="dashboardBody">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="float-start pt-2 pe-2"
                    style={{
                      fontSize: "18px",
                      paddingBottom: "15px",
                      float: "left",
                    }}
                  >
                    <Link to="/settings" title="Back">
                      <i
                        className="fa fa-arrow-left"
                        data-tlabel="Receivables Invoicing Templates back button"
                        aria-hidden="true"
                        style={{ color: "#000000", marginRight: "15px" }}
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row" key={get(requestObject, "id", "-")}>
                <div className="col-md-12">
                  <h5 className="my-3">Update Existing Template</h5>
                  <form id="email-template-form" className="pos-relative">
                    <div className="row">
                      <div className="col-md-4">
                        <select
                          data-testid="Update Existing Template"
                          defaultValue={"1"}
                          className="form-control t-12"
                          onChange={(e) => {
                            this.setState({
                              mode: "edit",
                              fieldArray:
                                defaultFormFields[e.target.value] || [],
                            });
                          }}
                        >
                          <option value="">Select Template</option>
                          {invoiceTemplates.map((d) => (
                            <option value={d.id} key={d.id}>
                              {d.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {/* <div className="divider my-3"></div>
                    {mode === "add" && <h5>Create New Template</h5>}
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <input
                            defaultValue={get(requestObject, "name", "")}
                            data-testid="Enter Template Name"
                            type="text"
                            className="form-control t-12"
                            placeholder="Enter Template Name"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">Select Language</div>
                      <div className="col-md-4">
                        <select
                          onChange={(e) =>
                            this.setState({ language: e.target.value })
                          }
                          className="form-control t-12"
                          defaultValue={"en"}
                        >
                          <option value="en">English</option>
                          <option value="jp">Japanese</option>
                          <option value="fr">French</option>
                          <option value="rh">Russian</option>
                          <option value="zh">Chinese</option>
                        </select>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-12">Form Fields</div>
                      <div className="col-md-12">
                        {fieldArray.map((d) => this.renderFields(d))}
                        <span
                          onClick={() =>
                            this.setState({
                              fieldArray: fieldArray.concat([
                                {
                                  type: "",
                                  id: fieldArray.length + 1,
                                  name: "",
                                },
                              ]),
                            })
                          }
                          className="text-link cursor-pointer"
                        >
                          Add New Field
                        </span>
                      </div>
                    </div> */}
                    <div className="mt-2">
                      <RenderTemplate3
                        isSample
                        payablesInvoicingFields={
                          this.props.payablesInvoicingFields
                        }
                        getInvoicingFields={getInvoicingFields}
                        updateInvoicingFields={updateInvoicingFields}
                        deleteInvocingFields={deleteInvocingFields}
                      />
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <Button
                          type="button"
                          data-testid="Submit"
                          className="me-2"
                          onClick={() => {
                            Toaster(
                              `${
                                mode === "add"
                                  ? "New Template Added"
                                  : "Template Updated"
                              }`,
                              "success"
                            );
                            this.setState({
                              mode: "add",
                              loading: false,
                              fieldArray: [
                                { type: "", id: 1, name: "", options: [] },
                              ],
                            });
                          }}
                        >
                          Submit
                        </Button>
                        <Button
                          variant="secondary"
                          type="button"
                          data-testid="Cancle"
                          className="me-2"
                          onClick={() =>
                            this.setState({
                              mode: "add",
                              loading: false,
                              fieldArray: [
                                { type: "", id: 1, name: "", options: [] },
                              ],
                            })
                          }
                        >
                          Cancel
                        </Button>
                        {mode !== "add" && (
                          <Button
                            variant="danger"
                            type="button"
                            data-testid="Delete"
                            onClick={() =>
                              setconfirmationboxData({
                                variant: "warning",
                                msg: "Are you sure you want to delete this template?",
                                onSave: () => {
                                  resetconfirmationboxData();
                                  this.setState({
                                    mode: "add",
                                    loading: false,
                                    fieldArray: [
                                      {
                                        type: "",
                                        id: 1,
                                        name: "",
                                        options: [],
                                      },
                                    ],
                                  });
                                },
                              })
                            }
                          >
                            Delete
                          </Button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  payablesInvoicingFields: state.payablesInvoicingFields,
});

export default connect(mapStateToProps, null)(InvoicingTemplates);
