import { instanceWithRetry } from "actions/axiosInstance";
import { handleAPIError } from "handlers/setters";
import { generateQueryString } from "utils/query";

export const getCreditApplicationDetails = (params = {}) => {
    return instanceWithRetry.get(`/credit/application${generateQueryString(params)}`)
    .then(response => response.data)
    .catch(e => handleAPIError(e))
};

export const submitCreditApplication = (postData) => {
    return instanceWithRetry.post(`/credit/application?mutate=1`, postData)
    .then(response => response.data)
    .catch(e => handleAPIError(e));
};

export const deleteCreditApplication = (ids) => {
    return instanceWithRetry.delete(`/credit/application?ids=${ids}&mutate=1`)
    .then(response => response.data)
    .catch(e => handleAPIError(e));
};

export const updateCreditApplication = (id, postData) => {
    return instanceWithRetry.put(`/credit/application?id=${id}&mutate=1`, postData)
    .then(response => response.data)
    .catch(e => handleAPIError(e));
};

export const updateFormStepName = (postData) => {
    return instanceWithRetry.post(`/credit/description?mutate=1`, postData)
    .then(response => response.data)
    .catch(e => handleAPIError(e));
}