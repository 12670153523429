/* eslint-disable react-hooks/exhaustive-deps */
import { instanceWithRetry } from "actions/axiosInstance";
import Autocomplete from "components/Autocomplete";
import { handleAPIError, logout } from "handlers/setters";
import get from "lodash/get";
import { ux_setbool, ux_reset_filters, syncUserSettings } from "handlers/ux";
import debounce from "lodash/debounce";
import uniqBy from "lodash/uniqBy";
import { store } from "store";
import { default as React, useRef, useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { setUserData, getCorpHierarchy } from "actions/user";
import { refreshHeader } from "actions/global";
import TokenManager from "utils/TokenManager";
import { getLocaleString } from "utils/localization/locale";
import { clearClientInterval } from "utils/user-tracking";
import logo from "../assets/images/serrala_logo.svg";
import ClickAwayListener from "./ClickAwayListener";
import Toaster from "./Toaster";
import Select from "react-select";
import { trimText } from "utils/strings";
import { trackEvent } from "utils/loggly-tracker";
import getNewToken from "utils/token";
import { enableOpenSearch, enableHubspotSupportForm } from "handlers/features";
import {
  getClientData,
  getPayrollSettings,
  getPaymentAccountType,
} from "actions/client";
import { getUsersList } from "containers/dashboard/TaskManagement/actions";
import { intToString } from "utils";
import parse from "html-react-parser";
import { useWindowSize } from "handlers/getters";
import NotificationModal from "containers/settings/profile/notificationModal";
import InfiniteScrollList from "components/InfiniteScrollList";
import CustomerSupportForm from "components/HubSpot/CustomerSupportForm";
import MobileHeader from "./header_mobile";
import {
  nonStandardCurrencyString,
  checkNonstandardCurrencyFormat,
} from "utils/localization/formatCurrency";
import CallDialog from "./CallDialog";

const SETTING_OPTIONS = [
  {
    id: -1,
    name: `<strong>Settings: </strong>Personal > Profile</>`,
    custom_name: "personal profile",
    type: "Settings",
    route: "/settings/profile",
  },
  {
    id: -2,
    name: `<strong>Settings: </strong>Personal > Notification settings</>`,
    custom_name: "notification settings",
    type: "Settings",
    route: "/settings/profile#notificationsettings",
  },
  {
    id: -3,
    name: `<strong>Settings: </strong>Personal > Password</>`,
    custom_name: "password",
    type: "Settings",
    route: "/settings/profile#password",
  },
  {
    id: -4,
    name: `<strong>Settings: </strong>Personal > Notifications</>`,
    custom_name: "notifications",
    type: "Settings",
    route: "/settings/profile#notifications",
  },
  {
    id: -5,
    name: `<strong>Settings: </strong>Personal > Schedule notifications</>`,
    custom_name: "schdeuled notifications",
    type: "Settings",
    route: "/settings/profile#snotifications",
  },
  {
    id: -6,
    name: `<strong>Settings: </strong>Personal > Personalized dashboard</>`,
    custom_name: "personalized dashboard",
    type: "Settings",
    route: "/settings/customize-dashboard",
  },
  {
    id: -7,
    name: `<strong>Settings: </strong>Company > Profile</>`,
    custom_name: "company profile",
    type: "Settings",
    route: "/settings/company#profile",
  },
  {
    id: -8,
    name: `<strong>Settings: </strong>Company > Logo</>`,
    custom_name: "logo",
    type: "Settings",
    route: "/settings/company#profile",
  },
  {
    id: -9,
    name: `<strong>Settings: </strong>Company > Bank accounts</>`,
    custom_name: "bank accounts",
    type: "Settings",
    route: "/settings/company#bankbalance",
  },
  {
    id: -10,
    name: `<strong>Settings: </strong>Company > Cash flow configuration</>`,
    custom_name: "cash flow configuration",
    type: "Settings",
    route: "/settings/company#reportconfig",
  },
  {
    id: -11,
    name: `<strong>Settings: </strong>Company > Users</>`,
    custom_name: "users",
    type: "Settings",
    route: "/settings/company#users",
  },
  {
    id: -12,
    name: `<strong>Settings: </strong>Company > Finance goals</>`,
    custom_name: "finance goals",
    type: "Settings",
    route: "/settings/company#finance",
  },
  {
    id: -13,
    name: `<strong>Settings: </strong>Company > Permissions</>`,
    custom_name: "permissions",
    type: "Settings",
    route: "/settings/company#permissions",
  },
  {
    id: -14,
    name: `<strong>Settings: </strong>Company > General</>`,
    custom_name: "general",
    type: "Settings",
    route: "/settings/company#general",
  },
  // {
  //   id: -15,
  //   name: `<strong>Settings: </strong>Company > New company</>`,
  //   custom_name: "newcompany",
  //   type: "Settings",
  //   route: "/settings/company#newcompany",
  // },
  {
    id: -16,
    name: `<strong>Settings: </strong>Company > Company Hierarchy</>`,
    custom_name: "company hierarchy",
    type: "Settings",
    route: "/settings/company#company_heirarchy",
  },
  {
    id: -17,
    name: `<strong>Settings: </strong>Company > Corporate Entities</>`,
    custom_name: "corporate entities",
    type: "Settings",
    route: "/settings/company#corporate_entities",
  },
  {
    id: -18,
    name: `<strong>Settings: </strong>Company > Invoice Tags</>`,
    custom_name: "invoice tags",
    type: "Settings",
    route: "/settings/company#tag",
  },
  {
    id: -19,
    name: `<strong>Settings: </strong>Company > Payments</>`,
    custom_name: "payments",
    type: "Settings",
    route: "/settings/company#paymentaccounts",
  },
  {
    id: -20,
    name: `<strong>Settings: </strong>Company > Payment settings</>`,
    custom_name: "payment settings",
    type: "Settings",
    route: "/settings/company#paymentaccounts",
  },
  {
    id: -21,
    name: `<strong>Settings: </strong>Company > Report frequency</>`,
    custom_name: "report frequency",
    type: "Settings",
    route: "/settings/company#reportconfig",
  },
  {
    id: -22,
    name: `<strong>Settings: </strong>Payables > Preferences</>`,
    custom_name: "preferences",
    type: "Settings",
    route: "/settings/payables",
  },
  {
    id: -23,
    name: `<strong>Settings: </strong>Payables > Payment rules</>`,
    custom_name: "payment rules",
    type: "Settings",
    route: "/settings/payables#paymentrules",
  },
  {
    id: -24,
    name: `<strong>Settings: </strong>Payables > Payment methods</>`,
    custom_name: "payment methods",
    type: "Settings",
    route: "/settings/payables#paymentmethods",
  },
  {
    id: -25,
    name: `<strong>Settings: </strong>Payables > Vendor category</>`,
    custom_name: "vendor category",
    type: "Settings",
    route: "/settings/payables#vendorcategory",
  },
  {
    id: -26,
    name: `<strong>Settings: </strong>Payables > Payment instruments</>`,
    custom_name: "all payment instruments",
    type: "Settings",
    route: "/settings/payables#allpaymentinstruments",
  },
  {
    id: -27,
    name: `<strong>Settings: </strong>Payables > Vendors with payment plan</>`,
    custom_name: "vendors with payment plan",
    type: "Settings",
    route: "/settings/payables#vendorplan",
  },
  {
    id: -28,
    name: `<strong>Settings: </strong>Payables > Exclude invoices</>`,
    custom_name: "exclude invoices",
    type: "Settings",
    route: "/settings/payables#excludeinvoices",
  },
  {
    id: -29,
    name: `<strong>Settings: </strong>Receivables > Preferences</>`,
    custom_name: "receivables preferences",
    type: "Settings",
    route: "/settings/receivables",
  },
  {
    id: -30,
    name: `<strong>Settings: </strong>Receivables > Templates</>`,
    custom_name: "templates",
    type: "Settings",
    route: "/settings/receivables",
  },
  {
    id: -31,
    name: `<strong>Settings: </strong>Receivables > Payments set-up</>`,
    custom_name: "payments set-up",
    type: "Settings",
    route: "/settings/receivables",
  },
  {
    id: -32,
    name: `<strong>Settings: </strong>Receivables > Exclude invoices</>`,
    custom_name: "exclude invoices",
    type: "Settings",
    route: "/settings/receivables#excludeinvoices",
  },
  {
    id: -33,
    name: `<strong>Settings: </strong>AR workflow</>`,
    custom_name: "ar workflow",
    type: "Settings",
    route: "/settings/receivables/ar-workflow",
  },
  {
    id: -34,
    name: `<strong>Settings: </strong>Receivables > Customer category</>`,
    custom_name: "customer category",
    type: "Settings",
    route: "/settings/receivables#customercategory",
  },
  {
    id: -35,
    name: `<strong>Settings: </strong>Data > Integrations</>`,
    custom_name: "integrations",
    type: "Settings",
    route: "/settings/data",
  },
  // {
  //   # We dont need this
  //   id: -36,
  //   name: `<strong>Settings: </strong>Data > Background jobs</>`,
  //   custom_name: "background jobs",
  //   type: "Settings",
  //   route: "/settings/data#backgroundjobs"
  // },
  {
    id: -37,
    name: `<strong>Settings: </strong>Data > Import</>`,
    custom_name: "import",
    type: "Settings",
    route: "/settings/data#import",
  },
  // {
  //   id: -38,
  //   name: `<strong>Settings: </strong>Data > Industry benchmarks</>`,
  //   custom_name: "industry benchmarks",
  //   type: "Settings",
  //   route: "/settings/data#industry_benchmarks",
  // },
  {
    id: -39,
    name: `<strong>Settings: </strong>Company Level Settings > Exclude CC payments</>`,
    custom_name: "exclude cc payments",
    type: "Settings",
    route: "/settings/company#reportconfigcompany",
  },
  {
    id: -40,
    name: `<strong>Settings: </strong>Company Level Settings > ${
      "Credit card payments" || "Credit card "
    }</>`,
    custom_name: "credit card payments",
    type: "Settings",
    route: "/settings/company#reportconfigcompany",
  },
  {
    id: -41,
    name: `<strong>Settings: </strong>Receivables > Discounts</>`,
    custom_name: "discounts",
    type: "Settings",
    route: "/settings/receivables#discounts",
  },
];

export const Header = ({
  history,
  clients,
  phone_status,
  refreshHeader,
  getClientData,
  refresh_header,
  corporateEntities,
  changingClientFlag,
  isReportsPageLoading,
  showCompareScenarioChart,
  isFetchingAccessibleClients,
}) => {
  const currentClientId = TokenManager.getClientId();
  const [showpopup, setshowpopup] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [searchList, setSearchList] = useState([]);
  const [isSearchFetching, setIsSearchFetching] = useState(false);
  const [client, setclient] = useState(currentClientId);
  const [corporateEntity, setCorporateEntity] = useState(
    TokenManager.getCorporateEntityId()
  );
  const [customOptions, setcustomOptions] = useState([]);
  const [notificationCount, setnotificationCount] = useState([]);
  const [notificationList, setNotificationList] = useState([]);
  const [currentNotification, setCurrentNotifications] = useState(null);
  const [notificationPage, setNotificationPage] = useState(1);
  const [isChangingClient, setIsChangingClient] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [windoWidth] = useWindowSize();
  const isMobile = windoWidth <= 900;
  const username = TokenManager.getUserName() || "-";
  const debounceMethod = useRef(debounce((text) => search(text), 500)).current;
  const client_logo = TokenManager.getClientLogo();
  const client_name = TokenManager.getClientName();
  const skipDisabledEntities =
    TokenManager.getIsPayOrSuperOrGroupAdmin() ||
    TokenManager.getIsEntityAdmin();
  const disableEntitiesSelection =
    isReportsPageLoading &&
    window.location.pathname === "/reports" &&
    !showCompareScenarioChart;
  const sortedClients = clients.sort((a, b) => a.name.localeCompare(b.name));
  let groupedOptions = sortedClients.map((x) => {
    const corpEntities = skipDisabledEntities
      ? x.corporate_entities.filter((m) => m.is_enabled)
      : x.corporate_entities;
    let obj = {
      label: ["customer_invoice_restricted", "customer"].includes(x.role)
        ? x.name
        : JSON.stringify({ name: x.name, id: x.id }),
      options: !corpEntities.length
        ? [{ value: x.id, label: x.name, isHide: true }]
        : [],
      id: x.id,
      role: x.role,
    };
    let t = [];
    if (!["customer_invoice_restricted", "customer"].includes(x.role)) {
      for (let i = 0; i < corpEntities.length; i++) {
        if (
          (skipDisabledEntities && corpEntities[i]?.is_enabled) ||
          !skipDisabledEntities
        ) {
          t.push({
            value: `${x.id}-${corpEntities[i].id}`,
            label: `- ${corpEntities[i].name}`,
            displayVal: corpEntities[i].name,
            isBold: false,
            indentation: get(corpEntities, `[${i}].level`, 0) * 20,
            role: x.role,
            parentLabel: x.name,
          });
        }
      }
    } else {
      for (let i = 0; i < x.client_customer_rels.length; i++)
        t.push({
          value: `${x.id}-${x.client_customer_rels[i].client_customer_rel_id}`,
          label: `- ${x.client_customer_rels[i].customer_name}`,
          displayVal: x.client_customer_rels[i].customer_name,
          isBold: false,
          role: x.role,
          parentLabel: x.name,
        });
    }
    obj["options"] =
      (!["customer_invoice_restricted", "customer"].includes(x.role) &&
        !corpEntities.length) ||
      (["customer_invoice_restricted", "customer"].includes(x.role) &&
        !x.client_customer_rels.length)
        ? obj.options
        : t;
    return obj;
  });
  TokenManager.getIsEntityAdmin() &&
    (groupedOptions = get(groupedOptions, "[0].options", []));

  function renameKey(obj, oldKey, newKey) {
    for (let i = 0; i < oldKey.length; i++) {
      obj[newKey[i]] = obj[oldKey[i]];
      delete obj[oldKey[i]];
    }
  }

  const search = (text) => {
    const flag = enableOpenSearch();
    const url = `${
      flag
        ? `search/v2?per_page=20&page=1&search_text=${trimText(
            text ? text.replace("&", "%26") : ""
          )}`
        : `search?per_page=20&page=1&search_text=${trimText(
            text ? text.replace("&", "%26") : ""
          )}`
    }`;
    if (text) {
      setIsSearchFetching(true);
      setSearchList([]);
      setcustomOptions(
        SETTING_OPTIONS.filter((x) =>
          x.custom_name.includes(text.toLowerCase())
        )
      );
      trackEvent(`Searched for '${text}'`, "global_search");
      instanceWithRetry
        .get(url)
        .then((res) => {
          setIsSearchFetching(false);
          const data = flag ? res.data : res.data.result;
          if (flag)
            data.forEach((obj) =>
              renameKey(
                obj,
                ["doc_id", "doc_label", "doc_index"],
                ["id", "display_string", "model_type"]
              )
            );
          // Updating order to Customer, Vendor, Receivables, Payables, Order backlog
          const customerKeys = [
            "Customer",
            "pay-search-customer",
            "dev-search-customer",
            "prod-search-customer",
          ];
          const vendorKeys = [
            "Vendor",
            "pay-search-vendor",
            "dev-search-vendor",
            "prod-search-vendor",
          ];
          const arInvoiceKeys = [
            "AR_Invoice",
            "pay-search-arinvoice",
            "dev-search-arinvoice",
            "prod-search-arinvoice",
          ];
          const apInvoiceKeys = [
            "AP_Invoice",
            "pay-search-apinvoice",
            "dev-search-apinvoice",
            "prod-search-apinvoice",
          ];
          const obKeys = [
            "OrderBacklog",
            "pay-search-ob",
            "dev-search-ob",
            "prod-search-ob",
          ];
          const poKeys = [
            "PurchaseOrder",
            "pay-search-po",
            "dev-search-po",
            "prod-search-po",
          ];
          const final = data.sort((a, b) => {
            if (customerKeys.includes(a.model_type)) {
              return -1;
            } else if (
              vendorKeys.includes(a.model_type) &&
              !customerKeys.includes(b.model_type)
            ) {
              return -1;
            } else if (
              arInvoiceKeys.includes(a.model_type) &&
              !vendorKeys.includes(b.model_type) &&
              !customerKeys.includes(b.model_type)
            ) {
              return -1;
            } else if (
              apInvoiceKeys.includes(a.model_type) &&
              !arInvoiceKeys.includes(b.model_type) &&
              !vendorKeys.includes(b.model_type) &&
              !customerKeys.includes(b.model_type) &&
              b.model_type !== "JournalEntry"
            ) {
              return -1;
            } else if (
              obKeys.includes(a.model_type) &&
              !apInvoiceKeys.includes(b.model_type) &&
              !arInvoiceKeys.includes(b.model_type) &&
              !vendorKeys.includes(b.model_type) &&
              !customerKeys.includes(b.model_type) &&
              b.model_type !== "JournalEntry"
            ) {
              return -1;
            } else if (
              poKeys.includes(a.model_type) &&
              !obKeys.includes(b.model_type) &&
              !apInvoiceKeys.includes(b.model_type) &&
              !arInvoiceKeys.includes(b.model_type) &&
              !vendorKeys.includes(b.model_type) &&
              !customerKeys.includes(b.model_type) &&
              b.model_type !== "JournalEntry"
            ) {
              return -1;
            }
            return 1;
          });
          trackEvent(
            `User search result for '${text}' - count ${final.length}`,
            "global_search"
          );
          setSearchList(final);
        })
        .catch((e) => handleAPIError(e));
    } else {
      setSearchList([]);
    }
  };

  const routes_names = {
    AP_Invoice: "Payables",
    "prod-search-vendor": "Vendors",
    "dev-search-vendor": "Vendors",
    "pay-search-apinvoice": "Payables",
    "dev-search-apinvoice": "Payables",
    "prod-search-apinvoice": "Payables",
    AR_Invoice: "Receivables",
    "pay-search-arinvoice": "Receivables",
    "dev-search-arinvoice": "Receivables",
    "prod-search-arinvoice": "Receivables",
    Customer: "Customers",
    Vendor: "Vendors",
    CustomerRel: "Customers",
    SupplierRel: "Vendors",
    "pay-search-vendor": "Vendors",
    "pay-search-customer": "Customers",
    "dev-search-customer": "Customers",
    "prod-search-customer": "Customers",
    OrderBacklog: "Order Backlog",
    "pay-search-ob": "Order Backlog",
    "dev-search-ob": "Order Backlog",
    "prod-search-ob": "Order Backlog",
    PurchaseOrder: "Purchase Order",
    "pay-search-po": "Purchase Order",
    "dev-search-po": "Purchase Order",
    "prod-search-po": "Purchase Order",
    Customer_Credit: "Credit Memos",
    JournalEntry: "Journal",
  };

  const routes = {
    AP_Invoice: "payables",
    "pay-search-apinvoice": "payables",
    "dev-search-apinvoice": "payables",
    "prod-search-apinvoice": "payables",
    AR_Invoice: "receivables",
    "pay-search-arinvoice": "receivables",
    "dev-search-arinvoice": "receivables",
    "prod-search-arinvoice": "receivables",
    Customer: "Customers",
    Vendor: "Vendors",
    CustomerRel: "customers/rel",
    SupplierRel: "vendors/rel",
    "pay-search-customer": "customers/rel",
    "dev-search-customer": "customers/rel",
    "prod-search-customer": "customers/rel",
    "pay-search-vendor": "vendors/rel",
    "dev-search-vendor": "vendors/rel",
    "prod-search-vendor": "vendors/rel",
    OrderBacklog: "backlog",
    "pay-search-ob": "backlog",
    "dev-search-ob": "backlog",
    "prod-search-ob": "backlog",
    PurchaseOrder: "purchase-orders",
    "pay-search-po": "purchase-orders",
    "dev-search-po": "purchase-orders",
    "prod-search-po": "purchase-orders",
    Customer_Credit: "Receivables",
    JournalEntry: "journal",
  };

  useEffect(() => {
    instanceWithRetry
      .get(`notification/new_count`)
      .then((res) => {
        setnotificationCount(res.data.result);
      })
      .catch((e) => handleAPIError(e));
    getNotifications(true);
    setNotificationPage(1);
  }, [get(store.getState(), `ux.notification_updated`)]);

  const setchangingClientFlag = (flag) => {
    setIsChangingClient(flag);
  };

  const getNotifications = (reset) => {
    const url = `notification?page=${notificationPage}&per_page=5&sort_by=type&sort_order=asc&status=NEW`;
    instanceWithRetry
      .get(reset ? `${url}&mutate=1` : url)
      .then((res) => {
        setNotificationList(
          reset ? res.data.result : notificationList.concat(res.data.result)
        );
        setHasMore(res.data.result.length === 5);
        setNotificationPage(
          res.data.result.length === 5 ? notificationPage + 1 : notificationPage
        );
      })
      .catch((e) => handleAPIError(e));
  };

  const getNextNotificationList = () => {
    if (hasMore) {
      getNotifications();
    }
  };

  const markNotificationRead = (currentNotification) => {
    if (!currentNotification) return;
    const data = {
      type: currentNotification.type,
      status: "READ",
      content: currentNotification.content,
    };
    instanceWithRetry
      .post(`notification?id=${currentNotification.id}&mutate=1`, data)
      .then((res) => {
        store.dispatch({
          type: "SET_UX",
          payload: { notification_updated: res },
        });
        getNotifications(true);
        setCurrentNotifications(null);
      })
      .catch((e) => handleAPIError(e));
  };

  const onFail = (e) => {
    handleAPIError(e, true);
    setchangingClientFlag(false);
    Toaster(
      getLocaleString(
        "header.failed_please_login_again",
        "Failed, Please login again"
      ),
      "error"
    );
    logout();
  };

  const updateChangesAndRedirect = (response, datatemp, customerRedirect) => {
    let base_currency = get(response, "data.corp_entity.currency", null)
      ? get(response, "data.corp_entity.currency", "USD")
      : get(response, "data.client.base_currency", "USD");
    base_currency = nonStandardCurrencyString[base_currency]
      ? nonStandardCurrencyString[base_currency]
      : base_currency;
    let client_base_currency = get(
      response,
      "data.client.base_currency",
      "USD"
    );
    client_base_currency = nonStandardCurrencyString[client_base_currency]
      ? nonStandardCurrencyString[client_base_currency]
      : client_base_currency;
    const date_format = get(response, "data.corp_entity.date_format", null)
      ? get(response, "data.corp_entity.date_format", "MM/DD/YYYY")
      : get(response, "data.client.date_format", "MM/DD/YYYY");
    localStorage.setItem("base_currency", base_currency);
    localStorage.setItem("client_base_currency", client_base_currency);
    localStorage.setItem("date_format", date_format);
    localStorage.setItem(
      "corp_entity_selected",
      !!get(response, "data.corp_entity", "")
    );

    TokenManager.setUserData(datatemp);
    localStorage.removeItem("activeTabSelection");
    localStorage.removeItem("customerListColumns");
    refreshHeader();
    // Clears the ongoing client timer activity and starts new client activity
    clearClientInterval();

    if (parseInt(localStorage.getItem("oldClientId")) !== currentClientId) {
      localStorage.setItem("event_type", "logout");
    } else {
      localStorage.setItem("event_type", "navigation");
    }

    localStorage.setItem("event_resource", "Button-ClientSelector");
    try {
      ux_reset_filters().then(() => {
        syncUserSettings().then(() => {
          if (customerRedirect) {
            history.push(customerRedirect);
          }
          window.location.reload();
        });
      });
    } catch (e) {
      window.location.reload();
    }
  };

  const onChangeClientAndEntities = (value, hideToaster = false) => {
    let client_id = null;
    let corporate_entity_id = null;
    let client_customer_rel_id_context = null;
    let customerRedirect = "";
    const isCustomer = ["customer_invoice_restricted", "customer"].includes(
      value.role
    );
    if (typeof value.value == "number") {
      client_id = Number(value.value);
    } else {
      let selected_data = value.value.split("-");
      client_id = Number(selected_data[0]);
      const id = selected_data[1];
      if (selected_data.length === 2) {
        if (isCustomer) {
          client_customer_rel_id_context = id;
          customerRedirect = `/customers/rel/${id}`;
        } else {
          corporate_entity_id = id;
        }
      }
    }
    if (corporate_entity_id) {
      const base_currency = get(
        corporateEntities.find((d) => d.id === Number(corporate_entity_id)),
        "currency",
        "USD"
      );
      if (checkNonstandardCurrencyFormat(base_currency.trim(), true)) {
        Toaster(
          getLocaleString(
            "header.unable_to_switch_entity",
            "Unable to switch entity"
          ),
          "error"
        );
        setTimeout(() => {
          window.location.reload();
        }, 800);
        return;
      }
    }

    setchangingClientFlag(true);
    localStorage.setItem("cashflow-event", "client-change");
    localStorage.setItem("oldClientId", currentClientId);
    if (corporate_entity_id || client_customer_rel_id_context) {
      const getToken = (ids = "") => {
        const headers = {
          client_id_context: client_id,
          [isCustomer
            ? "client_customer_rel_id_context"
            : "corporate_entity_id_context"]: isCustomer
            ? client_customer_rel_id_context
            : corporate_entity_id,
        };
        if (ids.length > 1) {
          headers.hierarchical_corporate_entity_list_context = ids;
        }
        getNewToken({ headers }, (response) => {
          if (!hideToaster) {
            Toaster(
              `${getLocaleString("header.changing", "Changing")} ${
                !isCustomer
                  ? getLocaleString(
                      "payroll.corporate_entity",
                      "Corporate Entity"
                    )
                  : getLocaleString("common.customer", "Customer")
              }`,
              "info"
            );
          }
          const corp_entity = get(response, "data.corp_entity.id", "");
          const isCustomerWithEntity =
            response.data.role === "customer" && corp_entity;
          getPaymentAccountType();
          setclient(client_id);
          setCorporateEntity(corporate_entity_id);
          getClientData("", isCustomerWithEntity ? corp_entity : "")
            .then((responses) => {
              setchangingClientFlag(false);
              // userdata
              const datatemp = {
                ...JSON.parse(TokenManager.getUserData()),
                ...response.data,
                phone_token: null,
                has_child_entities: get(
                  response,
                  "data.has_child_entities",
                  ""
                ),
                client_id_context: client_id,
                client_id: client_id,
                [!isCustomer
                  ? "client_customer_rel_id_context"
                  : "corporate_entity_id_context"]: "",
                [isCustomer
                  ? "client_customer_rel_id_context"
                  : "corporate_entity_id_context"]: isCustomer
                  ? client_customer_rel_id_context
                  : corporate_entity_id,
                client_name: responses.result.name,
                client_address: responses.result.address,
                client_logo: responses.result.logo_url,
              };
              setUserData({
                datatemp,
                clients,
                corporateEntities,
              });
              localStorage.setItem("clientChangeType", "navigation");

              updateChangesAndRedirect(response, datatemp, customerRedirect);
            })
            .catch(onFail);
        });
      };
      if (isCustomer) {
        getToken();
      } else {
        getCorpHierarchy(corporate_entity_id, getToken);
      }
    } else if (client_id) {
      getNewToken({ headers: { client_id_context: client_id } }, (response) => {
        if (!hideToaster) {
          Toaster(
            getLocaleString("header.changing_client", "Changing client"),
            "info"
          );
        }
        setclient(client_id);
        const showScenarioPage = localStorage.getItem("showScenarioPage");
        const selectedScenario = localStorage.getItem("selectedSecnario");
        if (showScenarioPage) {
          localStorage.setItem("showScenarioPage", true);
          selectedScenario &&
            localStorage.setItem("selectedSecnario", selectedScenario);
        }
        getClientData()
          .then((responses) => {
            setchangingClientFlag(false);
            setCorporateEntity(null);
            getPaymentAccountType();
            // userdata
            const datatemp = {
              ...JSON.parse(TokenManager.getUserData()),
              ...response.data,
              client_id: client_id,
              has_child_entities: get(response, "data.has_child_entities", ""),
              client_name: get(responses, "result.name", ""),
              phone_token: null,
              client_id_context: client_id,
              corporate_entity_id_context: null,
              corporate_entity_id: null,
              client_logo: responses.result.logo_url,
              client_address: responses.result.address,
              client_email: responses.result.reply_to_email,
            };

            setUserData({
              datatemp,
              clients,
              corporateEntities,
            });
            updateChangesAndRedirect(response, datatemp, "", true);
          })
          .catch(onFail);
      });
    }
  };

  const getClientAndEntitiesValue = (forceSwitchEntity = false) => {
    if (TokenManager.getIsEntityAdmin()) {
      const compare_id = `${client}-${TokenManager.getCorporateEntityId()}`;
      const res = groupedOptions.find((x) => x.value === compare_id);
      if (
        forceSwitchEntity &&
        !res &&
        groupedOptions.length &&
        !(groupedOptions.length === 1 && groupedOptions.some((d) => d.isHide))
      ) {
        onChangeClientAndEntities(groupedOptions[0], true); // force switch entity as currenty is disabled
      }
      return res;
    }
    const clientCorprateEntity = `${client}-${corporateEntity}`;
    const clientCustomer = `${client}-${TokenManager.getClientCustomerRelId()}`;

    for (let i = 0; i < groupedOptions.length; i++) {
      const groupOption = groupedOptions[i];
      for (let j = 0; j < groupOption.options.length; j++) {
        const option = groupOption.options[j];
        if (!option.isHide && client) {
          if (
            clientCorprateEntity === option.value ||
            (!corporateEntity && client === option.value) ||
            clientCustomer === option.value
          )
            return option;
        }
      }
      if (groupOption.value === client_name) {
        return {
          displayVal: client_name,
          value: client_name,
        };
      }
      if (client && client === groupOption.id) {
        let displayVal = "";
        let value = "";
        try {
          displayVal = JSON.parse(groupOption.label).name;
          value = JSON.parse(groupOption.label).id;
        } catch (e) {
          displayVal = groupOption.label;
          value = groupOption.id;
        }
        return {
          displayVal,
          value,
        };
      }
    }
  };

  const preventClientOptionClick = ["aprep"].includes(
    TokenManager.getRoleNew()
  );

  const selectedEntity = getClientAndEntitiesValue();
  useEffect(() => {
    if (!selectedEntity && !isFetchingAccessibleClients) {
      getClientAndEntitiesValue(true);
    }
  }, [selectedEntity, isFetchingAccessibleClients]);

  const customStyles = {
    group: () => ({
      padding: 0,
      marginBottom: 8,
    }),
    groupHeading: (provided) => ({
      ...provided,
      fontWeight: 700,
      color: "#000",
      textTransform: "none",
      fontSize: "inherit",
      cursor: preventClientOptionClick ? "default" : "pointer",
      paddingTop: 5,
      paddingBottom: 5,
    }),
    option: (provided) => ({
      ...provided,
      padding: "5px 0 5px 10px",
      cursor: "pointer",
    }),
  };

  const filterOption = ({ label, data }, string) => {
    const lowerCaseString = string ? string.toLocaleLowerCase() : "";
    const lowercaseLabel = label ? label.toLocaleLowerCase() : "";
    const lowerCaseParentLabel = get(data, "parentLabel", "")
      ? get(data, "parentLabel", "").toLocaleLowerCase()
      : "";
    if (
      lowercaseLabel.includes(lowerCaseString) ||
      lowerCaseParentLabel.includes(lowerCaseString)
    ) {
      return true;
    }
    return false;
  };

  const GroupHeading = (props) => {
    let data = null;
    try {
      data = JSON.parse(get(props, "children", ""));
    } catch (e) {
      data = get(props, "children", "");
    }

    if (typeof data === "string") {
      return (
        <div
          style={{
            ...props.getStyles("groupHeading", props),
            cursor: "default",
          }}
        >
          {data}
        </div>
      );
    }

    const selectedStyle =
      data.id === client
        ? {
            backgroundColor: "rgb(38, 132, 255)",
            color: "#fff",
          }
        : {};
    return (
      <div
        className={data.id === client ? "" : "hover-blue"}
        style={{ ...props.getStyles("groupHeading", props), ...selectedStyle }}
        onClick={() => {
          if (!preventClientOptionClick) {
            onChangeClientAndEntities({
              value: data && data.id,
            });
          }
        }}
      >
        {data && data.name}
      </div>
    );
  };

  const ValueContainer = (props) => (
    <div style={props.getStyles("singleValue", props)}>
      {get(props, "data.displayVal", "")}
    </div>
  );
  const OptionContainer = (props) =>
    !get(props, "data.isHide", false) && (
      <div
        {...props.innerProps}
        innerRef={props.innerRef}
        ref={props.innerRef}
        id={`${get(props, "data.value", "")}`}
        style={{
          ...props.getStyles("option", props),
          fontStyle: get(props, "data.isBold", false) ? "normal" : "italic",
          paddingLeft: get(props, "data.indentation", "") || 10,
        }}
      >
        {get(props, "data.isBold", false) ? (
          <strong>{get(props, "data.label", "")}</strong>
        ) : (
          get(props, "data.label", "")
        )}
      </div>
    );

  const is_customer = TokenManager.isCustomer();
  let is_singleOption = false;
  if (
    groupedOptions.length === 1 &&
    get(groupedOptions[0], "options", []).length === 1 &&
    groupedOptions[0].options[0].isHide
  ) {
    is_singleOption = true;
  } else if (
    groupedOptions.length === 1 &&
    !get(groupedOptions[0], "options", []).length
  ) {
    is_singleOption = true;
  } else {
    is_singleOption = false;
  }
  if (TokenManager.getOldRole() && TokenManager.getOldRole() !== "customer") {
    is_singleOption = true;
  }
  const status_arr = ["RINGING", "IN_PROGRESS"].includes(phone_status);

  if (is_customer && isMobile) {
    return <MobileHeader />;
  }

  return (
    <div className="row menufixed justify-content-between" id="topHeader">
      <div className="col-md-3 my-auto p-0 logop-expanded switchButton">
        {!is_customer ? (
          <div
            data-toggle="sidebar-colapse"
            className="border-none m-0"
            onClick={() => {
              ux_setbool("NAVIGATION_PANEL_EXPANDED");
            }}
          >
            <i
              data-tlabel="Navigation Sidebar Menu Toggle"
              id="swit"
              className={`fas fa-fw fa-bars cursor-pointer`}
            ></i>
          </div>
        ) : null}
        <img
          className="logo-header"
          src={logo}
          alt="loading"
          data-tlabel="Serrala logo"
        />
      </div>
      <div className="col-md-5 my-auto pe-4">
        {!is_customer ? (
          <form className="navbar-form navbar-left searchform">
            <div className="input-group">
              <Autocomplete
                key={changingClientFlag}
                isGlobal={true}
                suggestions={uniqBy(
                  [
                    ...customOptions,
                    ...searchList.map((s) => ({
                      id: s.id,
                      type: s.model_type,
                      name: `<strong>${routes_names[s.model_type]}:</strong> ${
                        s.display_string
                      }`,
                    })),
                  ],
                  "id"
                )}
                placeholder={getLocaleString(
                  TokenManager.getIsAR_REP() || TokenManager.isReadOnlyARUser()
                    ? "header.searchPlaceholderArRep"
                    : TokenManager.getIsAP_REP() ||
                      TokenManager.getDriverAdmin()
                    ? "header.searchPlaceholderApRep"
                    : "header.searchPlaceholder"
                )}
                inputClassName="searchbar"
                inputWrapperClassName="search-wrapper"
                getSelected={(item) => {
                  if (item) {
                    setSearchList([]);
                    setcustomOptions([]);
                    if (item.type === "Settings") {
                      let hash = item.route.split("#");
                      hash = hash[hash.length - 1];
                      const objDiv = document.getElementById(hash);
                      objDiv && objDiv.scrollIntoView();
                      history.push("/");
                      setTimeout(() => {
                        history.push(item.route);
                      }, 50);
                    } else {
                      history.push(
                        `/${routes[item.type].toLowerCase()}/${item.id}`
                      );
                    }
                  }
                }}
                search={(text) => debounceMethod(text)}
                isLoading={isSearchFetching}
              />
            </div>
          </form>
        ) : (
          <div
            key={refresh_header}
            className="d-flex align-items-center justify-content-center w-100"
          >
            {client_logo ? (
              <img style={{ height: 70 }} src={client_logo} alt={client_name} />
            ) : (
              <div className="text-uppercase t-28">{client_name}</div>
            )}
          </div>
        )}
      </div>
      <div className="col-md-4 my-auto d-flex justify-content-end p-0">
        {is_singleOption ? (
          <div className="my-auto pe-5 w-100">
            <div className="border rounded px-2 py-2 text-truncate">
              {get(selectedEntity, "displayVal", "")}
            </div>
          </div>
        ) : (
          <div className="my-auto pe-5 w-100">
            <Select
              key={isChangingClient}
              isDisabled={
                disableEntitiesSelection ||
                isChangingClient ||
                status_arr ||
                isFetchingAccessibleClients
              }
              styles={customStyles}
              className={isChangingClient ? "loading-anim" : "entity-selector"}
              name="entities"
              components={{
                GroupHeading: GroupHeading,
                SingleValue: ValueContainer,
                Option: OptionContainer,
              }}
              filterOption={filterOption}
              label="Entities"
              options={groupedOptions}
              value={selectedEntity}
              onChange={onChangeClientAndEntities}
              placeholder="Company or entity"
            />
          </div>
        )}
        {enableHubspotSupportForm() ? <CustomerSupportForm /> : null}
        <div className="d-inline-block pe-2">
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip>
                {getLocaleString("header.notificationIconText")}
              </Tooltip>
            }
          >
            <>
              <div
                className="cursor-pointer position-relative"
                to="#"
                role="button"
                id="notificationDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={() => {
                  setShowNotifications(!showNotifications);
                }}
              >
                <i
                  className="fa fa-bell notification-bell"
                  aria-hidden="true"
                  data-tlabel="Navigation Notification Bell Icon"
                />
              </div>
              <div className="notification-count font-weight-bold">
                {intToString(notificationCount)}
              </div>
            </>
          </OverlayTrigger>
          <ClickAwayListener onClickAway={setShowNotifications}>
            <div
              className={`dropdown-menu dropdown-menu-end right-0 py-2 notification-tray ${
                showNotifications ? "show" : ""
              }`}
              aria-labelledby="dropdownMenuLink"
            >
              <div
                className="pt-2 mb-2"
                style={{ borderBottom: "2px solid #dee2e6" }}
              >
                <h5 className="px-2">
                  <strong>
                    {getLocaleString("common.notifications", "Notifications")}
                  </strong>
                  {get(history, "location.pathname", "") !==
                  "/settings/profile" ? (
                    <Link
                      to="/settings/profile#notifications"
                      onClick={() => setShowNotifications(false)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i
                        title="Show all notifications"
                        className="fa fa-external-link t-18 me-1 mt-1 float-end cursor-pointer"
                        aria-hidden="true"
                        data-tlabel="Show all notifications"
                      />
                    </Link>
                  ) : null}
                </h5>
              </div>
              <div className="t-14">
                <InfiniteScrollList
                  wrapperClass="notifications-list"
                  dataLength={notificationList.length}
                  hasMore={hasMore}
                  getNextList={getNextNotificationList}
                  refreshList={getNotifications}
                >
                  {notificationList.length ? (
                    notificationList.map((d) => (
                      <div
                        title="View Notification"
                        data-tlabel={`Notification id ${d.id}`}
                        className="px-2 border-bottom pb-2 mb-2 cursor-pointer notification-item"
                        onClick={() => setCurrentNotifications(d)}
                      >
                        <strong>{get(d, "associated_client.name", "")}</strong>
                        <div className="pt-1">{parse(d.content)}</div>
                      </div>
                    ))
                  ) : (
                    <div className="p-2 px-3">
                      {getLocaleString(
                        "common.no_new_notifications",
                        "There are no new notifications"
                      )}
                    </div>
                  )}
                </InfiniteScrollList>
              </div>
            </div>
          </ClickAwayListener>
          <NotificationModal
            rowData={currentNotification}
            handleClose={() => {
              markNotificationRead(currentNotification);
              setShowNotifications(true);
            }}
            show={currentNotification}
          />
        </div>
        <div className="d-inline-block pe-3 py-2">
          <div className="dropdown">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>{getLocaleString("header.profileIconText")}</Tooltip>
              }
            >
              <div
                className="btn cursor-pointer px-0" // dropdown-toggle for down arrow icon
                to="#"
                role="button"
                id="profile"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={() => setshowpopup(!showpopup)}
              >
                <div className="text-icon-big rounded-circle">
                  {(username[0] || "").toUpperCase()}
                </div>
              </div>
            </OverlayTrigger>
            <ClickAwayListener onClickAway={setshowpopup}>
              <div
                className={`dropdown-menu ${
                  showpopup && "open-profilepic-menu show"
                }`}
                id="profilebox"
                aria-labelledby="dropdownMenuLink"
              >
                <div className="mt-3 text-icon-big xl rounded-circle">
                  {(username[0] || "").toUpperCase()}
                </div>
                <p title={username} className="text-center">
                  {username}
                </p>
                <div className="row" id="dtwrapper">
                  <div className="col-6">
                    <Link
                      onClick={() => setshowpopup(false)}
                      to="/settings/profile"
                    >
                      {getLocaleString("header.profile")}
                    </Link>
                  </div>
                  <div
                    className={`col-6 align-right ${
                      status_arr && "disabledbutton"
                    }`}
                  >
                    <Link
                      disabled
                      onClick={(e) => {
                        e.preventDefault();
                        setshowpopup(false);
                        logout();
                      }}
                      to="/"
                    >
                      {getLocaleString("header.signout")}
                    </Link>
                  </div>
                </div>
              </div>
            </ClickAwayListener>
          </div>
        </div>
        {window.location.pathname !== "/settings/profile" && status_arr ? (
          <CallDialog history={history} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ux: state.ux,
  refresh_header: state.header.refresh_header,
  changingClientFlag: state.user.changingClientFlag,
  clients: state.user.clients,
  isFetchingAccessibleClients: state.user.isFetchingAccessibleClients,
  corporateEntities: state.user.corporateEntities,
  isReportsPageLoading: get(state, "reports.isTotalLoading", false),
  showCompareScenarioChart: get(
    state,
    "reports.showCompareScenarioChart",
    false
  ),
  phone_status: get(state, "phoneCall.call_status", ""),
  userSettings: get(state, "user.userSettings", {}),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setUserData,
      refreshHeader,
      getClientData,
      getUsersList,
      getPayrollSettings,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
