import { combineReducers } from "redux";
import login from "containers/login/reducer";
import dashboard from "containers/dashboard/reducer";
import payables from "containers/payables/reducer";
import receivables from "containers/receivables/reducer";
import orderbacklog from "containers/backlog/reducer";
import purchaseorder from "containers/purchaseOrder/reducer";
import customerList from "containers/customers/listnew/reducer";
import customerDetails from "containers/customers/reducer";
import workflow from "containers/settings/receivables/ARworkflow/reducer";
import settings from "containers/settings/reducer";
import compareScenarios from "containers/reports/CompareScenarios/reducer";
import settingsUser from "containers/settings/company/usersList/reducer";
import reports from "containers/reports/reducers";
import auth from "reducers/auth";
import global from "reducers/global";
import skipArEmail from "containers/settings/receivables/reducer";
import payablesInvoicingFields from "containers/settings/payables/reducer";
import user from "reducers/user";
import activity from "containers/analytics-report/Activity/reducers";
import paymentAnalyticsDetails from "containers/analytics-report/Payments/reducer";
import helpPageResources from "containers/help/reducer";
import journalEntries from "containers/journal-entries/reducer";
import vendorListing from "containers/vendors/list/reducer";
import globalNotesList from "containers/analytics-report/Notes/reducer";
import smsList from "containers/analytics-report/Sms/reducer";
import carrierActivity from "containers/analytics-report/CarrierActivity/reducer";
import invoicingPortal from  "containers/vendors/invoicing-portal/reducer";
import phoneCall from "containers/settings/profile/makePhoneCall/reducer";
import reportsCallList from "containers/analytics-report/Phone/reducer";
import vendorDetails from "containers/vendors/details/reducer";
import emailLogList from "containers/analytics-report/Email/reducer";

// Combine all reducers.
const appReducer = combineReducers({
  login,
  auth,
  user,
  smsList,
  reports,
  payables,
  activity,
  settings,
  workflow,
  dashboard,
  phoneCall,
  skipArEmail,
  receivables,
  emailLogList,
  orderbacklog,
  purchaseorder,
  settingsUser,
  customerList,
  vendorDetails,
  vendorListing,
  journalEntries,
  reportsCallList,
  carrierActivity,
  invoicingPortal,
  globalNotesList,
  customerDetails,
  compareScenarios,
  helpPageResources,
  payablesInvoicingFields,
  paymentAnalyticsDetails,
  ...global,
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === "DESTROY_SESSION") state = undefined;

  return appReducer(state, action);
};

export default rootReducer;
