import get from "lodash/get";
import { _get } from "utils";
import CONSTANTS from "constants/index";

export default class TokenManager {
  static set(idToken, expired_at_api_time) {
    if (idToken) {
      const expired_at_in_seconds = new Date(expired_at_api_time).getTime();
      localStorage.setItem("expired_at", expired_at_in_seconds);
      localStorage.setItem("auth", idToken);
    }
  }

  static get() {
    return localStorage.getItem("auth");
  }

  static getExpiredAt() {
    return localStorage.getItem("expired_at")
      ? JSON.parse(localStorage.getItem("expired_at"))
      : 0;
  }

  static remove() {
    localStorage.removeItem("auth");
  }

  static setUserName(userName, userid) {
    if (userName) {
      localStorage.setItem("username", userName);
      localStorage.setItem("userid", userid);
    }
  }

  static getUserId() {
    return localStorage.getItem("userid");
  }

  static getUserName() {
    return localStorage.getItem("username");
  }

  static setUserData(userData) {
    if (userData) {
      localStorage.setItem("loggedInUserData", JSON.stringify(userData));
    }
  }
  static getUserData() {
    return localStorage.getItem("loggedInUserData") || "{}";
  }

  static getUserEmail() {
    const userData = JSON.parse(
      localStorage.getItem("loggedInUserData") || "{}"
    );
    return get(userData, "email", "");
  }

  static getClientName() {
    const userData = JSON.parse(
      localStorage.getItem("loggedInUserData") || "{}"
    );
    return get(userData, "client_name", "");
  }

  static getClientLogo() {
    const userData = JSON.parse(
      localStorage.getItem("loggedInUserData") || "{}"
    );
    return get(userData, "client_logo", "");
  }

  static getClientEmail() {
    const userData = JSON.parse(
      localStorage.getItem("loggedInUserData") || "{}"
    );
    return get(userData, "client_email", "");
  }

  static getClientId() {
    const userData = JSON.parse(
      localStorage.getItem("loggedInUserData") || "{}"
    );
    return (
      get(userData, "client_id", "") || get(userData, "client_id_context", "")
    );
  }

  static getCorporateEntityId() {
    const userData = JSON.parse(
      localStorage.getItem("loggedInUserData") || "{}"
    );
    return (
      _get(userData, "corporate_entity_id_context", "") ||
      _get(userData, "corporate_entity_id", "")
    );
  }

  static getCorporateEntityLength() {
    return get(
      JSON.parse(localStorage.getItem("loggedInUserData")),
      "corporate_entities",
      []
    ).length
      ? true
      : false;
  }

  static isCorpEntityRelatedToUser() {
    return (
      JSON.parse(localStorage.getItem("corp_entity_selected") || "false") ||
      false
    );
  }

  static getCorporateEntityName(id) {
    const entityId = id || this.getCorporateEntityId();
    if (entityId) {
      const payferData =
        localStorage.getItem("persist:payference") &&
        JSON.parse(localStorage.getItem("persist:payference") || "{}");
      const userData =
        payferData && payferData["user"] && JSON.parse(payferData["user"]);
      const entityData = get(userData, "corporateEntities", []).find(
        (data) => Number(data.id) === Number(entityId)
      );
      return entityData ? entityData.name : "";
    }
    return "";
  }

  static getIsGroupAdmin() {
    const loggedInUserData = JSON.parse(TokenManager.getUserData());
    return ["groupadmin", "payadmin"].includes(
      get(loggedInUserData, "role", "")
    );
  }

  static isCustomer() {
    const loggedInUserData = JSON.parse(TokenManager.getUserData());
    return ["customer_invoice_restricted", "customer"].includes(
      get(loggedInUserData, "role", "")
    );
  }

  static isCustomerInvoiceRestricted() {
    const loggedInUserData = JSON.parse(TokenManager.getUserData());
    return get(loggedInUserData, "role", "") === "customer_invoice_restricted";
  }

  static isCorpHasChildEntities() {
    const loggedInUserData = JSON.parse(TokenManager.getUserData());
    return get(loggedInUserData, "has_child_entities", false);
  }

  static isVendor() {
    const loggedInUserData = JSON.parse(TokenManager.getUserData());
    return get(loggedInUserData, "role", "") === "supplier";
  }

  static isReadOnlyAdmin() {
    const loggedInUserData = JSON.parse(TokenManager.getUserData());
    return ["readonlyuser", "readonlyentityuser", "readonlyaruser"].includes(
      get(loggedInUserData, "role", "")
    );
  }

  static isReadOnlyEntityUser() {
    const loggedInUserData = JSON.parse(TokenManager.getUserData());
    return ["readonlyentityuser"].includes(get(loggedInUserData, "role", ""));
  }

  static isReadOnlyARUser() {
    const loggedInUserData = JSON.parse(TokenManager.getUserData());
    return get(loggedInUserData, "role", "") === "readonlyaruser";
  }

  static getClientCustomerRelId() {
    const userData = JSON.parse(
      localStorage.getItem("loggedInUserData") || "{}"
    );
    return get(userData, "client_customer_rel_id", "");
  }

  static getARrepContext() {
    const userData = JSON.parse(
      localStorage.getItem("loggedInUserData") || "{}"
    );
    return get(userData, "client_customer_ar_rep_list", "");
  }

  static getClientSupplierRelId() {
    const userData = JSON.parse(
      localStorage.getItem("loggedInUserData") || "{}"
    );
    return get(userData, "client_supplier_rel_id", "");
  }

  static getIsAdmin() {
    const loggedInUserData = JSON.parse(TokenManager.getUserData());
    return [
      "payadmin",
      "admin",
      "groupadmin",
      "entityadmin",
      "readonlyuser",
      "readonlyentityuser",
    ].includes(get(loggedInUserData, "role", ""));
  }

  static getAllAdmins() {
    const loggedInUserData = JSON.parse(TokenManager.getUserData());
    return ["payadmin", "admin", "groupadmin", "entityadmin"].includes(
      get(loggedInUserData, "role", "")
    );
  }

  static getIsAR_REP() {
    const loggedInUserData = JSON.parse(TokenManager.getUserData());
    return get(loggedInUserData, "role", "") === "arrep";
  }

  static getIsAP_REP() {
    const loggedInUserData = JSON.parse(TokenManager.getUserData());
    return get(loggedInUserData, "role", "") === "aprep";
  }

  static getDriverAdmin() {
    const loggedInUserData = JSON.parse(TokenManager.getUserData());
    return get(loggedInUserData, "role", "") === "driveractivityadmin";
  }

  static getIsAdminOnly() {
    const loggedInUserData = JSON.parse(TokenManager.getUserData());
    return ["admin", "readonlyuser"].includes(
      get(loggedInUserData, "role", "")
    );
  }

  static getIsPayAdmin() {
    const loggedInUserData = JSON.parse(TokenManager.getUserData());
    return get(loggedInUserData, "role", "") === "payadmin";
  }

  static getIsSuperAdmin() {
    const loggedInUserData = JSON.parse(TokenManager.getUserData());
    return get(loggedInUserData, "role", "") === "groupadmin";
  }

  static getIsPayOrSuperAdmin() {
    return TokenManager.getIsPayAdmin() || TokenManager.getIsSuperAdmin();
  }

  static getIsEntityAdmin() {
    const loggedInUserData = JSON.parse(TokenManager.getUserData());
    return ["entityadmin", "readonlyentityuser"].includes(
      get(loggedInUserData, "role", "")
    );
  }

  static getIsPayOrSuperOrGroupAdmin() {
    return (
      TokenManager.getIsPayOrSuperAdmin() ||
      TokenManager.getIsGroupAdmin() ||
      TokenManager.getIsAdminOnly()
    );
  }

  static getIsUserOnly() {
    const loggedInUserData = JSON.parse(TokenManager.getUserData());
    return get(loggedInUserData, "role", "") === "user";
  }

  static getRoleForDiscount() {
    const loggedInUserData = JSON.parse(TokenManager.getUserData());
    return ["admin", "payadmin", "groupadmin"].includes(
      get(loggedInUserData, "role", "")
    );
  }

  static getRoles() {
    const loggedInUserData = JSON.parse(TokenManager.getUserData());
    return get(loggedInUserData, "roles", []);
  }

  static getOldRole() {
    const loggedInUserData = JSON.parse(TokenManager.getUserData());
    return get(loggedInUserData, "oldRole", "");
  }

  static getRole(_roles) {
    const loggedInUserData = JSON.parse(TokenManager.getUserData());
    const roles = _roles || get(loggedInUserData, "roles", []);
    return roles.includes("payadmin")
      ? "payadmin"
      : roles.includes("groupadmin")
      ? "groupadmin"
      : roles.includes("admin")
      ? "admin"
      : roles.includes("entityadmin")
      ? "entityadmin"
      : roles.includes("readonlyentityuser")
      ? "readonlyentityuser"
      : roles.includes("readonlyuser")
      ? "readonlyuser"
      : roles.includes("arrep")
      ? roles.includes("driveractivityadmin")
        ? "driveractivityadmin"
        : "arrep"
      : roles.includes("aprep")
      ? "aprep"
      : "user";
  }

  static getRoleNew() {
    const loggedInUserData = JSON.parse(TokenManager.getUserData());
    return get(loggedInUserData, "role", "");
  }

  static getHighestRoleId(_roles) {
    const loggedInUserRoleId = CONSTANTS.ROLE_OPTIONS.find(
      (x) => x.value === this.getRole()
    )?.id;
    return loggedInUserRoleId || loggedInUserRoleId === 0
      ? loggedInUserRoleId
      : 7;
  }

  static getCurrencyCode() {
    if (
      !localStorage.getItem("base_currency") ||
      localStorage.getItem("base_currency") === null ||
      localStorage.getItem("base_currency") === "null"
    ) {
      return "USD";
    }
    return localStorage.getItem("base_currency");
  }

  static getClientCurrencyCode() {
    if (
      !localStorage.getItem("client_base_currency") ||
      localStorage.getItem("client_base_currency") === null ||
      localStorage.getItem("client_base_currency") === "null"
    ) {
      return "USD";
    }
    return localStorage.getItem("client_base_currency");
  }

  static getDateFormat() {
    if (
      !localStorage.getItem("date_format") ||
      localStorage.getItem("date_format") === null ||
      localStorage.getItem("date_format") === "null"
    ) {
      return "MM/DD/YYYY";
    }
    return localStorage.getItem("date_format");
  }

  static getShortDateFormat() {
    return TokenManager.getDateFormat().replace("/YYYY", "");
  }

  static getPaymentAccountTypeData() {
    const data = JSON.parse(localStorage.getItem("account_data") || "{}");
    if (data && Object.keys(data).length) {
      return {
        ...data,
        account_type:
          ["STANDARD", "EXPRESS", "STRIPE"].includes(data.account_type) ||
          !data.account_type
            ? "STRIPE"
            : data.account_type,
      };
    }
    return false;
  }
}
