import { _get } from "utils";
import If from "components/If";
import { ReducersTypes } from "constants/ReducersTypes";
import React from "react";
import { store } from "store";
import Modal from "../CustomModal";
import { connect } from "react-redux";
import DocViewer from "./DocViewer";
import { MIMETYPE, EXTENSIONS } from "./const.js";

export const setPreview = (data) => {
  store.dispatch({
    type: ReducersTypes.PREVIEW_DOCUMENT,
    payload: data,
  });
};

export const resetPreview = () => {
  store.dispatch({
    type: ReducersTypes.RESET_PREVIEW_DOCUMENT,
  });
};

export const previewInNewTab = (url, type) => {
  if (window.Cypress) {
    return;
  }
  if (url && type.replace("application/", "") === "pdf") {
    let pdfWindow = window.open("", "_blank");
    pdfWindow.document.write(
      `<iframe width='100%' height='100%' src='${url}'></iframe>`
    );
  } else {
    window.open(url, "_blank");
  }
};

export const downloadFileOnLocal = (base64data, uri, name, ext) => {
  if (window.Cypress) {
    return;
  }
  let downloadLink = document.createElement("a");
  downloadLink.href = base64data
    ? `data:application/${_get(MIMETYPE, ext, "txt")};base64, ${encodeURI(
        base64data
      )}`
    : uri;
  downloadLink.download = `${name}${
    _get(EXTENSIONS, ext, "") ? "." + _get(EXTENSIONS, ext, "") : ""
  }`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

const Preview = ({
  preview: {
    show = false,
    type = "",
    data = "",
    name = null,
    isbase64 = false,
    ext = "",
    uri = "",
  },
}) => (
  <div>
    <Modal
      show={show}
      size="lg"
      backdrop="static"
      onHide={resetPreview}
      title={
        <div className="w-100">
          <strong>
            {name.replace(`.${_get(EXTENSIONS, ext, "txt")}`, "")}
            {ext ? `.${_get(EXTENSIONS, ext, "txt")}` : ""}
          </strong>
          <i
            onClick={() => {
              previewInNewTab(
                data
                  ? `data:application/${_get(
                      MIMETYPE,
                      ext,
                      "txt"
                    )};base64, ${encodeURI(data)}`
                  : uri,
                ext
              );
            }}
            id="preview-download-header"
            className="fas fa-expand float-end cursor-pointer mt-1 me-2"
            title="expand"
            style={{ fontSize: 16 }}
          />
        </div>
      }
    >
      <If condition={data || uri}>
        <div id="docviewer-wrapper">
          <DocViewer
            data={
              data
                ? `data:application/${_get(
                    MIMETYPE,
                    ext,
                    "txt"
                  )};base64, ${data}`
                : null
            }
            name={name}
            ext={ext}
            isbase64={isbase64}
            onClose={resetPreview}
            uri={uri}
          />
        </div>
      </If>
    </Modal>
  </div>
);

function mapStateToProps(state) {
  return {
    preview: state.preview,
  };
}

export default connect(mapStateToProps, null)(Preview);
