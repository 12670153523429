import React, { useEffect } from "react";
import { ReducersTypes } from "constants/ReducersTypes";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import parse from "html-react-parser";
import { store } from "store";
import Button from "components/Button";
import { getLocaleString } from "utils/localization/locale";

export const setconfirmationboxData = (data) => {
  store.dispatch({
    type: ReducersTypes.CONFIRMATION_BOX,
    payload: data,
  });
};

export const resetconfirmationboxData = () => {
  store.dispatch({
    type: ReducersTypes.CONFIRMATION_BOX_RESET,
  });
};

function ConfirmationBox(props) {
  const {
    confirmationbox: {
      msg = "",
      onSave,
      okButtonText,
      okVariant = "",
      cancelButtonTex,
      cancelVariant = "",
      hideCancel,
      hideOkButton = false,
      onCancel,
    },
  } = props;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    if (onCancel) {
      onCancel();
    }
    resetconfirmationboxData();
  };

  function onSubmit(event) {
    event.preventDefault();
    onSave && onSave();
    resetconfirmationboxData();
  }

  useEffect(() => {
    msg &&
      document.getElementById("confirmationbox-submit") &&
      document.getElementById("confirmationbox-submit").focus();
  }, [msg]);

  return (
    <Modal
      show={msg !== ""}
      onHide={handleClose}
      className="mt-4 confirmation-box"
      backdrop="static"
    >
      <Modal.Body>
        <div
          style={{
            maxHeight: "350px",
            overflowY: "auto",
          }}
          className="text-center mt-2"
        >
          {parse(msg)}
        </div>
        <form
          id="confirmationbox-form"
          className="row d-flex justify-content-center mt-3"
          onSubmit={onSubmit}
        >
          {!hideCancel && (
            <Button
              id="confirmationbox-cancel"
              variant={`${cancelVariant || "secondary"}`}
              onClick={handleClose}
              className="col-sm-5"
              type="button"
            >
              {cancelButtonTex || getLocaleString("common.cancel", "Cancel")}
            </Button>
          )}
          {!hideOkButton && (
            <Button
              id="confirmationbox-submit"
              type="submit"
              variant={`${okVariant || "primary"}`}
              className="ms-3 col-sm-5"
            >
              {okButtonText || getLocaleString("common.confirm", "Confirm")}
            </Button>
          )}
        </form>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  confirmationbox: state.confirmationbox,
});

export default connect(mapStateToProps, null)(ConfirmationBox);
