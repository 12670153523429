import React, { useRef } from "react";
import { DndProvider, createDndContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import PropTypes from 'prop-types';

const RNDContext = createDndContext(HTML5Backend);

function useDNDProviderElement(props) {
  const manager = useRef(RNDContext);
  if (!props.children) return null;
  return <DndProvider manager={manager.current.dragDropManager}>{props.children}</DndProvider>;
}

export default function DNDContainer(props) {
  const DNDElement = useDNDProviderElement(props);
  return <React.Fragment>{DNDElement}</React.Fragment>;
}

DNDContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.object]),
    scrollContainerClass: PropTypes.string,
};

