import { ReducersTypes } from "constants/ReducersTypes";

export const initialData = {
  data: [],
  fileType: "",
  selected: "",
  isFetching: false,
};

const helpPageResources = (state = initialData, { type, payload }) => {
  switch (type) {
    case ReducersTypes.HELP_PAGE_DETAILS_FETCH:
      return {
        ...state,
        isFetching: payload.isFetching,
        data: payload.data,
        fileType: payload.fileType,
        selected: payload.selected,
      };

    default:
      return state;
  }
};

export default helpPageResources;

export const helpCardOptions = [
  {
    title: "User Guide",
    fileType: "DOC",
    fileName: "HELP_FILE",
    iconClass: "fas fa-book me-2",
  },
  {
    title: "Cash Flow Tutorial",
    fileType: "VIDEO",
    fileName: "CASH_FLOW_TRAINING",
    iconClass: "fas fa-list me-2",
  },
  {
    title: "AR Collections Tutorial",
    fileType: "VIDEO",
    fileName: "AR_TRAINING_ADVANCED",
    iconClass: "fas fa-hand-holding-usd me-2",
  },
  {
    title: "Settings Tutorial",
    fileType: "VIDEO",
    fileName: "SETTINGS_AND_CONFIGURATION",
    iconClass: "fas fa-cog me-2",
  },
  {
    title: "Scheduled Statements Tutorial",
    fileType: "VIDEO",
    fileName: "SCHEDULED_STATEMENTS",
    iconClass: "fas fa-calendar-alt me-2",
  },
  {
    title: "Email Templates Tutorial",
    fileType: "VIDEO",
    fileName: "EMAIL_TEMPLATES",
    iconClass: "fas fa-envelope me-2",
  },
];
