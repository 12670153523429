/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./payroll.css";
import Button from "components/Button";

export class Entity extends Component {
  render() {
    return (
      <div className="row px-15" id="dashboardBody">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body payrollmanage">
              <div className="row">
                <div className="col-md-12">
                  <form action="" method="post">
                    <div className="row">
                      <div className="col-md-12">
                        <div style={{ clear: "both" }}>
                          <div
                            className="titletranshist"
                            style={{
                              fontSize: "18px",
                              paddingBottom: "15px",
                              float: "left",
                            }}
                          >
                            <Link to="/settings" key="back">
                              <i
                                className="fa fa-arrow-left"
                                data-tlabel="PaySchedule left-arrow"
                                style={{ color: "#ddd", marginRight: "15px" }}
                              />
                            </Link>
                            Payroll - Entity
                          </div>
                          <div className="dropdown float-end">
                            <Button
                              as="a"
                              className="dropdown-toggle"
                              href="#"
                              role="button"
                              id="export"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Export
                            </Button>
                            <div
                              className="dropdown-menu dropdown-menu-end right-0"
                              aria-labelledby="dropdownMenuLink"
                              id="exportlist"
                            >
                              <a href="#">Export CSV</a>
                              <a href="#">Export Excel</a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="divider"></div>
                        <div className="">
                          <div
                            className="titletranshist"
                            style={{ fontSize: 18, marginBottom: 15 }}
                          >
                            Add Entity
                          </div>

                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group mb-3">
                                <label>Entity</label>
                                <input
                                  type="text"
                                  name=""
                                  className="form-control"
                                  placeholder="Enter ..."
                                />
                              </div>
                            </div>
                            <div className="col-md-8">
                              <div
                                className="form-group mb-3"
                                style={{ textAlign: "right", marginTop: 30 }}
                              >
                                <Button className="payrollsumbtn">
                                  Submit
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="divider"></div>
                        <div className="">
                          <div
                            className="titletranshist"
                            style={{ fontSize: 18, marginBottom: 15 }}
                          >
                            List of entity
                          </div>
                          <form action="" method="post">
                            <div className="payrolltable">
                              <table>
                                <tbody>
                                  <tr>
                                    <th>Sr no.</th>
                                    <th>Entity</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                  </tr>
                                  <tr>
                                    <td>1</td>
                                    <td>Entity One</td>
                                    <td>
                                      <a href="#" className="btn  editdltbtn">
                                        <i
                                          className="fa fa-pencil-square-o"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        Edit
                                      </a>
                                    </td>
                                    <td>
                                      <a href="#" className="btn  editdltbtn">
                                        <i
                                          className="fa fa-trash-o"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        Delete
                                      </a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>2</td>
                                    <td>Entity Two</td>
                                    <td>
                                      <a href="#" className="btn  editdltbtn">
                                        <i
                                          className="fa fa-pencil-square-o"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        Edit
                                      </a>
                                    </td>
                                    <td>
                                      <a href="#" className="btn  editdltbtn">
                                        <i
                                          className="fa fa-trash-o"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        Delete
                                      </a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>3</td>
                                    <td>Entity Three</td>
                                    <td>
                                      <a href="#" className="btn  editdltbtn">
                                        <i
                                          className="fa fa-pencil-square-o"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        Edit
                                      </a>
                                    </td>
                                    <td>
                                      <a href="#" className="btn  editdltbtn">
                                        <i
                                          className="fa fa-trash-o"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        Delete
                                      </a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>4</td>
                                    <td>Entity Four</td>
                                    <td>
                                      <a href="#" className="btn  editdltbtn">
                                        <i
                                          className="fa fa-pencil-square-o"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        Edit
                                      </a>
                                    </td>
                                    <td>
                                      <a href="#" className="btn  editdltbtn">
                                        <i
                                          className="fa fa-trash-o"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        Delete
                                      </a>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Entity);
