import React, { Component } from "react";
import { getLocaleString } from "utils/localization/locale";
import { trackEvent } from "utils/loggly-tracker";
import Button from "./Button";
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // log the error to loggly
    const lastButtonClick = localStorage.getItem("lastAction") && JSON.parse(localStorage.getItem("lastAction"));
    const message = `Page crash on ${window.location.pathname}`;
    trackEvent(message, 'error', { error, info, lastButtonClick });
    this.setState({ hasError: true })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh'}}>
          <div className="border w-50 p-3 bg-white text-center">
            <h1>{getLocaleString("common.something_went_wrong_error", "Something went wrong. Please reload page.")}</h1>
            <div>
              <Button className="mt-2 t-14"
                onClick={() => window.location.reload()}>
                  {getLocaleString("common.reload", "Reload")}
              </Button>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
