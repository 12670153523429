import React from "react";

export const Reloading = (props) => {
  return (
    <div className="row px-15" id="dashboardBody">
      <div className="col-md-12 my-5 p-5 d-flex justify-content-center">
        <div
          className="spinner-border"
          style={{
            width: "3em",
            height: "3em",
          }}
        />
      </div>
    </div>
  );
};

export default Reloading;
