import { instanceWithRetry } from "actions/axiosInstance";
import { handleAPIError } from "handlers/setters";
import { generateQueryString } from "utils/query";
import { trimText } from "utils/strings";
import uniqBy from "lodash/uniqBy";

export const getCreditApplicationReports = (params = {}) => {
    return instanceWithRetry.get(`/credit/reports${generateQueryString(params)}`)
    .then(response => response.data)
    .catch(e => handleAPIError(e))
};

export const searchCustomer = (text, callback) => {
    if (text) {
      instanceWithRetry
        .get(`customer/rel/search?name=${trimText(text)}&per_page=100`)
        .then((res) => {
          const options = uniqBy(res.data.result, "id");
          callback(options);
        })
        .catch((e) => handleAPIError(e));
    } else {
      callback([]);
    }
  };