import { handleAPIError } from "handlers/setters";
import { generateQueryString } from "utils/query";
import { instanceWithRetry, instanceMultipart } from "actions/axiosInstance";

export const uploadRemittance = (e) => {
  const bodyFormData = new FormData();
  const fileList = Object.values(e.target.files);
  fileList.forEach((file) => bodyFormData.append("file", file, file.name));
  return instanceMultipart
    .post(`/cash_app/uploadfile?mutate=1`, bodyFormData)
    .catch((e) => handleAPIError(e, true));
};

export const deleteRemittance = (id) =>
  instanceWithRetry.delete(`/cash_app/file/delete/${id}?mutate=1`).catch((e) => handleAPIError(e));

export const getFileList = (params = {}) =>
  instanceWithRetry
    .get(`/cash_app/files${generateQueryString(params)}`)
    .catch((e) => handleAPIError(e));

export const getPaymentDetails = (id, params = {mutate: 1}) =>
  instanceWithRetry
    .get(`/cash_app/invoices/${id}${generateQueryString(params)}`)
    .catch((e) => handleAPIError(e));

export const getInvoicePaymentDetail = (id, lineId, params = {mutate: 1}) =>
  instanceWithRetry
    .get(`/cash_app/user_selections/${id}${generateQueryString(params)}`)
    .catch((e) => handleAPIError(e));

export const applyChargeback = (id, user_selection_id, params = {}) =>
  instanceWithRetry
    .post(
      `/cash_app/user_selections/chargeback/${id}/${user_selection_id}?mutate=1`,
      params
    )
    .catch((e) => handleAPIError(e));

export const deleteChargeback = (id, user_selection_id, params = {}) =>
  instanceWithRetry
    .delete(
      `/cash_app/user_selections/chargeback/${id}/${user_selection_id}?mutate=1`,
      params
    )
    .catch((e) => handleAPIError(e));

export const updatePayment = (id, user_selection_id, params = {}) =>
  instanceWithRetry
    .put(
      `/cash_app/user_selections/${id}/${user_selection_id}?mutate=1`,
      params
    )
    .catch((e) => handleAPIError(e));

export const applyPayment = (id, params = {}) =>
  instanceWithRetry
    .put(`/cash_app/user_selections/apply/${id}?mutate=1`, params)
    .catch((e) => handleAPIError(e));

export const getSyncRequest = (id, params = {}) =>
  instanceWithRetry
    .get(`/cash_app/sync_requests/${id}${generateQueryString(params)}`)
    .catch((e) => handleAPIError(e));

export const addSyncRequest = (id, params = {}) =>
  instanceWithRetry
    .put(`/cash_app/sync_requests/add/${id}${generateQueryString(params)}`)
    .catch((e) => handleAPIError(e));

export const updateSyncRequest = (id, erpId, params = {}) =>
  instanceWithRetry
    .post(`/cash_app/sync_requests/${id}/${erpId}?mutate=1`, params)
    .catch((e) => handleAPIError(e));

export const postSyncRequest = (id, erpId, params = {}) =>
  instanceWithRetry
    .post(`/cash_app/sync_requests/sync/${id}/${erpId}?mutate=1`, params)
    .catch((e) => handleAPIError(e));

export const getDeductionCode = (params = {}) =>
  instanceWithRetry
    .get(`/cash_app/deduction_codes${generateQueryString(params)}`)
    .catch((e) => handleAPIError(e));

export const getSyncEditableFields = (id) =>
  instanceWithRetry
    .get(`/cash_app/sync_requests/editable/${id}`)
    .catch((e) => handleAPIError(e));

export const getFileFormats = () =>
  instanceWithRetry
    .get(`/cash_app/file_formats`)
    .catch((e) => handleAPIError(e));
