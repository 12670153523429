import React from "react";
import moment from "moment";
import get from "lodash/get";
import { CSVLink } from "react-csv";
import uniqBy from "lodash/uniqBy";
import omit from "lodash/omit";
import If from "components/If";
import debounce from "lodash/debounce";
import Autocomplete from "components/Autocomplete";
import DateRangePicker from "components/DateRangePicker";
import AsyncSelect from "react-select/async";
import { getuxvalue, setuxvalue } from "handlers/ux";
import { enableForVoltyx } from "handlers/features";
import { startDate, endDate } from "./reducer";
import { getCustomFields, getEnabledKeysValue } from "utils";
import { getCustomFieldDropdownData } from "./actions";
import Button from "components/Button";
import { ExportXLSX } from "utils/table-utils";
import Dropdown from "components/Button/Dropdown";
import Toaster from "components/Toaster";
import TokenManager from "utils/TokenManager";
import { getLocaleString } from "utils/localization/locale";

export const initialVendorListFilters = {
  to_date: endDate,
  showFilter: false,
  interval: "",
  showexport: false,
  from_date: startDate,
  selectedVendor: null,
  originalendDate: endDate,
  vendorSearchList: [],
  isVendorFetching: false,
  originalstartDate: startDate,
  visibleColumnsShow: false,
};
export default class FilterAndExport extends React.Component {
  state = {
    ...initialVendorListFilters,
    ...getuxvalue("SUPPLIER_LIST_FILTERS"),
    columnsCount: 0,
    exportAllColumns: get(
      getuxvalue("EXPORT_ALL_COLUMNS"),
      "vendorList",
      false
    ),
  };
  searchCustomFieldValue = debounce(getCustomFieldDropdownData.bind(this), 500);

  setshowFilter = (showFilter) => this.setState({ showFilter });

  async componentDidMount() {
    const {
      vendorListData: { visibleColumns },
    } = this.props;

    const newCol = Object.keys(visibleColumns).filter(
      (key) => visibleColumns[key]
    );
    this.setState({
      columnsCount: Object.keys(newCol).length,
    });
  }

  resetFilter = () => {
    const {
      vendorListData: { vendorListFilters },
    } = this.props;
    this.props.setAppliedCustomFilters(null);
    this.setState(
      {
        ...initialVendorListFilters,
      },
      () => {
        this.props.resetVendorFilters({
          ...initialVendorListFilters,
          activeKey: get(
            this.props,
            "vendorListData.vendorListFilters.activeKey",
            ""
          ),
          reloadChart: !get(vendorListFilters, "reloadChart", false),
        });
      }
    );
    this.setshowFilter(false);
  };

  renderAsyncSearch = (data) => {
    const { appliedCustomFilters, masterFieldValue } = this.props;
    const defaultValue = get(
      appliedCustomFilters,
      `[${data.id}][erp_field_string_value_${data.id}]`,
      ""
    );
    let defaultOptions = get(masterFieldValue, `${data.id}`, []);

    /* If there are options to show, then add a special option '-' which is used to 
       filter records with the given custom field not set*/
    if (defaultOptions.length > 0) {
      defaultOptions = [{ label: "-", value: "-" }, ...defaultOptions];
    }
    return (
      <AsyncSelect
        isClearable={true}
        cacheOptions
        placeholder={`Search for ${get(data, "erp_field_name", "")}`}
        defaultOptions={defaultOptions}
        loadOptions={(text, callback) =>
          this.searchCustomFieldValue(
            {
              search_text: text,
              per_page: 10,
              custom_field_master_id: get(data, "id", ""),
            },
            callback
          )
        }
        menuPlacement="auto"
        value={
          defaultValue ? [{ label: defaultValue, value: defaultValue }] : ""
        }
        onChange={(option) => {
          this.props.setAppliedCustomFilters({
            ...appliedCustomFilters,
            [data.id]: {
              [`erp_field_string_value_${data.id}`]: option ? option.value : "",
              custom_field_master_id: data.id,
            },
          });
        }}
      />
    );
  };

  filterData = (reloadChart = true) => {
    const { selectedVendor } = this.state;
    const { appliedCustomFilters } = this.props;
    const {
      vendorListData: { vendorListFilters },
    } = this.props;

    if (selectedVendor) {
      this.props.goToDetailsPage(selectedVendor);
      return;
    }
    const appliedFilters = omit({ ...this.state }, [
      "showFilter",
      "showexport",
      "columnsCount",
      "vendorSearchList",
      "isVendorFetching",
      "visibleColumnsShow",
    ]);
    this.props.setVendorFilters({
      ...appliedFilters,
      appliedCustomFilters,
      reloadChart: reloadChart
        ? !get(vendorListFilters, "reloadChart", false)
        : get(vendorListFilters, "reloadChart", false),
    });
    this.setshowFilter(false);
  };

  setVisibleColumnsShow = (visibleColumnsShow) =>
    this.setState({ visibleColumnsShow });

  setshowexport = (showexport) => this.setState({ showexport });

  setselectedVendor = (selectedVendor) => this.setState({ selectedVendor });

  setVendorListing = ({ vendorSearchList, isVendorFetching }) =>
    this.setState({ vendorSearchList, isVendorFetching });

  exportAllData = (exportType) => {
    if (window.Cypress) {
      return;
    }
    const {
      tags,
      vendorListData: {
        vendorListFilters,
        visibleColumns,
        hideColumns,
        customFilters,
      },
      activeKey,
      ExportCSV,
      columnsOrder,
      getVendorList,
      updateVendorList,
    } = this.props;

    if (exportType === "csv") {
      getVendorList(
        {
          ...vendorListFilters,
          tags,
          activeKey,
          visibleColumns,
          customFilters,
          exportAllColumns: this.state.exportAllColumns,
        },
        "csv"
      ).then(() => this.refs.csv.link.click());
    }
    if (exportType === "excel") {
      getVendorList(
        {
          ...vendorListFilters,
          tags,
          activeKey,
          visibleColumns,
          customFilters,
        },
        "excel"
      ).then((exportedData) =>
        ExportXLSX(
          ExportCSV(
            exportedData,
            visibleColumns,
            hideColumns,
            customFilters,
            this.state.exportAllColumns,
            columnsOrder
          ),
          `vendors_${moment().format("MMMM Do YYYY-h:mm:ss-a")}.xlsx`
        )
      );
    }
    if (exportType === "email") {
      getVendorList(
        {
          ...vendorListFilters,
          tags,
          activeKey,
          visibleColumns,
          customFilters,
          isEmailExport: 1,
        },
        "email"
      ).then((response) => {
        updateVendorList({
          isExporting: false,
        });
        get(response, "data.message", "") &&
          Toaster(
            get(
              response,
              "data.message",
              getLocaleString(
                "common.the_export_data_will_be_sent_to_your_email",
                "The Export data will be sent to your email in sometime."
              )
            ),
            "success"
          );
      });
    }
    this.setshowexport(false);
  };

  renderCustomFilters = (d) => {
    const { appliedCustomFilters, setAppliedCustomFilters } = this.props;
    const uxCustomFilters = get(this.state, "appliedCustomFilters", "");
    if (d.field_type === "STRING") {
      return (
        <div className="form-group mb-3 remove-space">
          <label className="form-label">{d.erp_field_name}</label>
          {this.renderAsyncSearch(d)}
          {/* <input
            type="text"
            placeholder="Enter.."
            defaultValue={get(
              uxCustomFilters,
              `[${d.id}].erp_field_string_value_${d.id}`,
              get(appliedCustomFilters,`[${d.id}].erp_field_string_value_${d.id}`,"")
            )}
            onChange={(e) => {
              setAppliedCustomFilters({
                ...appliedCustomFilters,
                [d.id]: {
                  [`erp_field_string_value_${d.id}`]: e.target.value,
                  custom_field_master_id: d.id,
                },
              });
            }}
            className="form-control"
          /> */}
        </div>
      );
    }
    if (d.field_type === "DATE") {
      const customStartDate = get(
        uxCustomFilters,
        `[${d.id}].erp_field_date_value_${d.id}_from_date`,
        get(
          appliedCustomFilters,
          `[${d.id}].erp_field_date_value_${d.id}_from_date`,
          ""
        )
      );
      const customEndDate = get(
        uxCustomFilters,
        `[${d.id}].erp_field_date_value_${d.id}_to_date`,
        get(
          appliedCustomFilters,
          `[${d.id}].erp_field_date_value_${d.id}_to_date`,
          ""
        )
      );
      return (
        <div className="form-group mb-3">
          <label className="form-label">{d.erp_field_name}</label>
          <div className="single-date-picker t-12 overflow-hidden">
            <DateRangePicker
              startDate={customStartDate ? moment(customStartDate) : ""}
              endDate={customEndDate ? moment(customEndDate) : ""}
              isClearable={customStartDate || customEndDate}
              getSelectedDates={(startDate, endDate, label) => {
                setAppliedCustomFilters({
                  ...appliedCustomFilters,
                  [d.id]: {
                    ...get(appliedCustomFilters, `${d.id}`, {}),
                    [`erp_field_date_value_${d.id}_from_date`]: startDate
                      ? moment(startDate).format("MM/DD/YYYY")
                      : "",
                    [`erp_field_date_value_${d.id}_to_date`]: endDate
                      ? moment(endDate).format("MM/DD/YYYY")
                      : "",
                    custom_field_master_id: d.id,
                  },
                });
              }}
              parentEl="#outerside"
            />
          </div>
        </div>
      );
    }
    if (d.field_type === "FLOAT") {
      return (
        <div className="form-group mb-3 remove-space">
          <label className="d-block form-label">{d.erp_field_name}</label>
          <input
            type="number"
            placeholder={getLocaleString("common.min", "Min")}
            defaultValue={get(
              uxCustomFilters,
              `[${d.id}].erp_field_float_value_${d.id}_greater_than`,
              get(
                appliedCustomFilters,
                `[${d.id}].erp_field_float_value_${d.id}_greater_than`,
                ""
              )
            )}
            onChange={(e) => {
              setAppliedCustomFilters({
                ...appliedCustomFilters,
                [d.id]: {
                  ...get(appliedCustomFilters, `${d.id}`, {}),
                  [`erp_field_float_value_${d.id}_greater_than`]:
                    e.target.value,
                  custom_field_master_id: d.id,
                },
              });
            }}
            className="w-45 d-inline-block me-3 form-control"
          />
          <input
            type="number"
            placeholder={getLocaleString("common.max", "Max")}
            defaultValue={get(
              uxCustomFilters,
              `[${d.id}].erp_field_float_value_${d.id}_less_than`,
              get(
                appliedCustomFilters,
                `[${d.id}].erp_field_float_value_${d.id}_less_than`,
                ""
              )
            )}
            onChange={(e) => {
              setAppliedCustomFilters({
                ...appliedCustomFilters,
                [d.id]: {
                  ...get(appliedCustomFilters, `${d.id}`, {}),
                  [`erp_field_float_value_${d.id}_less_than`]: e.target.value,
                  custom_field_master_id: d.id,
                },
              });
            }}
            className="w-45 d-inline-block form-control"
          />
        </div>
      );
    }
  };

  setExportAllOption = (value) => {
    this.setState(
      {
        exportAllColumns: value,
      },
      setuxvalue("EXPORT_ALL_COLUMNS", {
        ...getuxvalue("EXPORT_ALL_COLUMNS"),
        vendorList: value,
      })
    );
  };

  render() {
    const {
      ExportCSV,
      searchVendor,
      columnsOrder,
      totalRecords,
      setVisibleColumns,
      vendorListData: {
        isExporting,
        exportedData,
        customFilters,
        visibleColumns,
        isVendorListFetching,
        vendorListFilters: { activeKey },
      },
    } = this.props;

    const {
      to_date,
      interval,
      from_date,
      showFilter,
      showexport,
      columnsCount,
      selectedVendor,
      isVendorFetching,
      vendorSearchList,
      exportAllColumns,
      visibleColumnsShow,
    } = this.state;

    return (
      <>
        <div className="row mt-5"></div>
        <div className={`row mb-4 ${activeKey ? "mt-2" : "mt-5"}`}>
          <div className="col-md-6">
            <div className="dropdown d-inline-block me-2">
              <Dropdown
                showDropdown={showFilter}
                buttonProps={{
                  as: "span",
                  role: "button",
                  "data-toggle": "dropdown",
                  "aria-haspopup": "true",
                  "aria-expanded": "false",
                }}
                btnClassName="dropdown-toggle cursor-pointer"
                setShowDropdown={this.setshowFilter}
                buttonText={getLocaleString("common.filter", "Filter")}
                dropdownWrapperClassName="filterdroplist p-2 pe-4 width-500px"
                dropdownWrapperProps={{
                  id: "outerside",
                }}
                filterButton
              >
                <div className="row">
                  <div className="col-md-6">
                    <div>
                      <div>
                        <label className="">
                          {getLocaleString("common.vendorName", "Vendor Name")}
                        </label>
                        <Autocomplete
                          suggestions={uniqBy(
                            vendorSearchList.map((s) => ({
                              id: s.id,
                              name: s.display_string,
                            })),
                            "id"
                          )}
                          getSelected={(item) => this.setselectedVendor(item)}
                          search={(text) =>
                            searchVendor(text, this.setVendorListing)
                          }
                          isLoading={isVendorFetching}
                          defaultValue={selectedVendor && selectedVendor.name}
                        />
                      </div>
                    </div>
                  </div>
                  {activeKey === "apaging" && (
                    <div data-cy="start-date" className="col-md-6">
                      <label className="mb-2">
                        {getLocaleString("common.date", "Date")}
                      </label>
                      <div className="single-date-picker t-12 overflow-hidden">
                        <DateRangePicker
                          allToDate={true}
                          label={interval}
                          startDate={moment(from_date)}
                          endDate={moment(to_date)}
                          isClearable={from_date || to_date}
                          getSelectedDates={(startDate, endDate, interval) => {
                            this.setState({
                              interval,
                              from_date: moment(startDate).format("MM/DD/YYYY"),
                              to_date: moment(endDate).format("MM/DD/YYYY"),
                              originalstartDate:
                                moment(startDate).format("MM/DD/YYYY"),
                              originalendDate:
                                moment(endDate).format("MM/DD/YYYY"),
                            });
                          }}
                          parentEl="#outerside"
                        />
                      </div>
                    </div>
                  )}
                  {!activeKey ? (
                    <div className="col-md-6">
                      <div>
                        <label>
                          {getLocaleString("common.dueDate", "Due date")}
                        </label>
                        <div className="single-date-picker t-12 overflow-hidden">
                          <DateRangePicker
                            label={interval}
                            startDate={moment(from_date)}
                            endDate={moment(to_date)}
                            isClearable={from_date || to_date}
                            getSelectedDates={(
                              startDate,
                              endDate,
                              interval
                            ) => {
                              this.setState({
                                from_date:
                                  moment(startDate).format("MM/DD/YYYY"),
                                to_date: moment(endDate).format("MM/DD/YYYY"),
                                interval,
                              });
                            }}
                            parentEl="#outerside"
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <If condition={customFilters && customFilters.length}>
                    <>
                      {customFilters.map((d) => (
                        <div className="col-md-6 mt-2">
                          {this.renderCustomFilters(d)}
                        </div>
                      ))}
                    </>
                  </If>

                  <div className="col-md-6" />
                  <div className="col-md-6 ms-auto">
                    <div className="setbtn mt-3">
                      <Button
                        type="reset"
                        variant="light"
                        className="resetbtn"
                        data-cy="resetbtn"
                        onClick={this.resetFilter}
                      >
                        {getLocaleString("common.reset", "Reset")}
                      </Button>
                      <Button
                        type="submit"
                        variant="primary"
                        className="applybtn"
                        data-cy="applybtn"
                        onClick={this.filterData}
                      >
                        {getLocaleString("common.submit", "Submit")}
                      </Button>
                    </div>
                  </div>
                </div>
              </Dropdown>
            </div>
            <span className="dropdown d-inline-block">
              <Dropdown
                showDropdown={visibleColumnsShow}
                buttonProps={{
                  as: "span",
                  role: "button",
                  "data-toggle": "dropdown",
                  "aria-haspopup": "true",
                  "aria-expanded": "false",
                  "data-cy": "columns-dropdown",
                }}
                btnClassName="ms-2"
                setShowDropdown={() =>
                  this.setVisibleColumnsShow(!visibleColumnsShow)
                }
                buttonText={getLocaleString("common.columns", "Columns")}
                dropdownWrapperClassName="p-0"
                dropdownWrapperProps={{
                  id: "companylist",
                  style: {
                    maxHeight: "50vh",
                    overflowY: "auto",
                    minWidth: "13rem",
                  },
                }}
                columnsButton
              >
                {Object.keys(visibleColumns).map((acc) => {
                  return (
                    <span
                      className={`${
                        visibleColumns[acc] ? "bg-option-selected-grey" : ""
                      }`}
                      onClick={async () => {
                        if (
                          (columnsCount === 1 && visibleColumns[acc]) ||
                          isVendorListFetching
                        )
                          return;
                        this.setState({
                          columnsCount: visibleColumns[acc]
                            ? columnsCount - 1
                            : columnsCount + 1,
                        });

                        const _columns = {
                          ...visibleColumns,
                          [acc]: !visibleColumns[acc],
                        };
                        setVisibleColumns(_columns);

                        const keys = await getCustomFields(
                          customFilters,
                          "keys"
                        );
                        const ids = await getEnabledKeysValue(
                          customFilters,
                          visibleColumns
                        );
                        if (keys.includes(acc) && !ids.length) {
                          this.filterData(false);
                          this.props.getList();
                        }
                      }}
                    >
                      <input
                        type="checkbox"
                        className="me-2 d-inline-block align-middle"
                        name={acc}
                        checked={visibleColumns[acc]}
                        data-tlabel={`checkbox ${acc}`}
                        disabled={columnsCount === 1 || isVendorListFetching}
                      />
                      <span className="d-inline-block align-middle p-0 w-80">
                        {acc === "Balance" ? "Current" : acc}
                      </span>
                    </span>
                  );
                })}
              </Dropdown>
            </span>
          </div>
          <div className="col-md-6 text-end">
            <div className="dropdown">
              {totalRecords ? (
                <span className="m-2">
                  {getLocaleString("common.total", "Total")}: {totalRecords}
                </span>
              ) : null}
              <CSVLink
                data={ExportCSV(
                  exportedData || [],
                  visibleColumns,
                  get(this.props.vendorListData, "hideColumns", []),
                  customFilters,
                  exportAllColumns,
                  columnsOrder
                )}
                filename={`vendors_${moment().format(
                  "MMMM Do YYYY-h:mm:ss-a"
                )}.csv`}
                ref="csv"
                style={{ display: "none" }}
              />
              <Dropdown
                showDropdown={showexport}
                buttonProps={{
                  as: "span",
                  role: "button",
                  "data-toggle": "dropdown",
                  "aria-haspopup": "true",
                  "aria-expanded": "false",
                  "data-cy": "export",
                  endIcon: isExporting && (
                    <i className="fa fa-spinner fa-spin" />
                  ),
                }}
                setShowDropdown={() => this.setshowexport(!showexport)}
                buttonText={getLocaleString("common.exportTitle", "Export")}
                dropdownWrapperClassName="dropdown-menu-end right-0 payexpdroplist right-0"
                dropdownWrapperProps={{
                  id: "payment-export",
                }}
              >
                {enableForVoltyx() ? (
                  <span className="t-12 d-block text-end cursor-pointer mb-1 me-2">
                    <label className="m-0">
                      <input
                        checked={exportAllColumns}
                        onChange={(e) =>
                          this.setExportAllOption(e.target.checked)
                        }
                        type="checkbox"
                        className="align-middle me-1"
                      />{" "}
                      <span className="align-middle">
                        {getLocaleString("common.all_data", "All Data")}
                      </span>
                    </label>
                  </span>
                ) : null}
                <span
                  className="t-12 d-block text-end cursor-pointer mb-1 pe-2"
                  onClick={() => this.exportAllData("csv")}
                >
                  {getLocaleString("common.exportCSV", "Export CSV")}
                </span>
                <span
                  className="t-12 d-block text-end cursor-pointer pe-2 mb-1"
                  onClick={() => this.exportAllData("excel")}
                >
                  {getLocaleString("common.exportExcel", "Export Excel")}
                </span>
                {TokenManager.getAllAdmins() && (
                  <span
                    className="t-12 d-block text-end cursor-pointer pe-2"
                    onClick={() => this.exportAllData("email")}
                  >
                    {getLocaleString("common.emailExport", "Email Export")}
                  </span>
                )}
              </Dropdown>
            </div>
          </div>
        </div>
      </>
    );
  }
}
