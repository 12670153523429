import { formatLocaleCurrency, getCurrency } from "country-currency-map";
import TokenManager from "utils/TokenManager";

export const nonStandardCurrencyString = {
  "European Euro": "EUR",
  "Swiss Franc": "CHF",
  "British pound": "GBP",
  "Canadian dollar": "CAD",
  "Chinese Yuan (RMB)": "CNY",
  "USA": "USD",
  "YUAN": "CNY",
  "AFN": "AFA",
  "DOLLAR": "USD"
}; // update all non standard format here with correct format value

export const currencySymbol = () => {
  return getCurrency(TokenManager.getCurrencyCode()).symbolFormat.replace("{#}", "")
}

const formatCurrency = (
  amount,
  currency,
  shortCode = false
  ) => {
  if (!currency) {
    return;
  }
  let convertedAmount = !amount || amount === 'undefined' || amount === "0" || amount === null || typeof amount === 'string' ? 0 : amount;
  const updatedCurrency = nonStandardCurrencyString[currency] ? nonStandardCurrencyString[currency] : currency;
  const _currencySymbol = getCurrency(updatedCurrency) ? getCurrency(updatedCurrency).symbolFormat.replace("{#}", "") : updatedCurrency;
  const localCurrency = (formatLocaleCurrency(0, updatedCurrency, {
    locale: 'en-US'
  }).replace("0", ''));
  const isnegative = Number(convertedAmount) < 0;
  if (shortCode) {
    let shortAbbrUnit = "";
    const absNumb = Math.abs(convertedAmount);
    const sign = Math.sign(convertedAmount);
    if (absNumb > 999999999) {
      convertedAmount = sign * (absNumb / 1000000000);
      shortAbbrUnit = "B";
    } else if (absNumb > 999999) {
      convertedAmount = sign * (absNumb / 1000000);
      shortAbbrUnit = "M";
    } else if (absNumb > 99999) {
      convertedAmount = sign * (absNumb / 1000);
      shortAbbrUnit = "K";
    }
    let str = `${formatLocaleCurrency(convertedAmount, updatedCurrency, {
      abbreviate: shortCode,
      locale: 'en-US'
    })}${shortAbbrUnit}`;
    if(str && localCurrency.length >= 3 && localCurrency !== _currencySymbol) {
      str = str.replace(localCurrency, _currencySymbol).trim();
    }
    return `${isnegative ? "-" : ""}${str.substring(str.indexOf(_currencySymbol))}`.replace("--", "-").replace('NaN', '0');
  }

  let str = `${formatLocaleCurrency(amount, updatedCurrency, {
    abbreviate: shortCode,
    locale: 'en-US'
  })}`;
  if(str && localCurrency.length >= 3 && localCurrency !== _currencySymbol) {
    str = str.replace(localCurrency, _currencySymbol).trim();
  }
  return `${isnegative ? "-" : ""}${str.substring(
    str.indexOf(_currencySymbol)
  )}`
    .replace("--", "-")
    .replace("NaN", "0");
};

export default formatCurrency;

export const checkNonstandardCurrencyFormat = (currency, handleError = false) => {
  if (!currency) return;
  const baseClientCurrency = TokenManager.getCurrencyCode();
  if (getCurrency(currency)) {
    if (handleError) {
      return false;
    }
    return currency;
  } else {
    if (handleError) {
      return nonStandardCurrencyString[currency] ? false : true;
    }
    return nonStandardCurrencyString[currency] || baseClientCurrency;
  }
}