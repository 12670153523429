import TokenManager from "./TokenManager";
import fe_config from "constants/Configs";
export const createScriptTag = () => {
  const script = document.createElement("script");
  const accountData = TokenManager.getPaymentAccountTypeData();
  if (document.getElementById("pgscript")) {
    document.getElementById("pgscript").remove();
  }

  script.setAttribute("defer", true);
  script.setAttribute("id", "pgscript");
  if (accountData && accountData.account_type === "EASYPAY") {
    script.src = "https://secure.safewebservices.com/token/Collect.js";
    script.setAttribute("data-tokenization-key", accountData.account_id);
  }

  if (accountData && accountData.account_type === "WORLDPAY") {
    script.src = fe_config.MODE === "PROD" ? "https://request.eprotect.vantivcnp.com/eProtect/js/eProtect-iframe-client4.min.js" : "https://request.eprotect.vantivprelive.com/eProtect/js/eProtect-iframe-client4.min.js";
  }

  if (!accountData || accountData.account_type === "STRIPE") {
    script.src = "https://js.stripe.com/v3/";
  }

  document.head.appendChild(script);
};
