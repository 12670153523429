import { ReducersTypes } from "constants/ReducersTypes";
import { getUXSettingsFromStorage } from "utils";
import moment from "moment";
import omit from "lodash/omit";
import { getDateByRangeInterval } from "utils/dates";
import { enableEasyPayPG, enableWorldPayPG } from "handlers/features";
import { enableDefaultAllDateFilter } from "handlers/features";
import { getLocaleString } from "utils/localization/locale";

const dateRange = {
  from_date: enableDefaultAllDateFilter()
    ? moment(0).format("MM/DD/YYYY")
    : moment().subtract(30, "days").format("MM/DD/YYYY"),
  to_date: enableDefaultAllDateFilter()
    ? moment().add(1, "year").format("MM/DD/YYYY")
    : moment().format("MM/DD/YYYY"),
};

export const REPORTS_PAYMENT_COLUMNS = {
  [getLocaleString("common.customer", "Customer")]: true,
  [getLocaleString("common.invoice", "Invoice")]: true,
  [getLocaleString(
    "common.show_payment_details",
    "Show Payment Details"
  )]: false,
  [getLocaleString("payments.net_amount", "Net Amount")]: true,
  [getLocaleString("common.paymentMethod", "Payment Method")]: true,
  [getLocaleString("payments.initiated_date", "Initiated Date")]: true,
  [getLocaleString("payments.last_updated_at", "Last Updated At")]: false,
  [getLocaleString("common.status", "Status")]: true,
  [getLocaleString("common.message", "Message")]: false,
  [getLocaleString("payments.receipt", "Receipt")]: true,
  [getLocaleString("payments.refund", "Refund")]: enableEasyPayPG(),
  [getLocaleString("payments.payout_id", "Payout ID")]: true,
  [getLocaleString("payments.payout_date", "Payout Date")]: true,
  [getLocaleString("payments.payout_amount", "Payout Amount")]: true,
  [getLocaleString("payments.transaction_id", "Transaction ID")]:
    enableWorldPayPG(),
  [getLocaleString("payments.posting_status", "Posting Status")]: false,
};

export const REPORTS_PAYMENT_COLUMNS_ORDER = {
  Customer: 1,
  Invoices: 2,
  "Show Payment Details": 3,
  "Net Amount": 4,
  "Payment Method": 5,
  "Initiated Date": 6,
  "Last Updated At": 7,
  Status: 8,
  Message: 9,
  Receipt: 10,
  Refund: 11,
  "Payout ID": 12,
  "Payout Date": 13,
  "Payout Amount": 14,
  "Transaction ID": 15,
  "Posting Status": 16,
};

let savedFilters = getUXSettingsFromStorage("ANALYTICS_PAYMENTS_FILTERS");
if (savedFilters && savedFilters.interval) {
  const { interval, from_date, to_date } = savedFilters;
  const [sd, ed] = getDateByRangeInterval(interval, from_date, to_date);
  savedFilters = {
    ...savedFilters,
    from_date: sd,
    to_date: ed,
  };
}

export const initialData = {
  listData: [],
  isFetching: false,
  filters: {
    sort_by: "latest_status_date",
    status: "",
    sort_order: "desc",
    ...dateRange,
    ...savedFilters,
    payment_type: enableEasyPayPG()
      ? savedFilters && savedFilters.payment_type
        ? savedFilters.payment_type
        : "PAYMENT"
      : "",
  },
  isReceiptFetching: "",
  total: null,
  pagination: {
    next_num: 2,
    pages: 2,
    prev_num: 1,
  },
};

const paymentAnalyticsDetails = (state = initialData, { type, payload }) => {
  switch (type) {
    case ReducersTypes.PAYMENT_ANALYTICS_LIST_FETCHING:
      return {
        ...state,
        isFetching: true,
        listData: [],
      };

    case ReducersTypes.PAYMENT_ANALYTICS_LIST_RESET:
      return {
        listData: [],
        isFetching: true,
        filters: {
          sort_by: "latest_status_date",
          status: "",
          payment_type: "",
          sort_order: "desc",
          ...dateRange,
        },
        isReceiptFetching: "",
        pagination: {
          next_num: null,
          pages: 1,
          prev_num: 1,
        },
      };

    case ReducersTypes.PAYMENT_ANALYTICS_LIST_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
      };

    case ReducersTypes.PAYMENT_ANALYTICS_LIST:
      return {
        ...state,
        isFetching: false,
        listData: payload.data.result,
        filters: {
          ...omit(state.filters, ["is_export"]),
          ...omit(payload.data.filters, ["is_export"]),
        },
        ...(payload.data.filters.is_export
          ? {}
          : { total: payload.data.total }),
        pagination: {
          next_num: payload.data.next_num,
          pages: payload.data.pages,
          prev_num: payload.data.prev_num,
        },
      };

    case ReducersTypes.PAYMENT_RECEIPT_FETCH:
      return {
        ...state,
        isReceiptFetching: payload.data,
      };

    default:
      return state;
  }
};

export default paymentAnalyticsDetails;
