import { ReducersTypes } from "constants/ReducersTypes";

export const initialData = {
  isActivityFetching: false,
  supplierRelDetails: "",
  activityFilters: {
    sort_by: "delivery_date",
    status: "",
    sort_order: "desc",
    pagination: {
      next_num: 2,
      pages: 2,
      prev_num: 1,
    },
  },
  activityList: [],
  isInvoiceFetching: false,
  invoiceFilters: {
    sort_by: "date",
    status: "",
    sort_order: "desc",
    pagination: {
      next_num: 2,
      pages: 2,
      prev_num: 1,
    },
  },
  invoiceList: [],
};

const invoicingPortal = (state = initialData, { type, payload }) => {
  switch (type) {
    case ReducersTypes.SET_DRIVER_ACTIVITY_LIST:
      return {
        ...state,
        activityFilters: {
          ...state.activityFilters,
          ...payload.filters,
        },
        isActivityFetching: payload.isActivityFetching,
        activityList: payload.activityList,
      };

    case ReducersTypes.SET_DRIVER_INVOICE_LIST:
      return {
        ...state,
        invoiceFilters: {
          ...state.invoiceFilters,
          ...payload.filters,
        },
        isInvoiceFetching: payload.isInvoiceFetching,
        invoiceList: payload.invoiceList,
      };

    case ReducersTypes.SET_DRIVER_INVOICE_FILTER:
      return {
        ...state,
        invoiceFilters: {
          ...state.invoiceFilters,
          ...payload.filters,
        },
      };

    case ReducersTypes.SET_DRIVER_ACTIVITY_FILTER:
      return {
        ...state,
        activityFilters: {
          ...state.activityFilters,
          ...payload.filters,
        },
      };
    case ReducersTypes.SET_SUPPLIER_REL_DETAILS:
      return {
        ...state,
        supplierRelDetails: payload,
      };

    default:
      return state;
  }
};

export default invoicingPortal;
