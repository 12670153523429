/* eslint-disable react-hooks/exhaustive-deps */
import { instance } from "actions/axiosInstance";
import { setUserData } from "actions/user";
import axios from "axios";
import CustomSpinner from "components/Spinner";
import Toaster from "components/Toaster";
import fe_config from "constants/Configs";
import get from "lodash/get";
import React, { useEffect } from "react";
import sessionStorage from "redux-persist/es/storage/session";
import { getParameterByName } from "utils";
import TokenManager from "utils/TokenManager";
import { nonStandardCurrencyString } from "utils/localization/formatCurrency";
import { getLocaleString } from "utils/localization/locale";

export const Component = (props) => {
  const init_token = getParameterByName("token");
  const redirectClientContext = getParameterByName("redirect");
  const isExistingUser = getParameterByName("exists");
  const client_supplier_rel_id = get(props, "match.params.id", "")
  const hash = get(props, "location.hash", "") ? get(props, "location.hash", "").split("?")[0] : '';
  const currentPath = `${get(props, "location.pathname", "")}${hash}`;
  function setAndRedirect() {
    if (currentPath) {
        sessionStorage.setItem("emailRedirectionPath", currentPath);
    }
    
    if (redirectClientContext !== 'canceled') {
      sessionStorage.setItem("client_id_context", redirectClientContext);
    }
    if (client_supplier_rel_id) {
      sessionStorage.setItem(
        "client_supplier_rel_id_context",
        client_supplier_rel_id
      );
    }
    props.history.push("/login");
  }
  useEffect(() => {
    // effect
    if (init_token && !isExistingUser) {
      axios
        .get(`${fe_config.API_URL}token?mutate=1`, {
          auth: {
            username: init_token,
            password: Math.random().toString(20),
          },
        })
        .then((resp) => {
          // normal login now
          const urlToken = resp.data.token;

          axios
            .get(`${fe_config.API_URL}token?mutate=1`, {
              auth: {
                username: urlToken,
                password: Math.random().toString(20),
              },
              headers: {
                client_id_context: redirectClientContext,
              },
            })
            .then((response) => {
              const role = TokenManager.getRole(response.data.roles);
              TokenManager.set(
                response.data.token,
                response.data["expires at"]
              );
              TokenManager.setUserName(
                response.data.username,
                response.data["id"]
              );
              setTimeout(() => {
                const APIs = [
                  `/client/user?id=${TokenManager.getUserId()}`,
                  "client/accessible",
                  "user/entities",
                  "client"
                ].map((x) => instance.get(x));
                Promise.all(APIs).then((responses) => {
                  //corporate_entity_id_context
                  const corporate_entity_id_context = response.data.corporate_entity_id;

                  // userdata
                  const datatemp = {
                    ...response.data,
                    ...get(responses[0], "data.result[0]", {}),
                    roles:
                      response.data.role === "supplier"
                        ? ["supplier"]
                        : response.data.roles,
                    corporate_entity_id_context,
                    client_supplier_rel_id_context: response.data.client_supplier_rel_id,
                    client_id: response.data.client_id,
                    client_address: responses[3].data.result.address,
                    client_name: responses[3].data.result.name,
                    client_logo: responses[3].data.result.logo_url,
                  };

                  //accessible
                  setUserData({
                    datatemp,
                    clients: responses[1].data.result,
                    corporateEntities: responses[2].data.result,
                  });
                  let base_currency = get(response, 'data.corp_entity.currency', null) ? get(response, 'data.corp_entity.currency', 'USD') : get(response, 'data.client.base_currency', 'USD');
                  base_currency = nonStandardCurrencyString[base_currency] ? nonStandardCurrencyString[base_currency] : base_currency;
                  let client_base_currency = get(response, 'data.client.base_currency', 'USD');
                  client_base_currency = nonStandardCurrencyString[client_base_currency] ? nonStandardCurrencyString[client_base_currency] : client_base_currency;
                  const date_format = get(response, 'data.corp_entity.date_format', null) ? get(response, 'data.corp_entity.date_format', 'MM/DD/YYYY') : get(response, 'data.client.date_format', 'MM/DD/YYYY');
                  localStorage.setItem("base_currency", base_currency);
                  localStorage.setItem("client_base_currency", client_base_currency);
                  localStorage.setItem("date_format", date_format);
                  localStorage.setItem("corp_entity_selected", !!get(response, 'data.corp_entity', ''));

                  TokenManager.setUserData(datatemp);

                  //navigate
                  const navigate = () => {
                    const redirectPath = currentPath.includes("vendors") ? `/vendors/rel/${
                      datatemp.client_supplier_rel_id ||
                      client_supplier_rel_id
                    }` : currentPath;
                    window.localStorage.setItem("loggedin", get(datatemp, "id", ""));
                    sessionStorage.setItem("emailRedirectionPath", redirectPath);
                    props.history.replace(redirectPath);
                    window.location.reload();
                    return;
                  };

                  // entityadmin fallback
                  if (
                    role === "entityadmin" &&
                    !get(response, "data.corporate_entity_id", "")
                  ) {
                    const headers = {
                      client_id_context: get(
                        responses[0],
                        "data.result[0].client_id",
                        ""
                      ),
                      corporate_entity_id_context,
                    };
                    instance.get("token?mutate=1", { headers }).then((tokenresponse) => {
                      TokenManager.set(
                        tokenresponse.data.token,
                        tokenresponse.data["expires at"]
                      );
                      navigate();
                    });
                  } else {
                    navigate();
                  }
                });
              }, 300);
            })
            .catch(() => {
              Toaster(getLocaleString("reset_password.authentication_failed", "Authentication failed"), "error");
                sessionStorage.setItem("emailRedirectionPath", currentPath);
              props.history.push("/login");
            });
        })
        .catch((e) => {
          if (get(e, "response.status", "") === 401 && get(e, "response.data", "") === "Unauthorized Access") {
            Toaster(getLocaleString("login.your_login_link_expired", "Your login link expired, please sign up to view/pay the invoices"), "error");
            props.history.push("/user-signup");
          } else {
            setAndRedirect();
          }
        });
    } else {
      setAndRedirect();
    }
    return () => {
      //   cleanup
    };
  }, []);
  return <CustomSpinner isfullscreen />;
};

export default Component;
