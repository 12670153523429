/* eslint-disable no-useless-escape */
export const passwordRegEx = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[~`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?!.*\s).{8,}$/;
export const emailRegEx = /^([a-zA-Z0-9_+\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,10})$/;
export const emailsFromStringRegEx = /([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
export const emailsWithCommasRegEx = /^(\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]{2,10}\s*?,?\s*?)+$/
export const phoneRegEx = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
export const phoneWithCountryCodeRegEx = /^(00|\+)[1-9]{1}([0-9][\s]*){9,16}/;
export const minRangeRegEx = /[1-5]?[0-9]\-[1-5]?[0-9]$/;
export const minsRegEx = /^[1-5]?[0-9](,[1-5]?[0-9])*$/;
export const hoursRegEx = /^(2[0-3]|1[0-9]|[0-9])(,(2[0-3]|1[0-9]|[0-9]))*$/;
export const hourRangeRegEx = /^(2[0-3]|1[0-9]|[0-9])\-(2[0-3]|1[0-9]|[0-9])$/;
export const weekDayRangeRegEx = /^[0-7]\-[0-7]$/;
export const weekDaysRegEx = /^[0-7](,[0-7])*$/;
export const daysRegEx = /^(3[01]|[12][0-9]|[1-9])(,(3[01]|[12][0-9]|[1-9]))*$/;
export const daysRangeRegEx = /^(3[01]|[12][0-9]|[1-9])\-(3[01]|[12][0-9]|[1-9])$/;
export const extractValueInCircleBrackets = /\[(.*?)\]/;
export const ipAddressRegEx = /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/;
export const urlRegEx = /(https?:\/\/[^\s]+)/g;
export const numberRegEx = /^-?\d+$/;
export const numberRegEx2 = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
export const numberWithCountryCodeRegEx = /^\+[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
export const digitRegex = /\d/;
export const onlydigitRegex = /^[0-9]+$/;
export const lettersRegex = /[a-zA-Z]/;
export const onlylettersRegex = /^[a-zA-Z\s]+$/;
export const specialCharsRegex = /^(?=.*[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])(?!.*\s).+$/;

export const sessionRegExString = "[?&]session_id(=([^&#]*)|&|#|$)";
export const cardSessionRegExString = "[?&]card_session_id(=([^&#]*)|&|#|$)";
export const accountSessionRegExString = "[?&]account_session_id(=([^&#]*)|&|#|$)";
export const cardAddFailedRegex = "[?&]redirect(=([^&#]*)|&|#|$)";

export function nameRegExString(name) {
  return "[?&]" + name + "(=([^&#]*)|&|#|$)"
}
