import { actionTypes } from './actions';

const dataObject = {
  data: [],
  isFetching: true,
};
const workflow = (
  state = {
    emailTemplate: dataObject,
    workflowSteps: dataObject,
    workflowList: dataObject,
    workflowSchedule: dataObject,
  },
  action
) => {
  switch (action.type) {
    case actionTypes.FETCH_WORKFLOW_STEPS: {
        return {
          ...state,
          workflowSteps: {
            data: action.payload.data,
            isFetching: false,
          },
        };
    }

    case actionTypes.FETCH_EMAIL_TEMPLATES: {
        return {
          ...state,
          emailTemplate: {
            data: action.payload.data,
            isFetching: false,
          },
        };
    }

    case actionTypes.FETCH_WORKFLOW_LIST: {
        return {
          ...state,
          workflowList: {
            data: action.payload.data,
            isFetching: false,
          },
        };
    }

    case actionTypes.FETCH_SCHEDULE_LIST: {
        return {
          ...state,
          workflowSchedule: {
            data: action.payload.data,
            isFetching: false,
          },
        };
    }

    default:
      return state;
  }
};

export default workflow;
