import React from "react";
const CustomeCheckbox = ({
  lable,
  value,
  checked,
  wrapperClass = "",
  labelClass,
  ...otherprops
}) => (
  <div className={`checkbox ${wrapperClass}`}>
    <input
      type="checkbox"
      id={`checkbox_${lable}`}
      className="md-checkbox cursor-pointer align-middle"
      value={value}
      checked={checked}
      data-tlabel={`checkbox ${lable}`}
      {...otherprops}
    />
    <label
      htmlFor={`checkbox_${lable}`}
      className={`d-inline-block ms-2 align-middle mb-0 ${labelClass || ""}`}
    >
      {lable}
    </label>
  </div>
);
export default CustomeCheckbox;
