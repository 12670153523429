import React, { useState, useRef } from "react";
import { instanceWithRetry as instance } from "actions/axiosInstance";
import AsyncSelect from "react-select/async";
import debounce from "lodash/debounce";
import { handleAPIError } from "handlers/setters";
import { trimText } from "utils/strings";
import { getLocaleString } from "utils/localization/locale";

export default function AssociateInvoiceList(props) {
  const { selectedVendor, setInvoiceList, invoiceList } = props;
  const [searchInvoiceList, setSearchInvoiceList] = useState([]);
  const [associatedInvoices, setAssociatedInvoices] = useState(invoiceList);

  const searchInvoices = useRef(
    debounce(
      (text, callback, vendor, setSearchInvoiceList) =>
        searchInvoicesForVendor(text, callback, vendor, setSearchInvoiceList),
      500
    )
  ).current;
  const searchInvoicesForVendor = (text, callback, vendor) => {
    const url = `apinvoice/search?per_page=100&invoice=${trimText(
      text
    )}&attach_invoices=1&vendor_id=${vendor.id}`;

    if (text) {
      instance
        .get(url)
        .then((res) => {
          const list = res.data.result.map((d) => ({
            value: d.id,
            label: d.invoice_number,
          }));
          callback(list);
          setSearchInvoiceList(list);
        })
        .catch((e) => {
          callback([]);
          handleAPIError(e);
        });
    } else {
      callback([]);
    }
  };

  return (
    <div>
      <AsyncSelect
        isMulti
        isClearable
        isDisabled={!selectedVendor}
        cacheOptions
        value={associatedInvoices}
        defaultOptions={searchInvoiceList}
        placeholder={getLocaleString(
          "common.search_invoices_to_associate",
          "Search Invoice to Associate"
        )}
        loadOptions={(text, callback) =>
          searchInvoices(text, callback, selectedVendor, setSearchInvoiceList)
        }
        onMenuClose={() => setInvoiceList(associatedInvoices)}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 100 }) }}
        menuPlacement={"auto"}
        onChange={(value) => {
          if (!value) {
            setAssociatedInvoices([]);
            setInvoiceList([]);
          } else {
            setAssociatedInvoices(value);
            setInvoiceList(value);
          }
        }}
      />
    </div>
  );
}
