import React from "react";
const sizes = {
  lg: "btn-lg",
  sm: "btn-sm",
};
const variantClassNames = {
  primary: "btn-primary",
  secondary: "btn-secondary",
  success: "btn-success",
  danger: "btn-danger",
  warning: "btn-warn",
  info: "btn-info",
  light: "btn-light",
  dark: "btn-dark",
  link: "btn-link",
  icon: "bg-transparent border-0",
  "primary-gradient": "bg-primary-gradient",
  "secondary-gradient": "bg-secondary-gradient",
  "outline-primary": "btn-outline-primary",
  "outline-secondary": "btn-outline-secondary",
  "outline-success": "btn-outline-success",
  "outline-danger": "btn-outline-danger",
  "outline-warning": "btn-outline-warning",
  "outline-info": "btn-outline-info",
  "outline-dark": "btn-outline-dark",
  "outline-light": "btn-outline-light",
};

export default function Button({
  as = "button",
  variant = "primary",
  size = "",
  className = "",
  startIcon,
  endIcon,
  isActive = false,
  children,
  ...rest
}) {
  const mergedClassname = `btn ${variantClassNames[variant]} ${
    sizes[size] || ""
  } ${isActive ? "active" : ""} ${className}`;
  const trackingValue = Array.isArray(children)
    ? children.join("").trim()
    : typeof children === "string"
    ? children
    : "";
  return (
    <>
      {React.createElement(
        as,
        {
          className: mergedClassname,
          "data-tlabel": trackingValue,
          ...rest,
        },
        [
          startIcon && <span className="me-1">{startIcon}</span>,
          children,
          endIcon && <span className="ms-1">{endIcon}</span>,
        ]
      )}
    </>
  );
}

/* <Button as="button" endIcon={<i className="fa fa-spinner fa-spin" />}>
    Click
</Button> */
