import { ReducersTypes } from "constants/ReducersTypes";

export const initialData = {
  invoiceFields: [],
  isFetching: false,
  translatedLabels: {},
  getFieldsVisiblity: {},
};

const payablesInvoicingFields = (state = initialData, { type, payload }) => {
  switch (type) {
    case ReducersTypes.SET_INVOICING_FIELDS:
      return {
        ...state,
        isFetching: payload.isFetching,
        invoiceFields: payload.invoiceFields,
        translatedLabels: payload.translatedLabels,
        getFieldsVisiblity: payload.getFieldsVisiblity,
      };
    default:
      return state;
  }
};

export default payablesInvoicingFields;
