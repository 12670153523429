import React from "react";
import { Link } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import EmailTemplate from "./EmailTemplateNew";
import Workflow from "./Workflow";
import {
  fetchWorkflowSteps,
  fetchEmailTemplateList,
  fetchWorkflowList,
  fetchScheduleList,
} from "./actions";
import "./arworkflow.scss";
import "../receivables.css";
import { getLocaleString } from "utils/localization/locale";

class ARworkflow extends React.Component {
  state = {
    activeTab: localStorage.getItem("arworkflow_tab") || 1,
  };

  componentDidMount() {
    this.props.fetchWorkflowSteps();
    this.props.fetchEmailTemplateList();
  }

  render() {
    const { activeTab } = this.state;
    const {
      match: {
        params: { id },
      },
    } = this.props;
    return (
      <div className="row arworkflow-main" id="dashboardBody">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="float-start p-2 t-18">
                    <Link
                      to={
                        id ? "/settings/receivables/ar-workflow" : "/settings"
                      }
                      title={getLocaleString("common.back", "Back")}
                    >
                      <i
                        data-tlabel="AR workflow settings back button"
                        className="fa fa-arrow-left t-black me-2"
                        aria-hidden="true"
                      />
                    </Link>
                  </div>

                  <Tabs
                    id="insightgraphs"
                    activeKey={activeTab}
                    onSelect={(activeTab) => {
                      localStorage.setItem("arworkflow_tab", activeTab);
                      this.setState({ activeTab });
                    }}
                  >
                    <Tab
                      eventKey="1"
                      title="Workflows"
                      className="arworkflow-tab-4"
                    >
                      <Workflow {...this.props} />
                    </Tab>
                    <Tab
                      eventKey="4"
                      title="Email Templates"
                      className="arworkflow-tab-2"
                    >
                      <EmailTemplate {...this.props} />
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  fetchWorkflowSteps,
  fetchEmailTemplateList,
  fetchWorkflowList,
  fetchScheduleList,
};

const mapStateToProps = (state) => {
  const {
    workflow: { workflowList, emailTemplate, workflowSteps, workflowSchedule },
    clientDetails: { clientData },
  } = state;
  return {
    workflowList,
    emailTemplate,
    workflowSteps,
    workflowSchedule,
    clientData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ARworkflow);
