import { ReducersTypes } from "constants/ReducersTypes";
import { instanceWithRetry } from "actions/axiosInstance";
import { store } from "store";
import { handleAPIError } from "handlers/setters";
import get from "lodash/get";
import Toaster from "components/Toaster";
import { getLocaleString } from "utils/localization/locale";

const dispatch = store.dispatch;
const setData = (type, data) => ({
  type,
  payload: {
    ...data,
  },
});

export const getInvoicingFields = (isMutate = false) => {
  dispatch(setData(ReducersTypes.SET_INVOICING_FIELDS, { isFetching: true }));
  instanceWithRetry
    .get(isMutate ? "/invoice/template?mutate=1" : "/invoice/template")
    .then((response) => {
      const getFieldsVisiblity = get(response, "data.result", []).reduce(
        (acc, cur) => {
          acc[cur.name] = cur.value;
          return acc;
        },
        {}
      );
      const translatedLabels = get(response, "data.result", []).reduce(
        (acc, cur) => {
          acc[cur.name] = cur.current_name;
          return acc;
        },
        {}
      );
      dispatch(
        setData(ReducersTypes.SET_INVOICING_FIELDS, {
          isFetching: false,
          invoiceFields: get(response, "data.result", []),
          translatedLabels,
          getFieldsVisiblity,
        })
      );
    })
    .catch((e) => {
      dispatch(
        setData(ReducersTypes.SET_INVOICING_FIELDS, {
          isFetching: false,
          invoiceFields: [],
        })
      );
      handleAPIError(e);
    });
};

export const updateInvoicingFields = (reqObj) => {
  instanceWithRetry
    .put("/invoice/template?mutate=1", reqObj)
    .then(() => {
      Toaster(
        getLocaleString(
          "settings_payables.invoice_fields_updated",
          "Invoice fields updated"
        ),
        "success"
      );
      getInvoicingFields(true);
    })
    .catch((e) => handleAPIError(e));
};

export const deleteInvocingFields = (reqObj) => {
  instanceWithRetry
    .delete("/invoice/template?mutate=1", { data: reqObj })
    .then(() => {
      getInvoicingFields(true);
    })
    .catch((e) => handleAPIError(e));
};
