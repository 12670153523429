/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { convertAmountWithDecimal } from "utils";
import get from "lodash/get";
import chunk from "lodash/chunk";
import Button from "components/Button";
import { getFormattedDate } from "utils/dates";
import ToggleSwitch from "components/Button/ToggleButton";
import "./template.css";
import { getLocaleString } from "utils/localization/locale";

const defaultLabelMapping = {
  activityDate: "Activity Date",
  deliveryDate: "Delivery Date",
  "Invoice Number": "Invoice Number",
  "Container Number": "Container Number",
  "Order Number": "Order Number",
  Service: "Service",
  Details: "Details",
  Total: "Total",
  From: "From",
  To: "To",
  billTo: "Bill To",
  shipTo: "Ship To",
  jobSequence: "Job Sequence",
  jobCategory: "Job Category",
  dispatchType: "Dispatch Type",
};

const sampleData = {
  client_id: 104,
  consignment_lines: [
    {
      approve_date: null,
      client_supplier_rel_id: 45174,
      consignment_header_id: 1,
      delivery_date: "2022-12-29T00:00:00",
      delivery_location: "TRAPAC, PORT-OAK",
      despatch_type: "FCL",
      driver_name: "Balbir Singh",
      id: 1,
      line_internal_id: "6261880",
      line_price: 65.6,
      line_remarks: null,
      line_status: "open",
      notify_date: null,
      pickup_date: "2022-12-29T00:00:00",
      pickup_location: "UP, PORT-OAK",
      reject_date: null,
      service_code: "AP-DRY",
      service_description: "DRV DRY RUN",
    },
  ],
  consignment_number: "LB1954938/001",
  container_number: "FSCU 717953-5",
  created_date: "2023-01-04T05:42:31",
  id: 1,
  site_code: "HAY",
};
export const RenderTemplate3 = (props) => {
  const {
    invoiceDetails = props.isSample ? sampleData : {},
    totalAmount = props.isSample ? 65.6 : 0,
    invoiceNumber = props.isSample
      ? getLocaleString("template_3.pf_12345_01", "PF_12345_01")
      : "",
    clientName = props.isSample ? getLocaleString("template_3.gsc", "GSC") : "",
    shipTo = props.isSample
      ? "Corporate Office 530 Water Street, 5th Floor Oakland, CA 94607"
      : "",
    billTo = props.isSample
      ? "Corporate Office 530 Water Street, 5th Floor Oakland, CA 94607"
      : "",
    supplierName = props.isSample
      ? getLocaleString("template_3.dire_trucking", "Dire Trucking")
      : "",
    perPick = props.isSample ? 10 : 0,
    returnFees = props.isSample ? 15 : 0,
    packing = props.isSample ? 2.5 : 0,
    recivingFees = props.isSample ? 3 : 0,
  } = props || {};

  const [invoiceFields, setFieldUpdates] = useState(
    get(props, "payablesInvoicingFields.invoiceFields", [])
  );
  const [labels, setLabels] = useState(defaultLabelMapping);
  const [getFieldsVisiblity, setFieldVisiblity] = useState({});
  const [showPreview, setShowPreview] = useState(true);
  const visibleColumnSpan =
    +getFieldsVisiblity["Order Number"] +
    +getFieldsVisiblity["Details"] +
    +getFieldsVisiblity["Pickup Date"] +
    +getFieldsVisiblity["Delivery Date"] +
    +getFieldsVisiblity["Trip Net"];

  useEffect(() => {
    setFieldUpdates(get(props, "payablesInvoicingFields.invoiceFields", []));
    setFieldVisiblity(
      get(props, "payablesInvoicingFields.invoiceFields", []).reduce(
        (acc, cur) => {
          acc[cur.name] = cur.value;
          return acc;
        },
        {}
      )
    );
    setLabels(
      get(props, "payablesInvoicingFields.invoiceFields", []).reduce(
        (acc, cur) => {
          acc[cur.name] = cur.current_name;
          return acc;
        },
        {}
      )
    );
  }, [get(props, "payablesInvoicingFields.invoiceFields", [])]);

  useEffect(() => {
    if (props.isSample) {
      props.getInvoicingFields();
    }
  }, []);

  const postUpdatedInvoiceFields = () => {
    const updatedFields = invoiceFields
      .filter((d) => d.isUpdated)
      .map((d) => ({
        id: d.id,
        current_name: d.current_name,
        value: (+d.value).toString(),
      }));
    if (updatedFields.length) {
      props.updateInvoicingFields(updatedFields);
    }
    setShowPreview(true);
  };

  const updateInvoiceField = (updatedData) => {
    setFieldUpdates(
      invoiceFields.map((d) => {
        if (d.id === updatedData.id) {
          return {
            ...d,
            ...updatedData,
            isUpdated: true,
          };
        }
        return d;
      })
    );
  };

  const translatedLabels = labels;
  const lineChunks = [
    get(invoiceDetails, "consignment_lines", []).slice(0, 3),
    ...chunk(
      get(invoiceDetails, "consignment_lines", []).slice(3, Infinity),
      5
    ),
  ].filter((e) => e && e.length);
  const totalPages = lineChunks.length;

  const renderLines = (data) => (
    <tr key={get(data, "line_internal_id", "-")}>
      {getFieldsVisiblity["Order Number"] ? (
        <td className="text-center">{get(data, "line_internal_id", "-")}</td>
      ) : null}
      {getFieldsVisiblity["Details"] ? (
        <td>
          {getFieldsVisiblity["Service Code"] ? (
            <p className="mb-0">
              {translatedLabels["Service Code"]}:{" "}
              {get(data, "service_code", "-")}
            </p>
          ) : null}
          {getFieldsVisiblity["Service Description"] ? (
            <p className="mb-0">
              {translatedLabels["Service Description"]}:{" "}
              {get(data, "service_description", "-")}
            </p>
          ) : null}
          {getFieldsVisiblity["Pickup Location"] ? (
            <p className="mb-0">
              {translatedLabels["Pickup Location"]}:{" "}
              {get(data, "pickup_location", "-")}
            </p>
          ) : null}
          {getFieldsVisiblity["Delivery Location"] ? (
            <p className="mb-0">
              {translatedLabels["Delivery Location"]}:{" "}
              {get(data, "delivery_location", "-")}
            </p>
          ) : null}
          {getFieldsVisiblity["Despatch Type"] ? (
            <p className="mb-0">
              {translatedLabels["Despatch Type"]}:{" "}
              {get(data, "despatch_type", "-")}
            </p>
          ) : null}
          {getFieldsVisiblity["Activity Number"] ? (
            <p className="mb-0">
              {translatedLabels["Activity Number"]}:{" "}
              {get(invoiceDetails, "consignment_number", "-")}
            </p>
          ) : null}
          {getFieldsVisiblity["Container Number"] ? (
            <p className="mb-0">
              {translatedLabels["Container Number"]}:{" "}
              {get(invoiceDetails, "container_number", "-")}
            </p>
          ) : null}
        </td>
      ) : null}
      {getFieldsVisiblity["Pickup Date"] ? (
        <td className="text-center">
          {get(data, "pickup_date", "")
            ? getFormattedDate(get(data, "pickup_date", ""))
            : "-"}
        </td>
      ) : null}
      {getFieldsVisiblity["Delivery Date"] ? (
        <td className="text-center">
          {get(data, "delivery_date", "")
            ? getFormattedDate(get(data, "delivery_date", ""))
            : "-"}
        </td>
      ) : null}
      {getFieldsVisiblity["Trip Net"] ? (
        <td className="text-end">
          {convertAmountWithDecimal(get(data, "line_price", ""))}
        </td>
      ) : null}
    </tr>
  );

  const renderHeader = () => (
    <tr className="bg-gray-header">
      {getFieldsVisiblity["Order Number"] ? (
        <th className="text-center">{translatedLabels["Order Number"]}</th>
      ) : null}
      {getFieldsVisiblity["Details"] ? (
        <th className="text-center">{translatedLabels["Details"]}</th>
      ) : null}
      {getFieldsVisiblity["Pickup Date"] ? (
        <th className="text-center">{translatedLabels["Pickup Date"]}</th>
      ) : null}
      {getFieldsVisiblity["Delivery Date"] ? (
        <th className="text-center">{translatedLabels["Delivery Date"]}</th>
      ) : null}
      {getFieldsVisiblity["Trip Net"] ? (
        <th className="text-center">{translatedLabels["Trip Net"]}</th>
      ) : null}
    </tr>
  );

  const renderNextPages = (pageData = [], isLast, visibleColumnSpan) => (
    <table className="invoiceTemplate">
      <tr>
        <td colSpan="2" className="thick-border"></td>
      </tr>
      <tr>
        <td colSpan="2">
          <table
            border="1"
            style={{ borderColor: "#ccc" }}
            className="mt-4 mb-3"
          >
            {renderHeader()}
            {pageData.map((d) => renderLines(d))}
            {isLast && getFieldsVisiblity["Invoice Amount"] ? (
              <>
                <tr>
                  <td colSpan={visibleColumnSpan} />
                </tr>
                <tr>
                  <td colSpan={visibleColumnSpan - 1} className="text-end">
                    {translatedLabels["Invoice Amount"]}
                  </td>
                  <td className="text-end">
                    {convertAmountWithDecimal(totalAmount)}
                  </td>
                </tr>
              </>
            ) : null}
          </table>
        </td>
      </tr>
    </table>
  );

  const renderFields = (invoiceFields = []) => {
    return (
      <div className="col-md-8">
        <table className="paynewdetsectran mb-3 mt-2">
          <tr>
            <th style={{ width: 30 }}>#</th>
            <th style={{ width: 300 }}>
              {getLocaleString(
                "settings.settings_company.current_labels",
                "Current Label"
              )}
            </th>
            <th style={{ width: 300 }}>
              {getLocaleString("template_3.rename_label", "Rename Label")}
            </th>
            <th style={{ width: 50 }}>
              {getLocaleString("template_3.show_hide", "Show/Hide")}
            </th>
          </tr>
          {invoiceFields.map((d, i) => (
            <tr>
              <td>{i + 1}</td>
              <td>{d.name}</td>
              <td>
                <input
                  onChange={(e) =>
                    updateInvoiceField({ ...d, current_name: e.target.value })
                  }
                  className="form-control t-14"
                  type="text"
                  maxLength={50}
                  defaultValue={d.current_name}
                />
              </td>
              <td>
                <ToggleSwitch
                  id={`field_label-${d.id}`}
                  data-tlabel={`${d.value ? "Disable" : "Enable"} field ${
                    d.name
                  }`}
                  checked={d.value}
                  onChange={(e) =>
                    updateInvoiceField({ ...d, value: +e.target.checked })
                  }
                />
              </td>
            </tr>
          ))}
        </table>
        <Button onClick={postUpdatedInvoiceFields} className="mt-3 t-14">
          {getLocaleString("common.save", "Save")}
        </Button>
        <Button
          variant="secondary"
          className="t-14 mt-3 ms-2"
          onClick={() => setShowPreview(true)}
        >
          {getLocaleString("common.cancel", "Cancel")}
        </Button>
      </div>
    );
  };

  return (
    <>
      {!showPreview ? renderFields(invoiceFields) : null}
      {showPreview ? (
        <div className="template-1">
          <table className="invoiceTemplate">
            <tr>
              <td colSpan="2" className="thick-border"></td>
            </tr>
            <tr>
              <td>
                <table>
                  <tr>
                    <td className="pb-3"></td>
                    <td className="text-end t-20 pt-2 pb-2">
                      <strong>{supplierName}</strong>
                    </td>
                  </tr>
                  <tr>
                    {billTo && getFieldsVisiblity["Bill To"] ? (
                      <td className="align-top">
                        <strong>{translatedLabels["Bill To"]}:</strong>
                        <p className="w-50">
                          {clientName} <br />
                          {billTo}
                        </p>
                      </td>
                    ) : null}
                    {shipTo && getFieldsVisiblity["Ship To"] ? (
                      <td className="align-top">
                        <strong>{translatedLabels["Ship To"]}:</strong>
                        <p className="w-50">{shipTo}</p>
                      </td>
                    ) : null}
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table border="1" style={{ borderColor: "#ccc" }}>
                  <tr className="bg-gray-header">
                    {getFieldsVisiblity["Invoice Number"] ? (
                      <th>{translatedLabels["Invoice Number"]}</th>
                    ) : null}
                    {getFieldsVisiblity["Activity Date"] ? (
                      <th>{translatedLabels["Activity Date"]}</th>
                    ) : null}
                    {getFieldsVisiblity["Invoice Amount"] ? (
                      <th>{translatedLabels["Invoice Amount"]}</th>
                    ) : null}
                  </tr>
                  <tr>
                    {getFieldsVisiblity["Invoice Number"] ? (
                      <td>{invoiceNumber}</td>
                    ) : null}
                    {getFieldsVisiblity["Activity Date"] ? (
                      <td>{getFormattedDate(new Date())}</td>
                    ) : null}
                    {getFieldsVisiblity["Invoice Amount"] ? (
                      <td>{convertAmountWithDecimal(totalAmount)}</td>
                    ) : null}
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <hr />
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <table
                  border="1"
                  style={{ borderColor: "#ccc" }}
                  className="mt-4 mb-3"
                >
                  {renderHeader()}
                  {get(lineChunks, "[0]", []).map((d) => renderLines(d))}
                  {lineChunks.length === 1 ? (
                    <>
                      {getFieldsVisiblity["Return Fee"] && (
                        <tr>
                          <td
                            colSpan={visibleColumnSpan - 1}
                            className="text-end"
                          >
                            {translatedLabels["Return Fee"]}
                          </td>
                          <td className="text-end">
                            {convertAmountWithDecimal(returnFees)}
                          </td>
                        </tr>
                      )}
                      {getFieldsVisiblity["Packing"] && (
                        <tr>
                          <td
                            colSpan={visibleColumnSpan - 1}
                            className="text-end"
                          >
                            {translatedLabels["Packing"]}
                          </td>
                          <td className="text-end">
                            {convertAmountWithDecimal(packing)}
                          </td>
                        </tr>
                      )}
                      {getFieldsVisiblity["Receiving Fee"] && (
                        <tr>
                          <td
                            colSpan={visibleColumnSpan - 1}
                            className="text-end"
                          >
                            {translatedLabels["Receiving Fee"]}
                          </td>
                          <td className="text-end">
                            {convertAmountWithDecimal(recivingFees)}
                          </td>
                        </tr>
                      )}
                      {getFieldsVisiblity["Per Pick"] && (
                        <tr>
                          <td
                            colSpan={visibleColumnSpan - 1}
                            className="text-end"
                          >
                            {translatedLabels["Per Pick"]}
                          </td>
                          <td className="text-end">
                            {convertAmountWithDecimal(perPick)}
                          </td>
                        </tr>
                      )}
                      {getFieldsVisiblity["Invoice Amount"] && (
                        <tr>
                          <td
                            colSpan={visibleColumnSpan - 1}
                            className="text-end"
                          >
                            {translatedLabels["Invoice Amount"]}
                          </td>
                          <td className="text-end">
                            {convertAmountWithDecimal(totalAmount)}
                          </td>
                        </tr>
                      )}
                    </>
                  ) : null}
                </table>
              </td>
            </tr>
          </table>
          {totalPages > 1
            ? lineChunks.map((d, i) =>
                i === 0
                  ? null
                  : renderNextPages(d, i === totalPages - 1, visibleColumnSpan)
              )
            : null}

          {props.isSample ? (
            <>
              <Button
                className="mt-2 t-14"
                onClick={() => setShowPreview(false)}
              >
                {getLocaleString("template_3.edit_fields", "Edit Fields")}
              </Button>
            </>
          ) : null}
        </div>
      ) : null}
    </>
  );
};
