/* eslint-disable react-hooks/exhaustive-deps */
import DateRangePicker from "components/DateRangePicker";
import Autocomplete from "components/Autocomplete";
import Button from "components/Button";
import get from "lodash/get";
import ActivityList from "./activityList";
import { getActivityList } from "./actions";
import { setReceivablesFilters } from "containers/receivables/list/actions";
import { searchCustomer } from "containers/dashboard/TaskManagement/actions";
import moment from "moment";
import debounce from "lodash/debounce";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocaleString } from "utils/localization/locale";
import CustomSpinner from "components/Spinner";
import TokenManager from "utils/TokenManager";
import { getuxvalue, setuxvalue } from "handlers/ux";
import { getDateByRangeInterval } from "utils/dates";
import DropdownButton from "components/Button/Dropdown";
import MultiSelect from "components/MultiSelectFilter";

const isArrep = TokenManager.getIsAR_REP();
const role = TokenManager.getRoleNew();
const userDetails = {
  user_id: TokenManager.getUserId(),
  user_role_id: get(TokenManager.getUserData(), "user_role_id", ""),
};

export const initialActivityFilters = {
  role,
  label: "Past 7 Days",
  to_date: moment().format("MM/DD/YYYY"),
  from_date: moment().subtract(7, "days").format("MM/DD/YYYY"),
  assigned_to: isArrep ? userDetails : "",
};

function Activity(props) {
  const dispatch = useDispatch();
  const { activity, userList } = useSelector((state) => ({
    activity: state.activity,
    userList: state.dashboard.userList,
  }));

  const dispatchFunctions = {
    getActivityList: (data) => dispatch(getActivityList(data)),
    setReceivablesFilters: (data) => dispatch(setReceivablesFilters(data)),
  };

  const path = activity;
  let filters = {
    ...path.filters,
    ...getuxvalue("ANALYTICS_ACTIVITY_FILTERS"),
  };

  if (filters && filters.label) {
    const [sd, ed] = getDateByRangeInterval(
      filters.label,
      filters.from_date,
      filters.to_date
    );
    filters = {
      ...filters,
      from_date: sd,
      to_date: ed,
    };
  }

  const [showFilter, setShowFilter] = useState(false);
  const [activityList, setActivityList] = useState(path.activityList);
  const [assigned_to, setAssignedTo] = useState(filters.assigned_to);
  const [label, setLabel] = useState(filters.label || "Past 7 Days");
  const [from_date, setFromDate] = useState(filters.from_date);
  const [to_date, setToDate] = useState(filters.to_date);
  const [customer, setCustomer] = useState(filters.customer || null);
  const [customerSearchList, setCustomerSearchList] = useState([]);
  const [loadingCustomerList, setLoadingCustomerList] = useState(false);
  const [flag, setFlag] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState(
    get(filters, "selectedUsers", [])
  );

  useEffect(() => {
    dispatchFunctions.getActivityList({
      role,
      to_date,
      from_date,
      assigned_to: isArrep ? userDetails : assigned_to,
      selectedUsers,
      client_customer_rel_id: get(customer, "id", ""),
    });
  }, []);

  useEffect(() => {
    setActivityList(path.activityList);
  }, [path]);

  const setDates = (startDate, endDate, label) => {
    setFromDate(startDate ? moment(startDate).format("MM/DD/YYYY") : "");
    setToDate(endDate ? moment(endDate).format("MM/DD/YYYY") : "");
    setLabel(label);
    setShowFilter(true);
  };

  const onReset = () => {
    dispatchFunctions.getActivityList(initialActivityFilters);
    setuxvalue("ANALYTICS_ACTIVITY_FILTERS", {
      ...initialActivityFilters,
      label: "Past 7 Days",
    });
    setLabel(initialActivityFilters.label);
    setAssignedTo(initialActivityFilters.assigned_to);
    setFromDate(initialActivityFilters.from_date);
    setToDate(initialActivityFilters.to_date);
    setCustomer(null);
    setShowFilter(false);
    setSelectedUsers([]);
    setFlag(true);
  };

  const applyFilter = () => {
    const filters = {
      role,
      label,
      to_date,
      from_date,
      selectedUsers,
      assigned_to:
        assigned_to !== ""
          ? typeof assigned_to === "string"
            ? JSON.parse(assigned_to)
            : assigned_to
          : isArrep
          ? userDetails
          : "",
      client_customer_rel_id: get(customer, "id", ""),
    };

    dispatchFunctions.getActivityList(filters);
    setuxvalue("ANALYTICS_ACTIVITY_FILTERS", { ...filters, customer });
    setShowFilter(false);
  };

  const setCustomerList = (list) => {
    setCustomerSearchList(
      list.map((s) => ({
        id: s.value,
        name: s.label,
        customer_id: s.customer_id,
      }))
    );
    setLoadingCustomerList(false);
  };

  const debounceMethod = useRef(
    debounce(
      (text) => searchCustomer(text, setCustomerList, assigned_to || []),
      300
    )
  ).current;

  const getUserData = (user_id) => {
    const data = get(userList, "list", []).find(
      (user) => Number(user.user_id) === Number(user_id)
    );
    return data?.email;
  };

  return (
    <>
      <div className="card-body px-2">
        <div className="row mb-3">
          <div className="col-md-6">
            <DropdownButton
              showDropdown={showFilter}
              buttonProps={{
                as: "span",
                role: "button",
                "data-toggle": "dropdown",
                "aria-haspopup": "true",
                "aria-expanded": "false",
              }}
              btnClassName="dropdown-toggle cursor-pointer"
              setShowDropdown={setShowFilter}
              buttonText={getLocaleString("common.filter", "Filter")}
              dropdownWrapperClassName="filterdroplist p-2 width-650px"
              dropdownWrapperProps={{
                id: "outerside",
              }}
              filterButton
            >
              <div className="col">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>{getLocaleString("common.date", "Date")}</label>
                      <div className="single-date-picker t-12 overflow-hidden">
                        <DateRangePicker
                          label={label}
                          startDate={from_date ? moment(from_date) : ""}
                          endDate={to_date ? moment(to_date) : ""}
                          isClearable
                          getSelectedDates={setDates}
                          parentEl="#outerside"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="mb-2">
                      {getLocaleString(
                        "order_backlog.customer_name",
                        "Customer name"
                      )}
                    </label>
                    <Autocomplete
                      suggestions={customerSearchList}
                      getSelected={setCustomer}
                      search={(text) => {
                        setLoadingCustomerList(true);
                        debounceMethod(text);
                      }}
                      isLoading={loadingCustomerList}
                      defaultValue={customer && customer.name}
                    />
                  </div>
                  {!isArrep ? (
                    <>
                      {true ? (
                        <div className="col-lg-6 mt-2">
                          <div className="form-group" data-testid="assigned_to">
                            <label>
                              {getLocaleString(
                                "common.assigned_to",
                                "Assigned to"
                              )}
                            </label>
                            <MultiSelect
                              type="payables-list"
                              placeholder="Assigned to"
                              onChange={(e) => setSelectedUsers(e)}
                              options={get(userList, "list", []).map((d) => ({
                                name: d.username,
                                id: d.user_id,
                                data: d,
                              }))}
                              sendValue={false}
                              value={selectedUsers}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="col-lg-6">
                          <div className="form-group">
                            {getLocaleString(
                              "common.assigned_to",
                              "Assigned to"
                            )}{" "}
                            <select
                              className="form-control t-14 w-100 d-inline-block h-50"
                              data-cy="Selectworkflow"
                              value={
                                assigned_to !== ""
                                  ? typeof assigned_to === "string"
                                    ? assigned_to
                                    : JSON.stringify(assigned_to)
                                  : 0
                              }
                              title={
                                assigned_to !== ""
                                  ? typeof assigned_to === "string"
                                    ? JSON.parse(assigned_to).email
                                    : assigned_to.email
                                  : ""
                              }
                              onChange={(e) => {
                                if (e.target.value === "0") {
                                  setAssignedTo("");
                                  setFlag(true);
                                } else {
                                  setAssignedTo(e.target.value);
                                  setFlag(false);
                                }
                              }}
                            >
                              <option
                                default
                                disabled={flag}
                                selected
                                value="0"
                              >
                                {flag ? "Select User" : "Unselect User"}
                              </option>
                              {get(userList, "list", []).map((user) => (
                                <option
                                  key={user.user_id}
                                  value={JSON.stringify(user)}
                                >
                                  {user.email}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  <div
                    className="col-lg-6 ms-auto"
                    style={
                      isArrep
                        ? {
                            position: "absolute",
                            right: 0,
                            bottom: 18,
                          }
                        : {}
                    }
                  >
                    <div className="setbtn box-space">
                      <Button
                        type="button"
                        variant="light"
                        className="resetbtn"
                        data-cy="resetbtn"
                        onClick={onReset}
                      >
                        {getLocaleString("common.reset", "Reset")}
                      </Button>

                      <Button
                        type="button"
                        className="applybtn"
                        data-cy="applybtn"
                        onClick={() => applyFilter()}
                      >
                        {getLocaleString("common.submit", "Submit")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </DropdownButton>
          </div>
        </div>
        <div>
          {path.isFetching ? (
            <CustomSpinner />
          ) : activityList ? (
            activityList.map((activity, idx) => (
              <ActivityList
                key={idx}
                assigned_to={path.filters.assigned_to}
                user_email={getUserData(path.filters.assigned_to.user_id)}
                date_label={label}
                from_date={from_date}
                customer={customer}
                to_date={to_date}
                {...activity}
                {...dispatchFunctions}
                {...props}
              />
            ))
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

export default Activity;
