import React, { useEffect, useState } from "react";
import TokenManager from "utils/TokenManager";
import Modal from "../CustomModal";
import Button from "components/Button";
import { getLocaleString } from "utils/localization/locale";

export default function CustomerSupportForm() {
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (window.hbspt) {
      window.hbspt.forms.create({
        region: "na1",
        portalId: "6952923",
        formId: "9304dd79-9158-4e1f-82a9-22e70b32a0fc",
        target: "#support_form",
        onFormReady: (form) => {
          const emailField = form.querySelector("input[name=email]");
          if (emailField) {
            emailField.focus();
            emailField.value = TokenManager.getUserEmail();
            emailField.blur();
          }
          const companyField = form.querySelector(
            "input[name='TICKET.account_name']"
          );
          if (companyField) {
            companyField.focus();
            companyField.value = TokenManager.getClientName();
            companyField.blur();
          }
        },
      });
    }
  }, [showForm]);

  return (
    <div>
      <Button
        onClick={() => setShowForm(true)}
        as="div"
        variant="icon"
        title={getLocaleString("common.get_support", "Get Support")}
        startIcon={<i className="fas fa-question-circle" />}
        className="cursor-pointer support-icon"
      />
      <Modal
        show={showForm}
        modalBodyStyle={{ maxHeight: "calc(100vh - 300px)", overflowY: "auto" }}
        size="lg"
        onHide={() => setShowForm(false)}
        title={getLocaleString(
          "common.raise_customer_support_ticket",
          "Raise customer support ticket"
        )}
      >
        <div id="support_form" />
      </Modal>
    </div>
  );
}
