import { ReducersTypes } from "constants/ReducersTypes";
import moment from "moment";
import TokenManager from "utils/TokenManager";
import { getDateByRangeInterval } from "utils/dates";
import { getUXSettingsFromStorage } from "utils";
import { enableDefaultAllDateFilter } from "handlers/features";
import { getLocaleString } from "utils/localization/locale";

export const dateRange = {
  from_date: enableDefaultAllDateFilter()
    ? moment(0).format("MM/DD/YYYY")
    : moment().subtract(2, "year").format("MM/DD/YYYY"),
  to_date: enableDefaultAllDateFilter()
    ? moment().add(1, "year").format("MM/DD/YYYY")
    : moment().add(1, "year").format("MM/DD/YYYY"),
};

let savedFilters = getUXSettingsFromStorage("CUSTOMER_DETAILS_FILTERS");
if (savedFilters && savedFilters.interval) {
  const { label, from_date, to_date } = savedFilters;
  const [sd, ed] = getDateByRangeInterval(
    label === "All" ? "AllToDate" : label,
    from_date,
    to_date
  );
  savedFilters = {
    ...savedFilters,
    from_date: sd,
    to_date: ed,
  };
}

export const CUSTOMER_INVOICE_LIST_COLUMNS_ORDER = {
  [getLocaleString("common.invoice", "Invoice")]: 1,
  // "CI Number" [getLocaleString("common.invoice", "Invoice")]: 3,
  [getLocaleString("common.payIQDate", "PayIQ Date")]: 4,
  [getLocaleString("common.invoice_date", "Invoice Date")]: 5,
  [getLocaleString("receivables.due_date", "Due Date")]: 6,
  [getLocaleString("common.daysPastDue", "Days Past Due")]: 7,
  [getLocaleString("order_backlog.po_number", "PO Number")]: 8,
  [getLocaleString("common.memo", "Memo")]: 9,
  [getLocaleString("common.projectID", "Project ID")]: 10,
  [getLocaleString("common.closedDate", "Closed Date")]: 11,
  [getLocaleString("common.InvoiceAmount", "Invoice Amount")]: 12,
  [getLocaleString("common.amountDue", "Amount Due")]: 13,
  [getLocaleString("common.amountPaid", "Amount Paid")]: 14,
  [getLocaleString("common.paymentMethod", "Payment Method")]: 15,
  [getLocaleString("common.entity", "Entity")]: 16,
  [getLocaleString("common.status", "Status")]: 17,
};

export const CUSTOMER_DETAILS_CREDIT_MEMO_COLUMNS_ORDER = {
  "Credit Number": 1,
  "PayIQ Date": 2,
  Date: 3,
  "Closed Date": 4,
  "Amount Due": 5,
  "Amount Paid": 6,
  Status: 7,
};

export const CUSTOMER_AR_PAYMENT_COLUMNS = {
  [getLocaleString("common.invoiceNumber", "Invoice Number")]: true,
  [getLocaleString("order_backlog.po_number", "PO Number")]: true,
  [getLocaleString("journal.document_number", "Document Number")]: true,
  [getLocaleString("common.payment_date", "Payment Date")]: true,
  [getLocaleString("common.invoice_due_date", "Invoice Due Date")]: true,
  [getLocaleString("customer_details.applied_amount", "Applied Amount")]: true,
  [getLocaleString(
    "customer_details.unapplied_amount",
    "Unapplied Amount"
  )]: true,
  [getLocaleString("common.status", "Status")]: true,
};

export const CUSTOMER_AR_PAYMENT_COLUMNS_ORDER = {
  "Invoice Number": 1,
  "PO Number": 2,
  "Document Number": 3,
  "Payment Date": 4,
  "Invoice Due Date": 5,
  "Applied Amount": 6,
  "Unapplied Amount": 7,
  Status: 8,
};

export const CUSTOMER_DEPOSIT_COLUMNS_ORDER = {
  "Deposit record number": 1,
  "Order backlog": 2,
  Invoice: 3,
  Amount: 4,
  Date: 5,
  Status: 6,
};

export const initialCustomerDetailsFilters = {
  ...dateRange,
  tag_ids: [],
  not_tag_ids: [],
  page: 1,
  label: "",
  per_page: 20,
  sort_by: "foreign_total_amount_due",
  sort_order: "desc",
  sort_by_keys: {
    "#invoice": ["foreign_total_amount_due", "desc"],
    "#backlog": ["total_amount_open", "desc"],
    "#credit_memo": ["foreign_total_amount_due", "desc"],
    "#deposit": ["amount", "desc"],
    "#payments": ["payment_date", "desc"],
    "#paymenthistory": ["latest_status_date", "desc"],
    "#files": ["upload_date", "desc"],
  },
  appliedCustomFilters: {},
  appliedARPCustomFilters: {},
  selectedSection: null,
};

export const initialCustomerDetails = {
  filter: {
    ...initialCustomerDetailsFilters,
    ...savedFilters,
  },
  prevUrl: "",
  selectedSection: null,
  showPaymentDialog: false,
  chartData: null,
  invoiceList: [],
  customerCards: [],
  arPaymentCustomFilters: [],
  customerBanks: [],
  customerMicroDeposits: [],
  selectedRows: [],
  emailTemplateData: [],
  customerRelData: null,
  customerData: null,
  isFetching: false,
  showEmailPopup: false,
  customFilters: [],
  client_customer_rel_id: null,
  customerId: null,
  floatButtonWidth: false,
  showpayIQDateModal: false,
  showPaymentOptionModal: false,
  showPrePayModal: false,
  showCallPopUp: false,
  showNoteModal: false,
  isinvoicelevelnote: false,
  notesData: {},
  depositList: [],
  depositSelectedSection: null,
  fetchingChartData: undefined,
  isFetchingArInvoice: undefined,
  showPaidFeature: !TokenManager.isCustomer(),
};

const customerDetails = (state = initialCustomerDetails, action) => {
  switch (action.type) {
    case ReducersTypes.CUSTOMER_DETAILS:
      return { ...state, ...action.payload };
    case ReducersTypes.RESET_CUSTOMER_DETAILS:
      return {
        ...initialCustomerDetails,
        filter: { ...initialCustomerDetails.filter, ...action.payload },
      };
    case ReducersTypes.CUSTOMER_DETAILS_FILTER:
      return { ...state, filter: { ...state.filter, ...action.payload } };
    case ReducersTypes.RESET_CUSTOMER_DETAILS_FILTER:
      return {
        ...state,
        filter: initialCustomerDetailsFilters,
        fetchingChartData: false,
      };
    case ReducersTypes.CUSTOMER_DETAILS_CUSTOMER_REL_DATA:
      return { ...state, customerRelData: action.payload };
    case ReducersTypes.RESET_CUSTOMER_DETAILS_CUSTOMER_REL_DATA:
      return {
        ...state,
        customerRelData: initialCustomerDetails.customerRelData,
      };
    case ReducersTypes.CUSTOMER_DETAILS_REL_ID:
      return { ...state, client_customer_rel_id: action.payload };
    case ReducersTypes.RESET_CUSTOMER_DETAILS_REL_ID:
      return {
        ...state,
        client_customer_rel_id: initialCustomerDetails.client_customer_rel_id,
      };
    case ReducersTypes.CUSTOMER_DETAILS_ID:
      return { ...state, customerId: action.payload };
    case ReducersTypes.RESET_CUSTOMER_DETAILS_ID:
      return { ...state, customerId: initialCustomerDetails.customerId };
    case ReducersTypes.CUSTOMER_DETAILS_LISTDATA:
      return { ...state, invoiceList: action.payload };
    default:
      return state;
  }
};

export default customerDetails;
