import { logout } from "handlers/setters";
import get from "lodash/get";
import { default as React, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getLocaleString } from "utils/localization/locale";
import TokenManager from "utils/TokenManager";
import logo from "assets/images/serrala_logo.svg";
import smallLogo from "assets/images/small-logo.png";
import ClickAwayListener from "components/ClickAwayListener";

export const Header = ({ user }) => {
  const [showpopup, setshowpopup] = useState(false);
  const username = TokenManager.getUserName() || "-";
  const client_logo = get(user, "datatemp.client_logo", "");
  const client_name = get(user, "datatemp.client_name", "Logo");
  return (
    <div className="row menufixed py-2" id="topHeader">
      <div className="col col-md-4">
        <div>
          <img
            className="logo-header hide-mobile mt-3 ps-0"
            src={logo}
            alt="Serrala"
          />
          <img
            className="logo-header-mobile"
            src={smallLogo}
            alt="Serrala"
          />
        </div>
      </div>
      <div className="col col-md-4 d-flex justify-content-center">
        <div className="d-flex align-items-center justify-content-center w-100">
          {client_logo ? (
            <img style={{ height: 60 }} src={client_logo} alt={client_name} />
          ) : (
            <div className="text-uppercase t-28">{client_name}</div>
          )}
        </div>
      </div>
      <div className="col col-md-4 text-end">
        <span className="dropdown">
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip>{getLocaleString("header.profileIconText")}</Tooltip>
            }
          >
            <div
              className="btn cursor-pointer pe-0" // dropdown-toggle for down arrow icon
              to="#"
              role="button"
              id="profile"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={() => setshowpopup(!showpopup)}
            >
              <div className="text-icon-big rounded-circle">
                {(username[0] || "").toUpperCase()}
              </div>
            </div>
          </OverlayTrigger>

          <ClickAwayListener onClickAway={setshowpopup}>
            <div
              className={`dropdown-menu pt-3 ${
                showpopup && "open-profilepic-menu show"
              }`}
              id="profilebox"
              aria-labelledby="dropdownMenuLink"
            >
              <div className="text-icon-big rounded-circle">
                {(username[0] || "").toUpperCase()}
              </div>
              <p title={username} className="text-center">
                {username}
              </p>

              <div className="row" id="dtwrapper">
                <div className="col-6 align-left">
                  <Link
                    disabled
                    onClick={(e) => {
                      setshowpopup(false);
                    }}
                    to="/settings/profile"
                  >
                    {getLocaleString("header.profile")}
                  </Link>
                </div>
                <div className="col-6 align-right">
                  <Link
                    disabled
                    onClick={(e) => {
                      e.preventDefault();
                      setshowpopup(false);
                      logout();
                    }}
                    to="/"
                  >
                    {getLocaleString("header.signout")}
                  </Link>
                </div>
              </div>
            </div>
          </ClickAwayListener>
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ux: state.ux,
  user: state.user,
  clients: state.user.clients,
});

function mapDispatchToProps(dispatch) {}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
