export function generateQueryString(params) {
  if (!params || !Object.keys(params).length) return '';
  const queryString = Object.keys(params)
    .filter((d) => params[d])
    .filter(d => params[d] && d.length > 1)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
  return queryString ? `?${queryString}` : '';
}

export function queryStringToObject(queryString) {
  if (!queryString) return;
  let queryObject = {};
  const [url, paramString] = queryString.split("?");
  const params = paramString.split("&");
  for (let i in params) {
    const split = params[i].split("=");
    queryObject[decodeURIComponent(split[0])] = decodeURIComponent(split[1]);
  }
  return {
    baseUrl: url,
    ...queryObject,
  };
}
