import { getUrlExtension } from "handlers/getters";
import React from "react";
import { _get } from "utils";
import { EXTENSIONS } from "./const.js";

export function getDocViewFromExt({ data, width, isbase64, ext, uri, name }) {
  const ext1 = ext || getUrlExtension(uri);
  console.log("data, width, isbase64, ext: ", data, width, isbase64, ext, uri);
  switch (ext1) {
    case "jpg":
    case "jpeg":
    case "png":
    case "image/png":
    case "image/jpg":
    case "image/jpeg":
      return <ImgView data={data} uri={uri} width={width} name={name} />;
    case "pdf":
    case "application/pdf":
      return <PdfView data={data} uri={uri} width={width} isbase64={isbase64} name={name} />;
    case "ppt":
    case "pptx":
      return <PptView data={data} uri={uri} width={width} name={name} />;
    case "txt":
    case "text":
    case "rtf":
      return <TextView data={data} uri={uri} width={width} isbase64={isbase64} name={name} />;
    case "doc":
    case "docx":
    case "image/tiff":
    case "image/tif":
    case "tiff":
    case "tif":
    default:
      return <UnsupportedView data={data} uri={uri} width={width} ext={ext1} name={name} />;
  }
}

const UnsupportedView = ({ data, uri, ext, width = "", name }) => (
  <div id="preview-unsupported" style={{ height: "60vh", overflow: "auto" }} className="text-center">
    <div className="p-2">
      <div className="text-center p-4">Filetype not supported, please download</div>
      <div
        id="unsupported_download"
        onClick={() => {
          if (window.Cypress) { return; }
          var downloadLink = document.createElement("a");
          downloadLink.href = data;
          downloadLink.download = name + "." + _get(EXTENSIONS, ext, "txt");
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          // previewInNewTab(data || uri, ext);
        }}
      >
        <i className="fas fa-download text-secondary t-20 text-center cursor-pointer" />
      </div>
    </div>
  </div>
);

const ImgView = ({ data, uri, width = "" }) => (
  <div id="preview-image" style={{ height: "60vh", overflow: "auto" }} className="text-center">
    <img
      src={data || uri}
      alt="loading"
      style={{ objectFit: "cover", overflow: "auto", height: "100%", width: "100%" }}
      className={`${width}`}
    />
  </div>
);

const PdfView = ({ data, isbase64, uri }) => (
  <div id="preview-pdf">
    {!isbase64 ? (
      <embed className="h-100 w-100 minht-300 holds-the-iframe" src={uri} alt="loading" />
    ) : (
      <embed className="h-100 w-100 minht-300 holds-the-iframe" src={data} alt="loading" />
    )}
  </div>
);

const PptView = ({ data }) => (
  <embed
    id="preview-ppt"
    className="holds-the-iframe h-100 w-100 minht-300"
    src={`https://view.officeapps.live.com/op/embed.aspx?src=${data}`}
  />
);

// const DocView = ({ data }) => <embed className="h-100 w-100" src={`https://docs.google.com/viewerng/viewer?url=${data}`} />;
// const TxtView = ({ data }) => <embed className="holds-the-iframe h-100 w-100 minht-300" type="text/plain" src={data} />;

const TextView = ({ data }) => (
  <embed
    id="preview-text"
    className="holds-the-iframe h-100 w-100 minht-300"
    src={`https://docs.google.com/gview?url=${data}&embedded=true`}
  />
);

export default function DocViewer({ data, name, isbase64, ext, uri }) {
  return getDocViewFromExt({ data, isbase64, ext, uri, name });
}