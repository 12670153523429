import { ReducersTypes } from "constants/ReducersTypes";
import moment from "moment";
import get from "lodash/get";
import { checkForeignCurrency, getUXSettingsFromStorage } from "utils";
import { getDateByRangeInterval } from "utils/dates";
import { getLocaleString } from "utils/localization/locale";

export const INITIAL_JOURNAL_COLUMNS_ORDER = {
  [getLocaleString("journal.document_number", "Document Number")]: 1,
  [getLocaleString("common.date", "Date")]: 2,
  [getLocaleString("common.memo", "Memo")]: 3,
  [getLocaleString("common.category", "Category")]: 4,
  [getLocaleString("common.account", "Account")]: 5,
  [getLocaleString("common.amount", "Amount")]: 6,
};

let filtersFromReportJInflow = getUXSettingsFromStorage(
  "JOURNAL_INFLOW_FILTERS"
);
if (filtersFromReportJInflow && filtersFromReportJInflow.dateLabel) {
  const { dateLabel, from_date, to_date } = filtersFromReportJInflow;
  const [sd, ed] = getDateByRangeInterval(dateLabel, from_date, to_date);
  filtersFromReportJInflow = {
    ...filtersFromReportJInflow,
    from_date: sd,
    to_date: ed,
  };
}
let filtersFromReportJOutflow = getUXSettingsFromStorage(
  "JOURNAL_OUTFLOW_FILTERS"
);
if (filtersFromReportJOutflow && filtersFromReportJOutflow.dateLabel) {
  const { dateLabel, from_date, to_date } = filtersFromReportJOutflow;
  const [sd, ed] = getDateByRangeInterval(dateLabel, from_date, to_date);
  filtersFromReportJOutflow = {
    ...filtersFromReportJOutflow,
    from_date: sd,
    to_date: ed,
  };
}

const from_date = moment().format("MM/DD/YYYY");
const to_date = moment().add(30, "days").format("MM/DD/YYYY");
const { keyNamePrefix } = checkForeignCurrency();

export const JOURNAL_LIST_COLUMNS = {
  [getLocaleString("journal.document_number", "Document Number")]: true,
  [getLocaleString("common.date", "Date")]: true,
  [getLocaleString("common.memo", "Memo")]: true,
  [getLocaleString("common.category", "Category")]: true,
  [getLocaleString("common.account", "Account")]: true,
  [getLocaleString("common.amount", "Amount")]: true,
};

export const initialJournalFilter = {
  page: 1,
  sort_by: `${keyNamePrefix}amount`,
  sort_order: "desc",
  from_date,
  to_date,
  dateLabel: "",
};
export const initialData = {
  journalInflow: {
    list: [],
    isFetching: true,
    details: null,
    exportList: [],
    isExportListFetching: false,
    visibleColumns: JOURNAL_LIST_COLUMNS,
    filters: {
      page: 1,
      sort_by: get(
        filtersFromReportJInflow,
        "sort_by",
        `${keyNamePrefix}amount`
      ),
      sort_order: get(filtersFromReportJInflow, "sort_order", "desc"),
      category_type: get(filtersFromReportJInflow, "category_type", ""),
      from_date: get(filtersFromReportJInflow, "from_date", from_date),
      to_date: get(filtersFromReportJInflow, "to_date", to_date),
      journal_category_id: get(
        filtersFromReportJInflow,
        "journal_category_id",
        ""
      ),
      selectedBankAccount: get(
        filtersFromReportJInflow,
        "selectedBankAccount",
        null
      ),
      corp_entity_id:
        get(filtersFromReportJInflow, "corp_entity_id", "") !== "null"
          ? get(filtersFromReportJInflow, "corp_entity_id", "")
          : "",
    },
  },
  journalOutflow: {
    list: [],
    isFetching: true,
    details: null,
    exportList: [],
    isExportListFetching: false,
    visibleColumns: JOURNAL_LIST_COLUMNS,
    filters: {
      page: 1,
      sort_by: get(
        filtersFromReportJOutflow,
        "sort_by",
        `${keyNamePrefix}amount`
      ),
      sort_order: get(filtersFromReportJOutflow, "sort_order", "desc"),
      from_date: get(filtersFromReportJOutflow, "from_date", from_date),
      to_date: get(filtersFromReportJOutflow, "to_date", to_date),
      selectedBankAccount: get(
        filtersFromReportJInflow,
        "selectedBankAccount",
        null
      ),
      journal_category_id: get(
        filtersFromReportJOutflow,
        "journal_category_id",
        ""
      ),
      corp_entity_id:
        get(filtersFromReportJOutflow, "corp_entity_id", "") !== "null"
          ? get(filtersFromReportJOutflow, "corp_entity_id", "")
          : "",
    },
  },
  journalEntriesCategories: [],
};

const journalEntries = (state = initialData, action) => {
  const { type, payload } = action;
  switch (type) {
    case ReducersTypes.FETCH_JOURNAL_INFLOW_LIST:
      return {
        ...state,
        journalInflow: {
          ...state.journalInflow,
          details: null,
          ...payload,
          filters: {
            ...state.journalInflow.filters,
            ...payload.filters,
          },
        },
      };
    case ReducersTypes.FETCH_JOURNAL_OUTFLOW_LIST:
      return {
        ...state,
        journalOutflow: {
          ...state.journalOutflow,
          details: null,
          ...payload,
          filters: {
            ...state.journalOutflow.filters,
            ...payload.filters,
          },
        },
      };
    case ReducersTypes.FETCH_JOURNAL_INFLOW_DETAILS:
      return {
        ...state,
        journalInflow: {
          ...state.journalInflow,
          ...payload,
        },
      };
    case ReducersTypes.FETCH_JOURNAL_OUTFLOW_DETAILS:
      return {
        ...state,
        journalOutflow: {
          ...state.journalOutflow,
          ...payload,
        },
      };
    case ReducersTypes.SET_JOURNAL_INFLOW_FILTERS:
      return {
        ...state,
        journalInflow: {
          ...state.journalInflow,
          filters: { ...state.journalInflow.filters, ...payload.filters },
        },
      };
    case ReducersTypes.SET_JOURNAL_OUTFLOW_FILTERS:
      return {
        ...state,
        journalOutflow: {
          ...state.journalOutflow,
          filters: { ...state.journalOutflow.filters, ...payload.filters },
        },
      };
    case ReducersTypes.FETCH_JOURNAL_EXPORT:
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          ...payload,
        },
      };
    case ReducersTypes.SET_JOURNAL_COLUMNS:
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          ...payload,
        },
      };
    case ReducersTypes.SET_JOURNAL_CATEGORIES:
      return {
        ...state,
        ...payload.data,
      };
    case ReducersTypes.RESET_JOURNAL_FILTERS:
      return {
        ...state,
        journalInflow: {
          ...state.journalInflow,
          filters: {
            ...initialJournalFilter,
          },
        },
        journalOutflow: {
          ...state.journalOutflow,
          filters: {
            ...initialJournalFilter,
          },
        },
      };
    default:
      return state;
  }
};

export default journalEntries;
