/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "./payroll.css";
import { instance, instanceMultipart } from "actions/axiosInstance";
import { handleAPIError } from "handlers/setters";
import get from "lodash/get";
import uniqBy from "lodash/unionBy";
import { _get, dataURItoBlob, savePopUpState } from "utils";
import TokenManager from "utils/TokenManager";
import { ClickAwayListener } from '@mui/base';
import {
  refreshClients,
  setPayrollSettings,
  refreshCorporateEntities,
} from "actions/user";
import { Accordion, Card } from "react-bootstrap";
import constants from "constants/index";
import defaultLogo from "assets/images/defaultLogoImage.png";
import Toaster from "components/Toaster";
import { emailRegEx, phoneRegEx, urlRegEx } from "constants/regex";
import { getLocaleString } from "utils/localization/locale";

const POSTDATA = {
  name: null,
  currency: "USD",
  logo: { data: null, name: "" },
  is_enabled: true,
};

function Entity(props) {
  const [postData, setPostData] = useState(POSTDATA);
  const [allEntities, setAllEntities] = useState([]);
  const [line, setline] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isValidName, setIsValidName] = useState(false);
  const [isValidEntityEmail, setIsValidEntityEmail] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [isValidCurrency, setIsValidCurrency] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidTnCLink, setIsValidTnCLink] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [imageError, setImageError] = useState("");
  const isCorporateEntity = TokenManager.getCorporateEntityId();
  const prevProps = useRef(props.expandedSection);
  const hash = props.location.hash;
  const isSectionExpanded = props.expandedSection;

  useEffect(() => {
    if (hash === "#corporate_entities") {
      getdata();
      const ref = document.getElementById("corporate_entities");
      ref && setTimeout(() => window.scrollTo(0, ref.offsetTop), 500);
      setIsExpanded(true);
    }
  }, [hash]);

  useEffect(() => {
    prevProps.current = props;
  }, []);

  useEffect(() => {
    saveState();
  }, [postData]);

  useEffect(() => {
    const isSectionExpanded =
      get(props, "expandedSection", "") !==
        get(prevProps.current, "expandedSection", "") &&
      get(props, "expandedSection", "") === "#corporate_entities";
    if (isSectionExpanded) {
      getdata();
    }
  }, [isSectionExpanded]);

  function getdata(isMutate = false) {
    instance
      .get(isMutate ? "payroll/setting?mutate=1" : "payroll/setting")
      .then((resp) => {
        setAllEntities(
          get(resp, "data.result", []).map((x) => ({
            ...x,
            logo: { data: x.logo_url },
          }))
        );
        setIsLoading(false);
        setPayrollSettings(get(resp, "data.result", []));
        refreshClients();
      })
      .catch((e) => handleAPIError(e));
  }

  const saveState = () =>
    savePopUpState(
      dataUpdated,
      false,
      "corporate_entities",
      (showUnsavedPopUp) => props.onChange(showUnsavedPopUp)
    );

  const updatepostdata = (e) => {
    setPostData({
      ...postData,
      [e.target.name]: e.target.value,
    });
    setDataUpdated(true);
  };

  const onUploadLogo = (e) => {
    const { files } = e.target;
    const file = files[0];
    if (!file) {
      return;
    }
    const size = file.size / 1024;
    var reader = new FileReader();
    if (size > 100) {
      setImageError(
        getLocaleString(
          "settings.settings_company.image_size_should_be_less_than_100kb",
          "Image size should be less than 100kb"
        )
      );
      return;
    }
    setImageError("");
    var canvas = document.getElementById("cv_ce");
    var ctx = canvas.getContext("2d");
    reader.readAsDataURL(files[0]);
    const _props = {
      setImageError,
      updatepostdata,
    };
    const onload = (_e, _props) => {
      var image = new Image();
      image.src = _e.target.result;
      function onimageload() {
        const height = this.height;
        const width = this.width;
        const canvas = ctx.canvas;
        const hRatio = canvas.width / width;
        const vRatio = canvas.height / height;
        const ratio = Math.min(hRatio, vRatio);
        const centerShift_x = (canvas.width - width * ratio) / 2;
        const centerShift_y = (canvas.height - height * ratio) / 2;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(
          image,
          0,
          0,
          width,
          height,
          centerShift_x,
          centerShift_y,
          width * ratio,
          height * ratio
        );
        _props.setImageError("");
        debugger;
        _props.updatepostdata({
          target: {
            name: "logo",
            value: { name: file.name, data: canvas.toDataURL("image/png") },
          },
        });
      }
      image.onload = onimageload;
    };
    reader.onload = (e) => onload(e, _props);
  };

  const onSubmit = () => {
    const {
      id,
      name,
      email,
      phone,
      currency,
      is_enabled,
      date_format,
      reply_to_email,
      terms_and_conditions_url,
      terms_and_conditions_display_text,
    } = postData;
    if (!(name && currency)) {
      setIsValidName(!name);
      setIsValidCurrency(!currency);
      return;
    }
    if (terms_and_conditions_url && !terms_and_conditions_url.match(urlRegEx)) {
      setIsValidTnCLink(true);
      return;
    } else {
      setIsValidTnCLink(false);
    }

    const entityEmailChecker = email
      ? !(email === "" || email.match(emailRegEx))
      : false;
    setIsValidEntityEmail(entityEmailChecker);
    if (entityEmailChecker) return;

    const phoneChecker = phone
      ? !(phone === "" || phone.match(phoneRegEx))
      : false;
    setIsValidPhone(phoneChecker);
    if (phoneChecker) return;

    const checker = reply_to_email
      ? !(reply_to_email === "" || reply_to_email.match(emailRegEx))
      : false;
    setIsValidEmail(checker);
    if (checker) return;

    let bodyFormData = new FormData();
    const postFields = {
      id,
      email,
      phone,
      name,
      currency,
      is_enabled,
      date_format,
      reply_to_email: reply_to_email || "",
      terms_and_conditions_url,
      terms_and_conditions_display_text,
    };
    if (!postData.logo.data && postData.logo.name === "null") {
      postFields.logo = "0";
    }
    bodyFormData.append("records", JSON.stringify([postFields]));
    postData.logo.data &&
      postData.logo.name &&
      bodyFormData.append(
        "logo",
        new File([dataURItoBlob(postData.logo.data)], postData.logo.name),
        `${postData.logo.name}`
      );

    const URL =
      line !== "new"
        ? `payroll/setting?id=${id}&mutate=1`
        : "payroll/setting?mutate=1";
    instanceMultipart
      .put(URL, bodyFormData)
      .then(() => {
        setline("");
        setPostData(null);
        setDataUpdated(false);
        getdata(true);
        refreshCorporateEntities();
        Toaster(
          getLocaleString(
            "common.updated_successfully",
            "Updated successfully"
          ),
          "success"
        );
      })
      .catch((e) => handleAPIError(e));
  };

  const renderEditableRow = (prevData, showCheckbox) => {
    return (
      <tr>
        {!isCorporateEntity && (
          <td>
            <div className="text-center">
              <input
                className="md-checkbox cursor-pointer"
                type="checkbox"
                disabled={!showCheckbox}
                checked={get(prevData, "is_enabled", false)}
                onClick={(e) => {
                  setPostData({
                    ...postData,
                    is_enabled: e.target.checked,
                  });
                  setDataUpdated(true);
                }}
              />
            </div>
          </td>
        )}
        <td>
          <div className="form-goup">
            <input
              type="text"
              name="name"
              className={`form-control t-14 ${
                isValidName ? "input-error" : ""
              }`}
              placeholder="Enter entity name"
              onChange={(e) => {
                updatepostdata(e);
              }}
              defaultValue={get(prevData, "name", "")}
            />
            {isValidName ? (
              <span className="form-error">
                {getLocaleString(
                  "common.please_fill_required_fields",
                  "Please fill required field"
                )}
              </span>
            ) : null}
          </div>
        </td>
        <td>
          <div className="form-goup">
            <select
              className={`form-control t-14 ${
                isValidCurrency ? "input-error" : ""
              }`}
              name="currency"
              onChange={(e) => updatepostdata(e)}
              defaultValue={get(prevData, "currency", "")}
            >
              {uniqBy(
                Object.keys(constants.CURRENCY).map((cur) => ({
                  ...constants.CURRENCY[cur],
                  currency_name: constants.CURRENCY[cur].name,
                  name: constants.CURRENCY[cur].code,
                  value: constants.CURRENCY[cur].code,
                })),
                "value"
              ).map((x) => (
                <option value={x.value} key={x.value}>
                  {x.name}
                </option>
              ))}
            </select>
            {isValidCurrency ? (
              <span className="form-error">
                {getLocaleString(
                  "common.please_fill_required_fields",
                  "Please fill required field"
                )}
              </span>
            ) : null}
          </div>
        </td>
        <td>
          <div className="form-group mb-3">
            <select
              className="form-control"
              name="date_format"
              onChange={(e) => updatepostdata(e)}
              defaultValue={get(prevData, "date_format", "")}
            >
              <option value="" selected default>
                {getLocaleString(
                  "settings.settings_company.select_date_format",
                  "Select date format"
                )}
              </option>
              <option value="MM/DD/YYYY">MM/DD/YYYY</option>
              <option value="DD/MM/YYYY">DD/MM/YYYY</option>
            </select>
          </div>
        </td>
        <td>
          <div className="form-group mb-3 position-relative d-inline-block profile-pic d-flex justify-content-center">
            <canvas id={"cv_ce"} height="100" width="200" className="d-none" />
            <input
              type="file"
              id={"ce_file"}
              className="logo-file-element"
              title={getLocaleString(
                "settings.settings_company.upload_logo",
                "Upload logo"
              )}
              accept="image/png, image/jpeg"
              name="logo"
              onChange={onUploadLogo}
            />
            <label
              for={"ce_file"}
              className="cursor-pointer"
              style={{ margin: "0 15px" }}
            >
              {_get(prevData, "logo.data", "") ? (
                <div className="text-center">
                  {getLocaleString(
                    "settings.settings_company.click_to_change_logo",
                    "Click to Change Logo"
                  )}
                  :
                </div>
              ) : (
                <div className="text-center">
                  {getLocaleString(
                    "settings.settings_company.click_to_upload_logo",
                    "Click to Upload Logo"
                  )}
                  :
                </div>
              )}
              <img
                src={_get(prevData, "logo.data", "") || defaultLogo}
                alt="Default logo"
                title={getLocaleString(
                  "settings.settings_company.click_here_to_upload_logo",
                  "Click here to upload logo"
                )}
              />
            </label>
            {_get(prevData, "logo.data", "") && (
              <div
                title={getLocaleString(
                  "settings.settings_company.remove_logo",
                  "Remove logo"
                )}
                className="cursor-pointer remove-logo-btn"
                onClick={() => {
                  setImageError("");
                  updatepostdata({
                    target: {
                      name: "logo",
                      value: {
                        name: "null",
                        data: null,
                      },
                    },
                  });
                }}
              >
                <i className="fa fa-trash-o cursor-pointer" />
              </div>
            )}
            {imageError && (
              <span className="error t-12 d-block">{imageError}</span>
            )}
          </div>
        </td>
        <td>
          <div className="form-group mb-3">
            <input
              type="text"
              name="reply_to_email"
              className={`form-control t-14 ${
                isValidEmail ? "input-error" : ""
              }`}
              placeholder={getLocaleString(
                "settings.settings_company.enter_ar_email",
                "Enter AR email"
              )}
              onChange={(e) => {
                setPostData({
                  ...postData,
                  reply_to_email: e.target.value,
                });
                setDataUpdated(true);
              }}
              defaultValue={get(prevData, "reply_to_email", "")}
            />
            {isValidEmail ? (
              <span className="form-error">
                {getLocaleString(
                  "common.please_enter_valid_email",
                  "Please enter a valid email"
                )}
              </span>
            ) : null}
          </div>
        </td>
        <td>
          <div className="form-group mb-3">
            <input
              type="email"
              name="email"
              className={`form-control t-14 ${
                isValidEntityEmail ? "input-error" : ""
              }`}
              placeholder={getLocaleString(
                "settings.settings_company.enter_entity_email",
                "Enter entity email"
              )}
              onChange={(e) => {
                updatepostdata(e);
              }}
              defaultValue={get(prevData, "email", "")}
            />
            {isValidEntityEmail ? (
              <span className="form-error">
                {getLocaleString(
                  "common.please_enter_valid_email",
                  "Please enter a valid email"
                )}
              </span>
            ) : null}
          </div>
        </td>
        <td>
          <div className="form-group mb-3">
            <input
              type="text"
              name="phone"
              className={`form-control t-14 ${
                isValidPhone ? "input-error" : ""
              }`}
              placeholder={getLocaleString(
                "settings.settings_company.enter_entity_phone",
                "Enter entity phone"
              )}
              onChange={(e) => {
                updatepostdata(e);
              }}
              defaultValue={get(prevData, "phone", "")}
            />
            {isValidPhone ? (
              <span className="form-error">
                {getLocaleString(
                  "common.phone_number_is_invalid",
                  "Phone number is invalid"
                )}{" "}
              </span>
            ) : null}
          </div>
        </td>
        <td>
          <div className="form-group mb-3">
            <label className="mb-0">
              {getLocaleString(
                "settings.settings_company.tnc_text",
                "TnC Text"
              )}
              :
            </label>
            <input
              type="text"
              title={getLocaleString(
                "settings.settings_company.tnc_text",
                "TnC Text"
              )}
              name="terms_and_conditions_display_text"
              className="form-control t-14"
              placeholder={getLocaleString(
                "settings.settings_company.tnc_text",
                "TnC Text"
              )}
              onChange={(e) => {
                setPostData({
                  ...postData,
                  terms_and_conditions_display_text: e.target.value,
                });
                setDataUpdated(true);
              }}
              defaultValue={get(
                prevData,
                "terms_and_conditions_display_text",
                ""
              )}
            />
          </div>
          <div className="form-group mb-3 mt-2">
            <label className="mb-0">
              {getLocaleString(
                "settings.settings_company.tnc_link",
                "TnC Link"
              )}
              :
            </label>
            <input
              type="text"
              title={getLocaleString(
                "settings.settings_company.tnc_link",
                "TnC Link"
              )}
              name="terms_and_conditions_url"
              className={`form-control t-14 ${
                isValidTnCLink ? "input-error" : ""
              }`}
              placeholder={getLocaleString(
                "settings.settings_company.tnc_link",
                "TnC Link"
              )}
              onChange={(e) => {
                setPostData({
                  ...postData,
                  terms_and_conditions_url: e.target.value,
                });
                setDataUpdated(true);
              }}
              defaultValue={get(prevData, "terms_and_conditions_url", "")}
            />
          </div>
        </td>
        <div className="form-group mb-3 d-flex justify-content-center">
          <div
            id="corporate_entities_save"
            className="btn payrollsumbtn cursor-pointer"
            onClick={onSubmit}
          >
            <i
              title={getLocaleString("common.save_changes", "Save Changes")}
              className="fa fa-save color-blue"
            />
          </div>
          <div
            onClick={() => {
              setline("");
              setPostData(null);
              setIsValidCurrency(false);
              setIsValidName(false);
              setDataUpdated(false);
            }}
            className="btn cursor-pointer"
          >
            <i
              title={getLocaleString(
                "common.discard_changes",
                "Discard Changes"
              )}
              className="fa fa-remove color-blue"
            />
          </div>
        </div>
      </tr>
    );
  };

  return (
    <Accordion
      activeKey={isSectionExpanded === "#corporate_entities" && "0"}
      id="corporate_entities"
      className="col-md-12"
    >
      <Accordion.Item eventKey="0">
        <Accordion.Header
          onClick={() => {
            setIsExpanded(!isExpanded);
            isExpanded
              ? props.history.push("./company")
              : props.history.push("./company#corporate_entities");
          }}
          className="border mt-4 cursor-pointer bg-light-grey"
          as={Card.Header}
        >
          <h6 className="my-0">
            {getLocaleString(
              "settings.settings_company.corporate_entities",
              "Corporate Entities"
            )}
            {!line && isExpanded && !TokenManager.getCorporateEntityId() && (
              <React.Fragment>
                <i
                  onClick={(e) => {
                    e.stopPropagation();
                    setline("new");
                    setPostData(POSTDATA);
                  }}
                  className="fa fa-plus cashinflowclick  t-10 "
                  title={getLocaleString(
                    "common.click_to_add_item",
                    "Click to add item"
                  )}
                  aria-hidden="true"
                />
              </React.Fragment>
            )}
          </h6>
        </Accordion.Header>
        <Accordion.Body className="py-2" eventKey="0">
          <div className="row">
            <div className="col-md-12">
              <ClickAwayListener onClickAway={() => {}}>
                <>
                  <div className="titletranshist t-18 mb-2">
                    {getLocaleString(
                      "settings.settings_company.list_of_entities",
                      "List of Entities"
                    )}
                  </div>
                  <div className="payrolltable overflow-x-auto">
                    <table>
                      <thead>
                        <tr>
                          {!isCorporateEntity && (
                            <th className="w-10 text-center">
                              {getLocaleString("common.enabled", "Enabled")}
                            </th>
                          )}
                          <th>{getLocaleString("common.entity", "Entity")}</th>
                          <th>{getLocaleString("common.currency", "Currency")}</th>
                          <th>
                            {getLocaleString("common.date_format", "Date Format")}
                          </th>
                          <th className="text-center">
                            {getLocaleString("common.logo", "Logo")}
                          </th>
                          <th className="text-center">
                            {getLocaleString(
                              "settings.settings_company.ar_email",
                              "AR email"
                            )}
                          </th>
                          <th className="text-center">
                            {getLocaleString("common.email", "Email")}
                          </th>
                          <th className="text-center">
                            {getLocaleString("common.phone", "Phone")}
                          </th>
                          <th className="w-18">
                            {getLocaleString(
                              "settings.settings_company.tnc_link",
                              "TnC Link"
                            )}
                          </th>
                          {line !== "" && (
                            <th className="text-center">
                              {getLocaleString("common.action", "Action")}
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td colspan="8" className="text-center">
                              {getLocaleString("common.spinner.loading")}...
                            </td>
                          </tr>
                        ) : null}
                        {line === "new" ? renderEditableRow(postData, false) : null}
                        {allEntities &&
                          allEntities.map((entity, d) => {
                            if (line === d) {
                              return renderEditableRow(postData, true);
                            }
                            return (
                              <tr
                                className="cursor-pointer"
                                title="Edit"
                                key={entity.id}
                                onClick={() => {
                                  if (dataUpdated) {
                                    Toaster(
                                      getLocaleString(
                                        "common.earlier_changes_not_saved",
                                        "Earlier changes not saved"
                                      ),
                                      "error"
                                    );
                                  }
                                  setline(d);
                                  setPostData(entity);
                                  setIsValidCurrency(false);
                                  setIsValidEmail(false);
                                  setIsValidName(false);
                                  setIsValidTnCLink(false);
                                  setDataUpdated(false);
                                }}
                              >
                                {!isCorporateEntity && (
                                  <td>
                                    <div className="text-center">
                                      <input
                                        className="md-checkbox cursor-pointer"
                                        type="checkbox"
                                        checked={entity.is_enabled}
                                      />
                                    </div>
                                  </td>
                                )}
                                <td>{entity.name}</td>
                                <td>{entity.currency}</td>
                                <td>{entity.date_format}</td>
                                <td>
                                  <div
                                    onClick={() => {
                                      setTimeout(() => {
                                        document.getElementById("ce_file").click();
                                      }, 10);
                                    }}
                                  >
                                    <div className="col-md-12">
                                      <div className="form-group mb-3 position-relative d-inline-block profile-pic d-flex justify-content-center">
                                        <label
                                          for="file"
                                          className="cursor-pointer mb-0"
                                        >
                                          <img
                                            src={entity.logo.data || defaultLogo}
                                            alt="Default logo"
                                            title={getLocaleString(
                                              "settings.settings_company.click_here_to_upload_logo",
                                              "Click here to upload logo"
                                            )}
                                          />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>{entity.reply_to_email}</td>
                                <td>{entity.email}</td>
                                <td>{entity.phone}</td>
                                <td>
                                  <a
                                    className="t-13"
                                    href={entity.terms_and_conditions_url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {entity.terms_and_conditions_display_text}
                                  </a>
                                </td>
                                {line !== "" && <td />}
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </>
              </ClickAwayListener>
            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default Entity;
