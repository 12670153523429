import { ReducersTypes } from "constants/ReducersTypes";
import moment from "moment";
import { getUXSettingsFromStorage } from "utils";
import { getDateByRangeInterval } from "utils/dates";

const dateRange = {
  from_date: moment().subtract(365, "days").format("MM/DD/YYYY"),
  to_date: moment().format("MM/DD/YYYY"),
};

let savedFilters = getUXSettingsFromStorage("PO_FILTERS");

if (savedFilters && savedFilters.interval) {
  const { interval, from_date, to_date } = savedFilters;
  const updatedKeys = {};
  const [sd, ed] = getDateByRangeInterval(
    interval === "All" ? "AllToDate" : interval,
    from_date,
    to_date
  );
  savedFilters = {
    ...savedFilters,
    ...updatedKeys,
    from_date: sd,
    to_date: ed,
  };
}

export const initialPurchaseOrderFilters = {
  page: 1,
  status: "all",
  tag_ids: [],
  to_date: "",
  sort_by: "total_amount",
  invoiced: 0,
  from_date: "",
  date_type: "order",
  sort_order: "desc",
  not_tag_ids: [],
  vendorSelect: null,
  invoiceSelect: null,
  corp_entity_id: "",
  selectedSection: null,
  ...dateRange,
};

export const initialPurchaseOrderData = {
  isFetching: false,
  isExporting: false,
  listingData: [],
  exportedData: [],
  totalRecords: 0,
  filters: {
    ...initialPurchaseOrderFilters,
    ...savedFilters,
  },
};

export const initialPurchaseOrderColumnOrder = {
  PO: 1,
  Vendor: 2,
  "PO Date": 3,
  Description: 4,
  "PayIQ Date": 5,
  "PO Amount": 6,
  Status: 7,
};

const purchaseorder = (state = initialPurchaseOrderData, action) => {
  switch (action.type) {
    case ReducersTypes.SET_PURCHASE_ORDER_LIST_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case ReducersTypes.SET_PURCHASE_ORDER_LIST_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };

    case ReducersTypes.RESET_PURCHASE_ORDER_LIST_FILTERS:
      return {
        ...state,
        filters: {
          ...initialPurchaseOrderData.filters,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};

export default purchaseorder;
