import React from "react";

export default function ToggleSwitchButton({
  onChange = () => {},
  id = "default_id",
  className = "",
  key = '',
  ...rest
}) {
  return (
    <div
      className={`checkbox switcher ${className}`}
      onClick={(e) => e.stopPropagation()}
      key={key}
      data-tlabel={rest && rest['data-tlabel']}
    >
      <label htmlFor={id}>
        <input
          id={id}
          type="checkbox"
          onChange={(e) => {
            e.stopPropagation();
            onChange(e);
          }}
          {...rest}
        />
        <span data-tlabel={rest && rest['data-tlabel']}>
          <small data-tlabel={rest && rest['data-tlabel']}></small>
        </span>
      </label>
    </div>
  );
}
