import React from "react";
import { NativeTypes } from "react-dnd-html5-backend";
import { useDrop } from "react-dnd";
import { getLocaleString } from "utils/localization/locale";

const TextareaDropBox = props => {
  const { onDrop } = props;
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item, monitor) {
      if (onDrop) {
        onDrop({item, props, monitor});
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });
  const isActive = canDrop && isOver;
  return (
    <div ref={drop} className="table-container">
        {props.children}
        {isActive ? (<div className="loader">{getLocaleString("common.release_to_drop", "Release to drop")}</div>) : null}
    </div>
  );
};
export default TextareaDropBox;
