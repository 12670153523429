import React, { useEffect, useState } from "react";
import Modal from "components/CustomModal";
import get from "lodash/get";
import Autocomplete from "components/Autocomplete";
import { urlify_string_only, urlify } from "utils/strings";
import Chip from '@mui/material/Chip';
import Button from "components/Button";
import { getLocaleString } from "utils/localization/locale";

export default function NotificationModal(props) {
  const { rowData, show, handleClose, sendNotification, searchUserList } =
    props;

  const [emailTo, setemailTo] = useState([]);
  const [content, setContent] = useState("");
  const [usersList, setUsersearchList] = useState([]);

  useEffect(() => {
    if (!show) {
      setemailTo("");
      setContent("");
      setUsersearchList([]);
    }
  }, [show]);

  return (
    <Modal
      footer={() =>
        rowData ? null : (
          <Button
            type="button"
            id="send-message"
            disabled={!content || !emailTo.length}
            onClick={() => sendNotification(emailTo, content, usersList)}
          >
            {getLocaleString("common.send", "Send")}
          </Button>
        )
      }
      modalBodyStyle={{ maxHeight: "calc(100vh - 300px)", overflowY: "auto" }}
      show={show}
      onHide={handleClose}
      title={
        rowData
          ? getLocaleString(
              "notifications_settings.notification",
              "Notification"
            )
          : getLocaleString("settings.new_notification", "New notification")
      }
    >
      <div>
        <div className="card-body p-1">
          {rowData ? (
            <div>
              <div className="mb-2">
                <strong>{getLocaleString("common.sender", "Sender")}:</strong>{" "}
                {get(rowData, "associated_sender.username", "-")}
              </div>
              <div className="mb-2">
                <strong>
                  {getLocaleString("common.receiver", "Receiver")}:
                </strong>{" "}
                {get(rowData, "associated_receiver.username", "-")}
              </div>
              <div className="mb-2">
                <strong>{getLocaleString("common.message", "Message")}:</strong>{" "}
                <span
                  onClick={() => {
                    const url = urlify_string_only(rowData.content);
                    if (url) {
                      url.includes("/customers") &&
                        localStorage.setItem("customer_activeTab", "araging");
                      url.includes("/vendors") &&
                        localStorage.setItem("vendor_activeTab", "apaging");
                      window.open(url, "_self");
                    }
                  }}
                  dangerouslySetInnerHTML={
                    urlify_string_only(rowData.content)
                      ? { __html: urlify(rowData.content) }
                      : { __html: rowData.content }
                  }
                ></span>
              </div>
              <div className="mb-2">
                <strong>{getLocaleString("common.status", "Status")}:</strong>{" "}
                {rowData.status}
              </div>
              <div className="mb-2">
                <strong>{getLocaleString("common.type", "Type")}:</strong>{" "}
                {rowData.type}
              </div>
            </div>
          ) : (
            <form id="share-form">
              <div
                id="email-list"
                className="mb-2 p-2"
                style={{
                  textAlign: "left",
                  borderRadius: 5,
                  border: "1px solid #d4d4d4",
                }}
              >
                {emailTo.length ? (
                  <div className="mb-1">
                    {emailTo.map((d) => (
                      <span className="me-1 mb-1">
                        <Chip
                          size="small"
                          label={d}
                          onDelete={() =>
                            setemailTo(emailTo.filter((email) => email !== d))
                          }
                          variant="outlined"
                        />
                      </span>
                    ))}
                  </div>
                ) : null}
                <Autocomplete
                  suggestions={usersList}
                  getSelected={(item) => {
                    setemailTo(
                      emailTo.length
                        ? item && !emailTo.includes(item.name)
                          ? [...emailTo, item.name]
                          : emailTo
                        : item
                        ? [item.name]
                        : []
                    );
                  }}
                  search={(text) => {
                    setUsersearchList(
                      searchUserList.filter(
                        (d) =>
                          d.email.toLowerCase().includes(text.toLowerCase()) ||
                          d.name.toLowerCase().includes(text.toLowerCase())
                      )
                    );
                  }}
                  placeholder={getLocaleString(
                    "settings.enter_name",
                    "Enter name"
                  )}
                  isLoading={false}
                />
              </div>
              <textarea
                id="message"
                className="form-control"
                placeholder={getLocaleString(
                  "common.add_message",
                  "Add a message"
                )}
                maxLength={1000}
                style={{ height: 150 }}
                onChange={(e) => setContent(e.target.value)}
              />
            </form>
          )}
        </div>
      </div>
    </Modal>
  );
}
