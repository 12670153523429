import React, { Component } from "react";
import cx from "classnames";
import parse from "html-react-parser";
import ClickAwayListener from "components/ClickAwayListener";
import { getLocaleString } from "utils/localization/locale";
import "assets/scss/autocomplete.scss";
import get from "lodash/get";

class Autocomplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: "",
      hide: false,
      typing: false,
      firstTimeFetch: false,
    };
  }

  componentDidMount() {
    const { defaultValue } = this.props;
    this.setState({
      filteredSuggestions: this.props.suggestions.length
        ? this.props.suggestions
        : [],
    });
    if (defaultValue) {
      // search(defaultValue);
      this.setState({ firstTimeFetch: !defaultValue, userInput: defaultValue });
    }
  }

  componentWillReceiveProps(nextProps) {
    const filteredSuggestions = nextProps.suggestions.length
      ? nextProps.suggestions
      : [];
    if (this.state.firstTimeFetch && filteredSuggestions[0]) {
      this.setState({ firstTimeFetch: false, filteredSuggestions: [] }, () =>
        this.props.getSelected(filteredSuggestions[0])
      );
    } else {
      this.setState({ filteredSuggestions });
    }
  }

  onChange = (e) => {
    this.props.search(e.currentTarget.value);

    this.setState({
      activeSuggestion: 0,
      showSuggestions: true,
      hide: false,
      typing: true,
      userInput: e.currentTarget.value,
    });
  };

  onClick = (e, item, activeSuggestion) => {
    this.setState({
      activeSuggestion,
      showSuggestions: false,
      typing: false,
      hide: true,
      userInput: "",
    });
    this.props.getSelected(item);
  };

  onDelete = () => {
    this.setState({
      activeSuggestion: 0,
      showSuggestions: false,
      typing: false,
      hide: true,
      userInput: "",
    });
    this.props.getSelected(null);
  };

  onKeyDown = (e) => {
    const { activeSuggestion, filteredSuggestions } = this.state;
    const { defaultValue, getSelected } = this.props;
    // User pressed the enter key
    if (e.keyCode === 13 && filteredSuggestions.length) {
      e.preventDefault();
      this.setState({
        userInput: "",
        activeSuggestion: activeSuggestion || 0,
        showSuggestions: false,
      });
      getSelected(filteredSuggestions[activeSuggestion || 0]);
    } else if (e.keyCode === 13 && !filteredSuggestions.length) {
      e.preventDefault();
      get(this.props, "includeDiffEmails", false)
        ? getSelected(this.state.userInput)
        : getSelected(filteredSuggestions[activeSuggestion || 0]);
      this.setState({
        userInput: "",
        activeSuggestion: activeSuggestion || 0,
        showSuggestions: false,
      });
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        this.setState({ activeSuggestion }, () =>
          this.scrollList(activeSuggestion)
        );
      } else {
        this.setState({ activeSuggestion: activeSuggestion - 1 }, () =>
          this.scrollList(activeSuggestion - 1)
        );
      }
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (
        activeSuggestion + 1 === filteredSuggestions.length ||
        filteredSuggestions.length === 1
      ) {
        this.setState({ activeSuggestion }, () =>
          this.scrollList(activeSuggestion)
        );
      } else {
        this.setState({ activeSuggestion: activeSuggestion + 1 }, () =>
          this.scrollList(activeSuggestion + 1)
        );
      }
    }
    // User pressed backspace
    else if (e.keyCode === 8) {
      this.setState(
        {
          userInput: defaultValue,
        },
        () => getSelected(null)
      );
    }
  };

  scrollList = (activeSuggestion) => {
    if (document.querySelector(".suggestion-active")) {
      const ul = document.querySelector(".suggestions");
      var elHeight = document.querySelector(".suggestion-active").clientHeight;
      var scrollTop = ul.scrollTop;
      var viewport = scrollTop + ul.clientHeight;
      var elOffset = elHeight * activeSuggestion;
      if (elOffset < scrollTop || elOffset + elHeight > viewport) {
        ul.scrollTop = elOffset;
      }
    }
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      props: {
        disabled,
        isLoading,
        placeholder,
        defaultValue,
        inputClassName,
        inputWrapperClassName,
        error,
      },
      state: {
        activeSuggestion,
        filteredSuggestions,
        firstTimeFetch,
        showSuggestions,
        userInput,
        hide,
      },
    } = this;

    let suggestionsListComponent;
    if (isLoading && !firstTimeFetch) {
      suggestionsListComponent = (
        <div className="no-suggestions">
          <em>{getLocaleString("common.spinner.loading")}</em>
        </div>
      );
    } else if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className={cx("suggestions", { hide: this.state.hide })}>
            {filteredSuggestions
              .filter((x) => x.name)
              .map((suggestion, index) => {
                const className = cx(
                  { "suggestion-active": index === activeSuggestion },
                  [suggestion.type]
                );
                return (
                  <li
                    className={className}
                    key={suggestion.name + index}
                    onClick={(e) => onClick(e, suggestion, index)}
                  >
                    {parse(suggestion.name)}
                  </li>
                );
              })}
          </ul>
        );
      } else if (userInput && !isLoading && !filteredSuggestions.length) {
        suggestionsListComponent = (
          <div className="no-suggestions">
            <em>{getLocaleString("common.noData")}</em>
          </div>
        );
      }
    }
  
    return (
      <ClickAwayListener
        onClickAway={() =>
          this.setState({ showSuggestions: false, hide: true })
        }
      >
        <div
          id="search-autocomplete"
          className={cx("autocomplete inputWithIcon", inputWrapperClassName)}
        >
          <input
            type="text"
            onChange={onChange}
            onFocus={() =>
              this.setState({ hide: false, showSuggestions: true })
            }
            onKeyDown={onKeyDown}
            // value={typing ? undefined: defaultValue}
            value={defaultValue || userInput}
            // defaultValue={defaultValue}
            placeholder={placeholder || ""}
            disabled={disabled}
            style={error ? { border: "1px solid red" } : {}}
            className={cx(
              "inputWithIconInput form-control",
              `${!hide && userInput ? "focused-suggestion" : ""}`,
              inputClassName
            )}
          />
          {defaultValue && !firstTimeFetch ? (
            <i
              onClick={this.onDelete}
              title="Clear"
              className="fa fa-remove cross cursor-pointer"
              aria-hidden="true"
              style={this.props.searchStyle ? this.props.searchStyle : {top: 3}}
            />
          ) : (
            <i
              style={this.props.searchStyle ? this.props.searchStyle : {}}
              className={`fa fa-search search`}
              aria-hidden="true"
            />
          )}
          {suggestionsListComponent}
        </div>
      </ClickAwayListener>
    );
  }
}

export default Autocomplete;
