import { ReducersTypes } from "constants/ReducersTypes";
import { instanceWithRetry } from "actions/axiosInstance";
import { handleAPIError } from "handlers/setters";
import { trimText } from "utils/strings";
import { setuxvalue } from "handlers/ux";
import get from "lodash/get";
import omit from "lodash/omit";
import { generateQueryString } from "utils/query";

const setData = (type, data) => ({
  type,
  payload: {
    data,
  },
});

export const getPaymentsData = (params) => (dispatch) => {
  if(!params.is_export) {
    dispatch(setData(ReducersTypes.PAYMENT_ANALYTICS_LIST_FETCHING, []));
  }
  setuxvalue("ANALYTICS_PAYMENTS_FILTERS", params);
  const URL = `payment${generateQueryString(omit(params, ['customerSelect', 'interval']))}`;
  return instanceWithRetry
    .get(URL)
    .then((response) => {
      if (response && response.data) {
        const finalData = get(response, "data.result", []).map(d => ({ ...d, initiated_date: get(get(d, "session_history", []).find(v => v.status === 'INITIATED'), 'created_date', '')}));
        dispatch(
          setData(ReducersTypes.PAYMENT_ANALYTICS_LIST, {
            ...get(response, "data", {}),
            result: finalData,
            filters: params,
          })
        );
        return finalData;
      }
    })
    .catch((e) => {
      dispatch(
        setData(ReducersTypes.PAYMENT_ANALYTICS_LIST, {
          result: [],
          filters: params,
          total: null
        })
      );
      handleAPIError(e);
      return;
    });
};

export const getRefundTransaction = (id, cb) => dispatch => {
  dispatch(setData(ReducersTypes.PAYMENT_RECEIPT_FETCH, id));
  instanceWithRetry
    .get(`payment?associated_payment_session_id=${id}&per_page=100`)
    .then((res) => {
      cb && cb(res.data.result);
      dispatch(setData(ReducersTypes.PAYMENT_RECEIPT_FETCH, ''));
    })
    .catch((e) => {
      cb && cb([]);
      dispatch(setData(ReducersTypes.PAYMENT_RECEIPT_FETCH, ''));
      handleAPIError(e);
    });
};

export const fetchPaymentsDataByPayoutID = async (payoutID) => {
  try {
    const response = await instanceWithRetry.get(`/payment/payout?payout_id=${payoutID}`);
    return response.data;
  } catch (e) {
    handleAPIError(e);
  }
}

export const getCustomerList = (text, cb) => {
  instanceWithRetry
    .get(`arinvoice/search?customer=${trimText(text)}`)
    .then((res) => {
      cb(res.data.result);
    })
    .catch((e) => {
      cb();
      handleAPIError(e);
    });
};

export const getERPPaymentSettings = (isMutate = false) => instanceWithRetry
  .get("client/config/setting?description=payment setting")
  .catch((e) => handleAPIError(e));

export const getReceipt = (id) => (dispatch) => {
  dispatch(setData(ReducersTypes.PAYMENT_RECEIPT_FETCH, id));
  instanceWithRetry
    .get(`payment/receipt?payment_session_id=${id}`)
    .then((res) => {
      dispatch(setData(ReducersTypes.PAYMENT_RECEIPT_FETCH, ''));
      window.open(res.data.receipt_url, "_blank");
    })
    .catch((e) => {
      dispatch(setData(ReducersTypes.PAYMENT_RECEIPT_FETCH, ""));
      handleAPIError(e);
    });
};

export const initiatePaymentRefund = (requestObj) => {
  return instanceWithRetry
    .post('/easypay?mutate=1', { ...requestObj, payment_type: 'REFUND' })
    .then(() => {
      return true;
    })
    .catch((e) => {
      handleAPIError(e, true);
    });
}

export const initiateWPGPaymentRefund = (requestObj) => instanceWithRetry
  .post('/wpg/refund?mutate=1', requestObj)
  .then(() => {
    return true;
  })
  .catch((e) => {
    handleAPIError(e, true);
  });

export const pushPaymentToERP = (id, requestObj) => instanceWithRetry
  .put(`payment?mutate=1&id=${id}`, requestObj)
  .catch((e) => {
    handleAPIError(e, true);
  });

export const resetPaymentsListReducer = () => (dispatch) => {
  dispatch(setData(ReducersTypes.PAYMENT_ANALYTICS_LIST_RESET, ""));
};

export const setPaymentsListFilter = (data) => (dispatch) => {
  dispatch({
    type: ReducersTypes.PAYMENT_ANALYTICS_LIST_FILTERS, 
    payload: data
  });
}