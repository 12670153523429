import React, {Component} from "react";
import cx from 'classnames';
import parse from 'html-react-parser';
import ClickAwayListener from "components/ClickAwayListener";
import {getLocaleString} from "utils/localization/locale";
import 'assets/scss/autocomplete.scss';
import {emailRegEx} from "../constants/regex";
import {extractEmails, extractRemainingText} from "../utils";

class EmailAutocomplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: "",
      hide: false,
      typing: false,
      firstTimeFetch: false,
      redColor: false,
      escapePressed: false,
    };
  }

  componentDidMount() {
    const { defaultValue, search } = this.props;
    this.setState({
      filteredSuggestions: this.props.suggestions.length ? this.props.suggestions : [],
    });
    if (defaultValue!=="" && defaultValue!==null && defaultValue!=="-")
      this.setState({
        firstTimeFetch: true,
        userInput: defaultValue,
      },() => search(this.state.userInput));
  }

  componentWillReceiveProps(nextProps) {
    const filteredSuggestions = nextProps.suggestions.length ? nextProps.suggestions : [];
    if (this.state.firstTimeFetch && filteredSuggestions[0])
      this.setState({
        firstTimeFetch: false,
        filteredSuggestions: [],
      });
    else
      this.setState({ filteredSuggestions });
  }

  onChange = e => {
    this.props.search(e.currentTarget.value);
    this.setState({
      activeSuggestion: 0,
      showSuggestions: true,
      hide: false,
      typing: true,
      userInput: e.currentTarget.value,
    });
    if (this.state.userInput === "")
      this.setState({
        redColor: false,
        filteredSuggestions: [],
      });
  };

  onClick = (e, item, activeSuggestion) => {
    this.setState({
      activeSuggestion,
      showSuggestions: false,
      typing: false,
      hide: true,
      userInput: "",
      redColor: false,
    });
    this.props.getSelected(item);
  };

  onDelete = () => {
    this.setState({
      activeSuggestion: 0,
      showSuggestions: false,
      typing: false,
      hide: true,
      userInput: "",
    });
    this.props.getSelected(null);
  }

  onPaste = e => {
    e.preventDefault();
    const { getSelectedArray, search } = this.props;
    const { userInput, activeSuggestion } = this.state;
    const clipboardData = e.clipboardData.getData('text/plain');
    const fetchEmails = extractEmails(clipboardData);
    const fetchRemainingText = extractRemainingText(clipboardData);
    if (clipboardData !== null &&clipboardData !== "") {
      getSelectedArray(fetchEmails);
      fetchRemainingText ?
        this.setState({
          userInput: userInput + fetchRemainingText,
          activeSuggestion: activeSuggestion || 0,
        },()=>{
          search(this.state.userInput);
        }):
        this.setState({
          activeSuggestion: activeSuggestion || 0,
          showSuggestions: false,
          redColor: false,
      });
    }
  }

  onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions, userInput, escapePressed } = this.state;
    const { getSelected } = this.props;
    if (e.keyCode === 9 && !userInput) {
      return;
    }
    if (e.keyCode === 13 || e.keyCode === 9 || e.keyCode === 188 || e.keyCode === 32) {
      e.preventDefault();
      if (escapePressed === true){
        if (emailRegEx.test(userInput)) {
          getSelected(userInput);
          this.setState({
            escapePressed: false,
            activeSuggestion: activeSuggestion || 0,
            showSuggestions: false,
            redColor: false,
            userInput: "",
          });
        } else {
          this.setState({ redColor: true });
        }
      } else if ((activeSuggestion !== 0) || (activeSuggestion === 0 && filteredSuggestions.length !== 0)) {
        getSelected(filteredSuggestions[activeSuggestion || 0]);
        this.setState({
          activeSuggestion: activeSuggestion || 0,
          showSuggestions: false,
          redColor: false,
          userInput: "",
        });
      } else if (emailRegEx.test(userInput)) {
        getSelected(userInput);
        this.setState({
          activeSuggestion: activeSuggestion || 0,
          showSuggestions: false,
          redColor: false,
          userInput: "",
        });
      } else {
        this.setState({ redColor: true });
      }
    } else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        this.setState({activeSuggestion}, () => this.scrollList(activeSuggestion))
      } else {
        this.setState({activeSuggestion: activeSuggestion - 1}, () => this.scrollList(activeSuggestion - 1));
      }
    } else if (e.keyCode === 40) {
      if (activeSuggestion + 1 === filteredSuggestions.length || filteredSuggestions.length === 1) {
        this.setState({activeSuggestion}, () => this.scrollList(activeSuggestion))
      } else {
        this.setState({activeSuggestion: activeSuggestion + 1}, () => this.scrollList(activeSuggestion + 1));
      }
    } else if (e.keyCode === 8) {
      if (userInput === "")
        this.props.tryRemove();
    } else if (e.keyCode === 27){
      e.preventDefault();
      this.setState({
        escapePressed: true,
        hide: true,
      })
    }
  };

  scrollList = (activeSuggestion) => {
    if (document.querySelector('.suggestion-active')) {
      const ul = document.querySelector('.suggestions');
      let elHeight = document.querySelector('.suggestion-active').clientHeight;
      let scrollTop = ul.scrollTop;
      let viewport = scrollTop + ul.clientHeight;
      let elOffset = elHeight * activeSuggestion;
      if (elOffset < scrollTop || (elOffset + elHeight) > viewport) {
        ul.scrollTop = elOffset;
      }
    }
  }

  onClickAway(){
    const {getSelected} = this.props;
    const {userInput, activeSuggestion} = this.state;
    this.setState({
      escapePressed: false,
      showSuggestions: false,
      hide: true,
    });
    if (emailRegEx.test(userInput)) {
      getSelected(userInput);
      this.setState({
        activeSuggestion: activeSuggestion || 0,
        redColor: false,
        userInput: "",
      });
    } else {
      this.setState({
        redColor: true,
      });
    }
  }

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      onPaste,
      props: {
        disabled,
        isLoading,
        placeholder,
        inputClassName,
        inputWrapperClassName,
      },
      state: {
        activeSuggestion,
        filteredSuggestions,
        firstTimeFetch,
        showSuggestions,
        userInput,
      }
    } = this;
    let suggestionsListComponent;
    if (isLoading && !firstTimeFetch) {
      suggestionsListComponent = (
        <div className="no-suggestions">
          <em>{getLocaleString("common.spinner.loading")}</em>
        </div>
      );
    } else if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul style={{width: 320, marginTop: 9}} className={cx('suggestions', {hide: this.state.hide})}>
            {filteredSuggestions.map((suggestion, index) => {
              const className = cx({"suggestion-active": index === activeSuggestion}, [suggestion.type]);
              return (
                <li className={className} key={suggestion} onClick={(e) => onClick(e, suggestion, index)}>
                  {parse(suggestion)}
                </li>
              );
            })}
          </ul>
        );
      } else if (userInput && !isLoading && !filteredSuggestions.length) {
        suggestionsListComponent = (
          <div style={{width: 320}} className="no-suggestions">
            <em>{getLocaleString("common.noData")}</em>
          </div>
        );
      }
    }

    return (
      <ClickAwayListener onClickAway={() => this.onClickAway()}>
        <div id="search-autocomplete" className={cx("autocomplete position-relative", inputWrapperClassName)} data-testid="autocomplete">
          <input
            type="search"
            data-cy={`Enter email address${this.props.tabIndex}`}
            tabIndex={this.props.tabIndex}
            style={{border: "none", fontSize: 14, color: this.state.redColor ? "red" : "black"}}
            onChange={onChange}
            onFocus={() => this.setState({hide: false, showSuggestions: !firstTimeFetch})}
            onKeyDown={onKeyDown}
            onPaste={onPaste}
            value={userInput}
            placeholder={placeholder || ''}
            disabled={disabled}
            className={cx("multiemail-input", inputClassName)}
          />
          {suggestionsListComponent}
        </div>
      </ClickAwayListener>
    );
  }
}

export default EmailAutocomplete;
