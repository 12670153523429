import moment from "moment";
import { ReducersTypes } from "constants/ReducersTypes";
import { getUXSettingsFromStorage } from "utils";
import { getDateByRangeInterval } from "utils/dates";

let savedFilters = getUXSettingsFromStorage("ANALYTICS_EMAIL_FILTERS");
if (savedFilters && savedFilters.interval) {
  const { interval, from_date, to_date } = savedFilters;
  const [sd, ed] = getDateByRangeInterval(interval, from_date, to_date);
  savedFilters = {
    ...savedFilters,
    from_date: sd,
    to_date: ed,
  };
}

export const initialEmailListFilters = {
  page: 1,
  status: "",
  to_date: moment().format("MM/DD/YYYY"),
  sort_by: "sent_at",
  interval: "",
  from_date: moment().subtract(365, "days").format("MM/DD/YYYY"),
  sort_order: "desc",
  assignedTo: "user",
  customerSelect: null,
};

const initialState = {
  emailList: [],
  senderList: [],
  exportedData: [],
  isFetching: false,
  filters: {
    ...initialEmailListFilters,
    ...savedFilters,
  },
};

const emailLogList = (state = initialState, action) => {
  switch (action.type) {
    case ReducersTypes.SET_EMAIL_REPORT_LIST_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case ReducersTypes.SET_EMAIL_REPORT_LIST_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };

    case ReducersTypes.RESET_EMAIL_REPORT_LIST_FILTERS:
      return {
        ...state,
        filters: {
          ...initialEmailListFilters,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};

export default emailLogList;
