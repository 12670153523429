/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { instanceMultipart, instanceWithRetry } from "actions/axiosInstance";
import { resetemail } from "components/Email/EmailCreator";
import Toaster from "components/Toaster";
import { ReducersTypes } from "constants/ReducersTypes";
import { b64toBlob } from "handlers/getters";
import { handleAPIError } from "handlers/setters";
import get from "lodash/get";
import omit from "lodash/omit";
import { store } from "store";
import AsyncSelect from "react-select/async";
import { getPastAndFutureDates, Today, Yesterday } from "utils/dates";
import TokenManager from "utils/TokenManager";
import { generateQueryString } from "utils/query";
import { sessionRegExString } from "constants/regex";
import {
  openNewTab,
  getEnabledKeysValue,
  removeEmptyValueFromObject,
} from "utils";
import {
  enableEasyPayPG,
  enableWorldPayPG,
  enableCustomerInvoiceRestrictedCreation,
} from "handlers/features";
import { getuxvalue } from "handlers/ux";
import { sendExportedReport } from "actions/global";
import { initialCustomerDetailsFilters } from "../reducer";
import { getLocaleString } from "utils/localization/locale";

const account_type = get(TokenManager.getPaymentAccountTypeData(), "account_type", "");
const isStripe = ["STANDARD", "EXPRESS", "STRIPE"].includes(account_type);

export const initCustomerDetails = (client_customer_rel_id, showPaidFeature) => {
  setCustomerDetails({
    client_customer_rel_id,
    showPaidFeature,
    initiated: true,
  });
  // 1st step APIs
  try {
    getcustomerRelData(client_customer_rel_id);
    getCustomerCategory();
    if (!TokenManager.isCustomer()) {
      getHeirarchyData(client_customer_rel_id);
      getCustomFieldFilters();
      getARPaymentHistoryFilters();
    }
  } catch (e) {
    console.log("e", e);
  }
};

export const getHeirarchyData = async (client_customer_rel_id) => {
  const flattendHirerchy = [];
  const modifyHirerchyData = (data) => {
    if (data.active) {
      flattendHirerchy.push(data);
      if (data.child_customer_rels.length) {
        data.child_customer_rels.forEach((d) => modifyHirerchyData(d));
      }
    }
  };
  instanceWithRetry
    .get(`customer/hierarchy?id=${client_customer_rel_id}`)
    .then((response) => {
      const data = get(response, "data", null);
      modifyHirerchyData(data);
      const modifyHirerchy =
        flattendHirerchy.length === 1
          ? flattendHirerchy
          : flattendHirerchy.map((d, i) => {
              if (i !== flattendHirerchy.length - 1) {
                return {
                  ...d,
                  active: false,
                  child_customer_rels: [],
                };
              }
              return d;
            });
      setCustomerDetails({
        heirarchy:
          data && data.child_customer_rels.length ? modifyHirerchy : [],
      });
    })
    .catch((e) => handleAPIError(e));
};

export const getCustomerCategory = () => {
  instanceWithRetry
    .get("customercategory?per_page=10000")
    .then((response) => {
      setCustomerDetails({
        categoryList: response.data.result.sort((a, b) =>
          (a.name || "").toLowerCase() > (b.name || "").toLowerCase() ? 1 : -1
        ),
      });
    })
    .catch((e) => handleAPIError(e));
};

const getAllLinkdedBanks = (client_customer_rel_id) => {
  if (!isStripe || (TokenManager.getOldRole() || !TokenManager.isCustomer())) {
    const url = `payment/account${generateQueryString({ account_type : isStripe ? "" : account_type })}`;
    instanceWithRetry
      .get(url)
      .then((resp) => {
        const allLinkedAccounts = get(resp, "data.result", []);
        const {
          customerRelData: { payment_account_id },
        } = getcustomerDetailsFromStore();
        const showPaymentBtn =
          allLinkedAccounts.length &&
          allLinkedAccounts.some(
            (d) =>
              d.id === Number(payment_account_id) &&
              d.is_account_charges_enabled
          );
        setCustomerDetails({
          showPaymentButton: showPaymentBtn,
          allLinkedAccounts,
        });
      })
      .catch((e) => handleAPIError(e));
  } else {
    instanceWithRetry
      .get(`stripe/account?client_customer_rel_id=${client_customer_rel_id}`)
      .then((res) => {
        setCustomerDetails({
          showPaymentButton:
            res.data.length &&
            res.data[0].is_active &&
            res.data[0].is_account_charges_enabled,
        });
      })
      .catch((e) => handleAPIError(e));
  }
};

export const checkCardPaymentStatus = (sessionId, history) => {
  var isMicro = false;
  history.replace(history.pathname);
  if (sessionId && sessionId[2]) {
    instanceWithRetry
      .get(`stripe/checkout?session_id=${sessionId[2]}`)
      .then((res) => {
        if (get(res, "data.result.latest_status", "") === "INITIATED")
          Toaster(
            getLocaleString(
              "customer_details.your_payment_is_initiated_successfully",
              "Your payment is initiated successfully"
            ),
            "success"
          );
        else if (get(res, "data.result.latest_status", "") === "FAILED")
          Toaster(
            getLocaleString(
              "customer_details.action_cancelled_payment_not_completed",
              "Action cancelled - payment not completed"
            ),
            "error"
          );
        else if (
          get(res, "data.result.session_history", []).some(
            (d) =>
              d.status === "PAID" ||
              (d.status === "PROCESSING" ? (isMicro = true) : false)
          )
        ) {
          isMicro
            ? Toaster(
                getLocaleString(
                  "customer_details.your_payment_is_processed_successfully",
                  "Your payment is processed successfully"
                ),
                "success"
              )
            : Toaster(
                getLocaleString(
                  "common.your_payment_was_successful",
                  "Your payment was successful"
                ),
                "success"
              );
          if (get(res, "data.result.receipt_url", "")) {
            openNewTab(get(res, "data.result.receipt_url", ""), "_blank");
          }
        }
      })
      .catch((e) => {
        handleAPIError(e);
      });
  }
};

export const registerNewCard = (sessionId, history) => {
  if (sessionId && sessionId[2]) {
    setAutoPayment(
      {
        session_id: sessionId[2],
      },
      true
    );
  }
  history.replace(`${history.location.pathname}#accounts`);
};

export const registerNewMicroDeposit = (sessionId, history) => {
  if (sessionId && sessionId[2]) {
    setAutoPayment(
      {
        session_id: sessionId[2],
      },
      true,
      "micro"
    );
  }
  history.replace(`${history.location.pathname}#accounts`);
};

export const getEmailTemplates = () =>
  instanceWithRetry.get("arworkflow/template").then((response) => {
    setCustomerDetails({
      emailTemplateData: get(response, "data.result", []),
    });
  });

export const getcustomerRelData = async (
  client_customer_rel_id,
  noReload = false
) => {
  const rel_response = await instanceWithRetry.get(
    `/customer/rel${generateQueryString({
      id: client_customer_rel_id,
      mutate: noReload ? 1 : "",
    })}`
  );
  if (rel_response) {
    // setdata
    const customerId = get(
      rel_response,
      "data.result[0].associated_customer.id",
      ""
    );
    if (!get(rel_response, "data.result", []).length) {
      window.location.href = `${window.location.origin}/customers`;
    }
    setCustomerDetailsId(customerId);
    setCustomerRelData(get(rel_response, "data.result[0]", ""));
    setCustomerDetails({
      ar_workflow_id: get(
        rel_response,
        "data.result[0].ar_workflows[0].id",
        ""
      ),
    });
    getCustomerOriginalData(client_customer_rel_id, noReload);
    if (!noReload) {
      getAllLinkdedBanks(client_customer_rel_id);
      getClientData();
      if (enableEasyPayPG()) {
        getEasyPayPaymentMethod(client_customer_rel_id);
      } else if (account_type !== "INTERNAL") {
        getStoredCards();
        getStoredBanks();
        getStoredMicroAccounts();
      }
    }
    const sessionId = new RegExp(sessionRegExString).exec(window.location.href);
    if (!sessionId) {
      if (!noReload) {
        getChartValues();
      } else if (typeof noReload !== "undefined") {
        getInvoiceData({ force: true });
      }
      !TokenManager.isCustomer() && getEmailTemplates();
    }
  }
};

export const getCustomerOriginalData = async (
  client_customer_rel_id,
  mutate
) => {
  instanceWithRetry
    .get(
      `customer${generateQueryString({
        client_customer_rel_id,
        mutate: mutate ? 1 : "",
      })}`
    )
    .then((responses) => {
      setCustomerDetails({
        customerData: get(responses, "data", ""),
        initiated: false,
      });
    })
    .catch((e) => {
      handleAPIError(e);
    });
};

export const updateCustomerDetail = (postData, hideNotie) => {
  const { client_customer_rel_id } = getcustomerDetailsFromStore();
  instanceWithRetry
    .put(
      `customer${generateQueryString({ client_customer_rel_id, mutate: 1 })}`,
      postData
    )
    .then(() => {
      getCustomerOriginalData(client_customer_rel_id);
      if (!hideNotie) {
        Toaster(
          `${getLocaleString("common.auto_pay", "Auto pay")} ${
            postData.auto_payment_enabled
              ? getLocaleString("common.enable", "enabled")
              : getLocaleString("common.disabled", "disabled")
          } ${getLocaleString("common.successfully", "successfully")}`,
          "success"
        );
      }
    })
    .catch((e) => handleAPIError(e));
};

export const getClientData = async () => {
  const { clientDetails } = store.getState();
  setCustomerDetails({
    clientData: get(clientDetails, "clientData.payment_settings", null),
    clientSettings: get(clientDetails, "clientData.client_settings", null),
  });
};

export function getCustomerReminder() {
  // to be cleanup later
}

export async function getInvoiceData(props, isExport, chartData) {
  const {
    filter: {
      page,
      sort_by,
      to_date,
      tag_ids,
      per_page,
      from_date,
      sort_order,
      not_tag_ids,
      invoiceSelect,
      selectedSection,
      appliedCustomFilters,
    },
    activeKey,
    prevUrl,
    initiated,
    selectedRows,
    customFilters,
    showPaidFeature,
    isFetchingArInvoice,
    isMixedInvoiceCurrency,
    client_customer_rel_id,
  } = getcustomerDetailsFromStore();

  const currentSelectedSection = chartData
    ? get(chartData, "filter.selectedSection", null)
    : selectedSection;

  if (
    (isFetchingArInvoice && !chartData) ||
    typeof initiated === "undefined" ||
    activeKey === "#payments"
  ) {
    return;
  }
  const isCreditMemo = props?.invoice_type === "CREDIT_MEMO";
  const [past_dates, future_dates] = getPastAndFutureDates(from_date, to_date);
  let filterObject = {
    page,
    sort_by: get(chartData, "is_mixed_currency", false)
      ? sort_by && sort_by.replace("foreign_", "")
      : sort_by,
    tag_ids: tag_ids.join(),
    per_page,
    is_export: isExport ? "1" : "",
    sort_order,
    invoice_id:
      isCreditMemo ||
      get(invoiceSelect, "client_customer_rel_id", "") !==
        client_customer_rel_id
        ? ""
        : get(invoiceSelect, "name", ""),
    mutate: props?.force ? 1 : "",
    not_tag_ids: not_tag_ids.join(),
    invoice_type: isCreditMemo ? props?.invoice_type : "",
    client_customer_rel_id,
    is_customer_invoice_restricted_role:
      enableCustomerInvoiceRestrictedCreation() && TokenManager.isCustomer()
        ? TokenManager.isCustomerInvoiceRestricted()
          ? "1"
          : "0"
        : "",
  };
  if (TokenManager.getOldRole() && TokenManager.getOldRole() !== "customer") {
    filterObject.is_customer_role = 1;
  }
  if (appliedCustomFilters && Object.keys(appliedCustomFilters).length) {
    let masterId = [];
    Object.keys(appliedCustomFilters).forEach((d) => {
      const filter = appliedCustomFilters[d];
      let flag = false;
      Object.keys(filter).forEach((f) => {
        if (f !== "custom_field_master_id" && filter[f]) {
          flag = true;
          filterObject[f] = filter[f];
        }
      });
      if (flag) {
        masterId.push(d);
        flag = false;
      }
    });
    if (masterId.length) {
      filterObject.custom_field_master_id = masterId.join();
    }
  }
  switch (currentSelectedSection?.name) {
    case "Overdue": {
      filterObject = {
        ...filterObject,
        status: "open",
        from_date: past_dates[0],
        to_date: Yesterday,
      };
      break;
    }
    case "Balance":
    case "Current": {
      filterObject = {
        ...filterObject,
        status: "open",
        from_date: Today,
        to_date: future_dates[1],
      };
      break;
    }
    case "Paid": {
      filterObject = {
        ...filterObject,
        status: "closed",
        from_date: from_date,
        to_date,
      };
      break;
    }
    case "Total Invoice Balance": {
      filterObject = {
        ...filterObject,
        status: "open",
        from_date: past_dates[0] || Today,
        to_date: future_dates[1] || Yesterday,
      };
      break;
    }
    default: {
      filterObject = {
        ...filterObject,
        status: !(showPaidFeature || isCreditMemo) ? "open" : "all",
        from_date,
        to_date,
      };
      break;
    }
  }

  if (
    !isCreditMemo &&
    filterObject.status === "open" &&
    filterObject.sort_by === "amount_closed"
  ) {
    filterObject.sort_by = "total_amount_due";
  }

  // call API
  let URL = `arinvoice${generateQueryString(filterObject)}`;

  const _columns = getuxvalue("CUSTOMER_INVOICE_DETAILS_COLUMNS");
  const ids = await getEnabledKeysValue(customFilters, _columns);

  if (ids.length || enableCustomerInvoiceRestrictedCreation()) {
    URL = `${URL}${
      isExport ? `&send_custom_field_master_id=${ids}` : "&send_custom_fields=1"
    }`;
  }

  if (isExport) {
    if (get(props, "isEmailExport", false)) {
      return sendExportedReport(
        removeEmptyValueFromObject({
          ...filterObject,
          export_type: "csv",
          page_type: "ar_invoice",
        })
      );
    }
    return instanceWithRetry.get(URL);
  }
  if (prevUrl === URL && !props?.force) {
    return;
  }
  setCustomerDetails({ isFetchingArInvoice: true });
  instanceWithRetry.get(URL).then((resp) => {
    setCustomerDetails({
      invoiceList: get(resp, "data.result", []),
      prev_num: get(resp, "data.prev_num", null),
      next_num: get(resp, "data.next_num", null),
      totalRecords: get(resp, "data.total", 0),
      isFetchingArInvoice: false,
      prevUrl: URL,
      selectedRows: props?.force ? [] : selectedRows,
      isMixedInvoiceCurrency:
        isMixedInvoiceCurrency || get(chartData, "is_mixed_currency", false),
      ...chartData,
    });
  });
}

export async function updateNotesData(data) {
  setCustomerDetails({ notesData: data });
}

export function getCreditMemoData() {
  getInvoiceData({ invoice_type: "CREDIT_MEMO" });
}

export function getPaymentsData(chartData) {
  const {
    filter: {
      page,
      to_date,
      sort_by,
      per_page,
      from_date,
      sort_order,
      selectedSection,
      appliedARPCustomFilters,
    },
    client_customer_rel_id,
    prevUrl,
    isFetchingArInvoice,
  } = getcustomerDetailsFromStore();
  const currentSelectedSection = chartData
    ? get(chartData, "filter.selectedSection", {})
    : selectedSection;
  if (isFetchingArInvoice && !chartData) {
    return;
  }

  let filterObject = {
    send_custom_fields: 1,
    client_customer_rel_id,
    page,
    per_page,
    sort_by,
    sort_order,
    from_date,
    to_date,
    is_customer_invoice_restricted_role:
      enableCustomerInvoiceRestrictedCreation() && TokenManager.isCustomer()
        ? TokenManager.isCustomerInvoiceRestricted()
          ? "1"
          : "0"
        : "",
    status:
      currentSelectedSection?.parsed_name === "Applied"
        ? "applied"
        : currentSelectedSection?.parsed_name === "Unapplied"
        ? "unapplied"
        : "",
  };

  if (appliedARPCustomFilters && Object.keys(appliedARPCustomFilters).length) {
    let masterId = [];
    Object.keys(appliedARPCustomFilters).forEach((d) => {
      const filter = appliedARPCustomFilters[d];
      let flag = false;
      Object.keys(filter).forEach((f) => {
        if (f !== "custom_field_master_id" && filter[f]) {
          flag = true;
          filterObject[f] = filter[f];
        }
      });
      if (flag) {
        masterId.push(d);
        flag = false;
      }
    });
    if (masterId.length) {
      filterObject.custom_field_master_id = masterId.join();
    }
  }

  const URL = `arpaymenthistory${generateQueryString(filterObject)}`;
  // call API
  if (prevUrl === URL && !chartData?.force) {
    return;
  }
  setCustomerDetails({ isFetchingArInvoice: true });
  instanceWithRetry.get(URL).then((resp) => {
    setCustomerDetails({
      invoiceList: get(resp, "data.result", []).map((x) => ({
        ...x,
        invoice_number: get(x, "invoice.invoice_number", ""),
      })),
      prev_num: get(resp, "data.prev_num", null),
      next_num: get(resp, "data.next_num", null),
      totalRecords: get(resp, "data.total", 0),
      isFetchingArInvoice: false,
      selectedRows: [],
      prevUrl: URL,
      ...chartData,
    });
  });
}

export const getCreditSummary = () => {
  const {
    filter: { from_date, to_date },
    client_customer_rel_id,
  } = getcustomerDetailsFromStore();
  const requObj = {
    client_customer_rel_id,
    from_date,
    to_date,
    is_customer_invoice_restricted_role:
      enableCustomerInvoiceRestrictedCreation() && TokenManager.isCustomer()
        ? TokenManager.isCustomerInvoiceRestricted()
          ? "1"
          : "0"
        : "",
  };
  Promise.all([
    instanceWithRetry.get(
      `arinvoice/total${generateQueryString(
        requObj
      )}&invoice_type=CREDIT_MEMO&status=open`
    ),
    instanceWithRetry.get(
      `arpaymenthistory/total${generateQueryString(requObj)}&status=unapplied`
    ),
  ])
    .then((responses) => {
      setCustomerDetails({
        unappliedPayment: get(responses, "[1].data.total", 0),
        unappliedCredit: get(responses, "[0].data.total", 0),
      });
    })
    .catch((e) => handleAPIError(e));
};

export function getCreditMemoExportData() {
  return getInvoiceData({ invoice_type: "CREDIT_MEMO" });
}

export function onEmailSave(
  to,
  subject,
  body,
  files = [],
  cc,
  pushToERP = false
) {
  const { customerId, selectedRows, client_customer_rel_id } =
    getcustomerDetailsFromStore();

  let postData = {
    to,
    subject,
    message: body,
    cc,
    invoice_id: get(selectedRows, "[0].id", undefined),
    customer_id: customerId,
    client_customer_rel_id,
  };
  if (pushToERP) {
    postData.is_manual_reminder_or_statement_email = 1;
  }

  if (!customerId) {
    postData = omit(postData, [
      "invoice_id",
      "customer_id",
      "client_customer_rel_id",
    ]);
    postData["from"] = "notification@payference.com";
  }

  let bodyFormData = new FormData();
  bodyFormData.set("metadata", JSON.stringify(postData));
  files.forEach((file) => {
    if (file.isbase64) {
      bodyFormData.append(
        "files[]",
        new File(
          [b64toBlob(decodeURIComponent(JSON.parse(file.content)))],
          file.name
        ),
        file.name
      );
    } else {
      bodyFormData.append("files[]", file, file.name);
    }
  });

  const obj = {};
  if (customerId) {
    obj.customer_id = customerId;
  }
  if (pushToERP) {
    obj.is_manual_reminder_or_statement_email = 1;
  }
  obj.mutate = 1;
  const URL = `sendemail${generateQueryString(obj)}`;
  instanceMultipart
    .post(URL, bodyFormData)
    .then(() => {
      Toaster(
        `${getLocaleString(
          "common.an_email_was_sent_to",
          "An email was sent to"
        )} ${to}`,
        "success"
      );
      resetemail();
      setCustomerDetails({
        notesUpdated: true,
        selectedRows: [],
      });
      // if(customerId) {
      //   instanceWithRetry
      //   .put(`/customer/note`, {
      //     note: `An email was sent to ${to} \n Subject: ${subject}`,
      //     client_customer_rel_id,
      //   })
      //   .then(() => {
      //     resetemail();
      //     setCustomerDetails({
      //       notesUpdated: true,
      //       selectedRows: [],
      //     });
      //   });
      // } else {
      //   resetemail();
      // }
    })
    .catch((e) => handleAPIError(e));
}

export async function resetFilter() {
  resetCustomerDetailsFilter();
}

export async function ExportCSV() {
  return [];
}

export function changePaymentSettings(postData, invId) {
  const { selectedRows } = getcustomerDetailsFromStore();
  const allpromises = invId
    ? [instanceWithRetry.put(`/arinvoice?id=${invId}&mutate=1`, postData)]
    : selectedRows
        .filter((d) => get(d, "status", "") !== "Pending")
        .map((d) =>
          instanceWithRetry.put(`/arinvoice?id=${d.id}&mutate=1`, postData)
        );
  Promise.all(allpromises)
    .then(() => {
      getInvoiceData({ force: true });
      Toaster(
        getLocaleString(
          "common.updated_payment_settings",
          "Updated payment settings"
        ),
        "success"
      );
    })
    .catch((e) => handleAPIError(e));
}

// Chart
export function getChartData(type) {
  const types = { Paid: 0, Balance: 1, Overdue: 2, Total: 3 };
  const {
    balance = 0,
    overdue = 0,
    total_balance = 0,
    last12MonthPaid = 0,
  } = getcustomerDetailsFromStore();
  const result = [
    {
      name: `${getLocaleString(
        "common.paid",
        "Paid"
      )}`,
      parsed_name: "Applied",
      value: Math.abs(last12MonthPaid || 0),
      original_value: last12MonthPaid || 0,
      dataindex: 0,
      urlparams: `&status=closed`,
    }, //paid
    {
      name: `${getLocaleString(
        "dashboard.current",
        "Current"
      )}`, // Balance
      value: Math.abs(balance || 0),
      dataindex: 1,
      urlparams: `&status=open&type=balance`,
    }, //balance
    {
      name:`${getLocaleString(
        "customer.overdue",
        "Overdue"
      )}`,
      value: Math.abs(overdue || 0),
      dataindex: 2,
      urlparams: `&status=open&type=overdue`,
    }, //overdue
    {
      name: `${getLocaleString(
                    "vendor.total_invoice_balance",
                    "Total Invoice Balance"
                  )}`,
      parsed_name: "Unapplied",
      value: Math.abs(total_balance || 0),
      original_value: total_balance || 0,
      dataindex: [1, 2],
      urlparams: `&status=open&type=total_balance`,
    }, //total_balance
  ];
  if (type) {
    return result[types[type]];
  }
  return result;
}

export function getPaymentChartData(type) {
  const types = { Paid: 0, Balance: 1, Overdue: 2 };
  const {
    applied = 0,
    balance = 0,
    overdue = 0,
    unapplied = 0,
  } = getcustomerDetailsFromStore();

  const result = [
    {
      name: "Paid",
      parsed_name: "Applied",
      value: Math.abs(applied || 0),
      original_value: applied || 0,
      dataindex: 0,
      urlparams: `&status=applied`,
      // urlparams: `&status=closed&from_date=${todayMinusYear}&to_date=${today}`,
    }, //paid
    {
      name: "Current", // Balance
      value: Math.abs(balance || 0),
      dataindex: 1,
      urlparams: `&status=open&type=balance`,
    }, //balance
    {
      name: "Overdue",
      value: Math.abs(overdue || 0),
      dataindex: 2,
      urlparams: `&status=open&type=overdue`,
    }, //overdue
    {
      name: "Total balance", // Balance
      parsed_name: "Unapplied",
      value: Math.abs(unapplied || 0),
      original_value: unapplied || 0,
      dataindex: [1, 2],
      urlparams: `&status=open&type=unapplied`,
    }, //total_balance
  ];
  if (type) {
    return result[types[type]];
  }
  return result;
}

export function getCustomFieldFilters() {
  instanceWithRetry
    .get("/customfields?model_name=ARInvoiceHeader")
    .then(async (res) => {
      const result = res.data.result;
      const allPromises = result
        .filter((d) => d.field_type === "STRING")
        .map(async (d) => {
          return {
            id: d.id,
            data: await instanceWithRetry.get(
              `/customfield${generateQueryString({
                custom_field_master_id: d.id,
                per_page: 10,
              })}`
            ),
          };
        });
      const masterFieldValue = await Promise.allSettled(allPromises).then(
        (response) => {
          return response.reduce(
            (acc, d) => ({
              ...acc,
              [get(d, "value.id", "")]: get(d, "value.data.data.result", [])
                .filter((d) => d)
                .map((d) => ({ label: d, value: d })),
            }),
            {}
          );
        }
      );
      setCustomerDetails({
        customFilters: result,
        masterFieldValue,
      });
    })
    .catch((e) => handleAPIError(e));
}

export const renderAsyncSearch = (data) => {
  const {
    appliedCustomFilters,
    masterFieldValue,
    setAppliedCustomFilters,
    searchCustomFieldValue,
  } = data;
  const defaultValue = get(
    appliedCustomFilters,
    `[${data.id}][erp_field_string_value_${data.id}]`,
    ""
  );
  let defaultOptions = get(masterFieldValue, `${data.id}`, []);

  /* If there are options to show, then add a special option '-' which is used to 
     filter records with the given custom field not set*/
  if (defaultOptions.length > 0) {
    defaultOptions = [{ label: "-", value: "-" }, ...defaultOptions];
  }
  return (
    <AsyncSelect
      isClearable={true}
      cacheOptions
      placeholder={`${getLocaleString("vendor.search_for", "Search for")} ${get(
        data,
        "erp_field_name",
        ""
      )}`}
      defaultOptions={defaultOptions}
      loadOptions={(text, callback) =>
        searchCustomFieldValue(
          {
            search_text: text,
            per_page: 10,
            custom_field_master_id: get(data, "id", ""),
          },
          callback
        )
      }
      menuPlacement="auto"
      value={defaultValue ? [{ label: defaultValue, value: defaultValue }] : ""}
      onChange={(option) => {
        setAppliedCustomFilters({
          ...appliedCustomFilters,
          [data.id]: {
            [`erp_field_string_value_${data.id}`]: option ? option.value : "",
            custom_field_master_id: data.id,
          },
        });
      }}
    />
  );
};

export const searchInvoice = (text, client_customer_rel_id) => {
  if (!text) return Promise.resolve();
  const url = `arinvoice/search${generateQueryString({
    client_customer_rel_id,
    invoice: text,
  })}`;
  return instanceWithRetry.get(url).catch((e) => handleAPIError(e));
};

export function getARPaymentHistoryFilters() {
  instanceWithRetry
    .get("/customfields?model_name=ARPaymentHistory")
    .then(async (res) => {
      const result = res.data.result;
      const allPromises = result
        .filter((d) => d.field_type === "STRING")
        .map(async (d) => {
          return {
            id: d.id,
            data: await instanceWithRetry.get(
              `/customfield${generateQueryString({
                custom_field_master_id: d.id,
                per_page: 10,
              })}`
            ),
          };
        });
      const masterFieldValue = await Promise.allSettled(allPromises).then(
        (response) => {
          return response.reduce(
            (acc, d) => ({
              ...acc,
              [get(d, "value.id", "")]: get(d, "value.data.data.result", [])
                .filter((d) => d)
                .map((d) => ({ label: d, value: d })),
            }),
            {}
          );
        }
      );
      setCustomerDetails({
        arPaymentCustomFilters: result,
        masterFieldValueARPayment: masterFieldValue,
      });
    })
    .catch((e) => handleAPIError(e));
}

export function getChartValues(props) {
  const customerDetails = getcustomerDetailsFromStore();
  const {
    filter: { from_date, to_date, tag_ids, not_tag_ids },
    client_customer_rel_id,
    showPaidFeature,
    initiated,
    fetchingChartData,
    activeKey,
  } = customerDetails;
  if (
    fetchingChartData ||
    typeof initiated === "undefined" ||
    activeKey === "#payments"
  ) {
    return;
  }

  const [past_dates, future_dates] = getPastAndFutureDates(from_date, to_date);
  let data = {
    filters: [
      {
        from_date: past_dates[0],
        to_date: past_dates[1],
        label_name: "overdue",
      },
      {
        from_date: future_dates[0],
        to_date: future_dates[1],
        label_name: "balance",
      },
      {
        from_date: past_dates[0] || future_dates[0],
        to_date: future_dates[1] || past_dates[1],
        label_name: "total_balance",
      },
    ].filter((x) => x.from_date || x.to_date),
  };

  const commonFilters = {
    status: "open",
    client_customer_rel_id,
    tag_ids: tag_ids.join(),
    not_tag_ids: not_tag_ids.join(),
    is_customer_invoice_restricted_role:
      enableCustomerInvoiceRestrictedCreation() && TokenManager.isCustomer()
        ? TokenManager.isCustomerInvoiceRestricted()
          ? "1"
          : "0"
        : "",
    invoice_type:
      props?.invoice_type || activeKey === "#credit_memo" ? "CREDIT_MEMO" : "",
  };

  if (TokenManager.getOldRole() && TokenManager.getOldRole() !== "customer") {
    commonFilters.is_customer_role = 1;
  }

  const omitBlankKeys = Object.keys(commonFilters).filter(
    (k) => !commonFilters[k]
  );

  data.filters = data.filters.map((d) =>
    removeEmptyValueFromObject(omit({ ...d, ...commonFilters }, omitBlankKeys))
  );

  setCustomerDetails({
    fetchingChartData: true,
    invoiceList: [],
    isFetchingArInvoice: true,
  });

  Promise.all([
    instanceWithRetry.post(`arinvoice/grouped`, data),
    instanceWithRetry.get(
      `arinvoice/total${generateQueryString({
        client_customer_rel_id,
        from_date,
        to_date,
        invoice_type: props?.invoice_type,
        status: "closed",
        is_customer_role: commonFilters.is_customer_role,
        is_customer_invoice_restricted_role:
          enableCustomerInvoiceRestrictedCreation() && TokenManager.isCustomer()
            ? TokenManager.isCustomerInvoiceRestricted()
              ? "1"
              : "0"
            : "",
      })}`
    ),
  ])
    .then((responses) => {
      const {
        balance = 0,
        overdue = 0,
        total_balance = 0,
        aggregate_credit = 0,
      } = get(responses, "[0].data.result[0]", {});

      const { is_mixed_currency = false } = get(responses, "[0].data", {});
      const last12MonthPaid =
        showPaidFeature || props?.invoice_type === "CREDIT_MEMO"
          ? get(responses, "[1].data.total", 0)
          : 0;

      const legend =
        props?.invoice_type === "CREDIT_MEMO" && !balance && !overdue
          ? ""
          : showPaidFeature && !total_balance
          ? "Paid"
          : "Total";

      getInvoiceData(props, "", {
        balance,
        overdue,
        credit: aggregate_credit,
        total_balance,
        last12MonthPaid,
        fetchingChartData: false,
        is_mixed_currency,
        filter: {
          ...customerDetails.filter,
          selectedSection: legend ? getChartData(legend) : null,
        },
      });
    })
    .catch((e) => {
      setCustomerDetails({
        fetchingChartData: false,
        isFetchingArInvoice: false,
      });
      handleAPIError(e);
    });
}

export function getPaymentChartValues() {
  const customerDetails = getcustomerDetailsFromStore();
  const {
    filter: { from_date, to_date, appliedARPCustomFilters },
    client_customer_rel_id,
    fetchingChartData,
  } = customerDetails;
  if (fetchingChartData) {
    return;
  }
  setCustomerDetails({
    fetchingChartData: true,
    invoiceList: [],
    isFetchingArInvoice: true,
  });

  const filterObject = {
    client_customer_rel_id,
    from_date,
    to_date,
    is_customer_invoice_restricted_role:
      enableCustomerInvoiceRestrictedCreation() && TokenManager.isCustomer()
        ? TokenManager.isCustomerInvoiceRestricted()
          ? "1"
          : "0"
        : "",
  };
  if (appliedARPCustomFilters && Object.keys(appliedARPCustomFilters).length) {
    let masterId = [];
    Object.keys(appliedARPCustomFilters).forEach((d) => {
      const filter = appliedARPCustomFilters[d];
      let flag = false;
      Object.keys(filter).forEach((f) => {
        if (f !== "custom_field_master_id" && filter[f]) {
          flag = true;
          filterObject[f] = filter[f];
        }
      });
      if (flag) {
        masterId.push(d);
        flag = false;
      }
    });
    if (masterId.length) {
      filterObject.custom_field_master_id = masterId.join();
    }
  }
  Promise.all([
    instanceWithRetry.get(
      `arpaymenthistory/total${generateQueryString(
        filterObject
      )}&status=unapplied`
    ),
    instanceWithRetry.get(
      `arpaymenthistory/total${generateQueryString(
        filterObject
      )}&status=applied`
    ),
  ])
    .then((responses) => {
      const unapplied = get(responses, "[0].data.total", 0);
      const applied = get(responses, "[1].data.total", 0);
      const legend = applied ? "Paid" : unapplied ? "Balance" : "";
      getPaymentsData({
        applied,
        unapplied,
        fetchingChartData: false,
        filter: {
          ...customerDetails.filter,
          selectedSection: legend ? getPaymentChartData(legend) : null,
        },
      });
    })
    .catch((e) => {
      setCustomerDetails({
        fetchingChartData: false,
        isFetchingArInvoice: false,
      });
      handleAPIError(e);
    });
}

export const getCustomFieldDropdownData = (data, callback) => {
  instanceWithRetry
    .get(`/customfield${generateQueryString(data)}`)
    .then((response) => {
      callback &&
        callback(
          response.data.result
            .filter((d) => d)
            .map((d) => ({ label: d, value: d }))
        );
    })
    .catch((e) => {
      handleAPIError(e);
      callback && callback([]);
    });
};

export const getStoredCards = (openModal, isMutate = false) => {
  const customerDetails = getcustomerDetailsFromStore();
  const { client_customer_rel_id, showAutoPayModal } = customerDetails;
  setCustomerDetails({
    isStoredCardFetching: true,
  });
  return instanceWithRetry
    .get(
      `${enableWorldPayPG() ? "saved" : "stripe"}/card${generateQueryString({
        client_customer_rel_id,
        mutate: isMutate ? 1 : "",
      })}`
    )
    .then((res) => {
      setCustomerDetails({
        customerCards: res.data.result,
        showAutoPayModal: openModal || showAutoPayModal,
        isStoredCardFetching: false,
      });
      return res.data.result;
    })
    .catch((e) => {
      setCustomerDetails({
        isStoredCardFetching: false,
      });
      handleAPIError(e);
    });
};

export const getStoredBanks = (openModal, isMutate = false) => {
  const customerDetails = getcustomerDetailsFromStore();
  const { client_customer_rel_id, showAutoPayModal } = customerDetails;
  setCustomerDetails({
    isStoredBankFetching: true,
  });
  instanceWithRetry
    .get(
      `stripe/bank/accounts${generateQueryString({
        client_customer_rel_id,
        mutate: isMutate ? 1 : "",
      })}`
    )
    .then((res) => {
      setCustomerDetails({
        customerBanks: res.data.result,
        showAutoPayModal: openModal || showAutoPayModal,
        isStoredBankFetching: false,
      });
    })
    .catch((e) => {
      setCustomerDetails({
        isStoredBankFetching: false,
      });
      handleAPIError(e);
    });
};

export const getStoredMicroAccounts = (openModal, isMutate = false) => {
  const customerDetails = getcustomerDetailsFromStore();
  const { client_customer_rel_id, showAutoPayModal } = customerDetails;
  setCustomerDetails({
    isStoredMicroDepositFetching: true,
  });
  instanceWithRetry
    .get(
      `saved/accounts${generateQueryString({
        client_customer_rel_id,
        mutate: isMutate ? 1 : "",
      })}`
    )
    .then((res) => {
      setCustomerDetails({
        customerMicroDeposits: res.data.result,
        showAutoPayModal: openModal || showAutoPayModal,
        isStoredMicroDepositFetching: false,
      });
    })
    .catch((e) => {
      setCustomerDetails({
        isStoredMicroDepositFetching: false,
      });
      handleAPIError(e);
    });
};

export const getCustomerContacts = (id, corp_id) => {
  let url = `/contacts?client_customer_rel_id=${id}`;
  if (corp_id) {
    url += `&corp_entity_id=${corp_id}`;
  }
  return instanceWithRetry
    .get(url)
    .then((response) => {
      return response.data.result;
    })
    .catch((e) => {
      handleAPIError(e);
    });
};

export const editOrAddCustomerContacts = (id, reqObj) => {
  let url = `/contacts${id ? "" : "?mutate=1"}`;
  if (id) {
    url += `?id=${id}&mutate=1`;
  }
  return instanceWithRetry
    .post(url, reqObj)
    .then((response) => {
      return response;
    })
    .catch((e) => handleAPIError(e));
};

export const deleteCustomerContacts = (id, reqObj) => {
  let url = `/contacts?id=${id}&mutate=1`;

  return instanceWithRetry
    .delete(url, reqObj)
    .then((response) => {
      return response;
    })
    .catch((e) => handleAPIError(e));
};

export const setBankAutoPayment = (bank, showModal) => {
  const customerDetails = getcustomerDetailsFromStore();
  const { client_customer_rel_id, customerRelData } = customerDetails;

  if (!get(customerRelData, "email", "")) {
    setCustomerDetails({
      showAutoPayModal: false,
      showEmailPopup: true,
    });
    return;
  }
  const requestObj = { ...bank, client_customer_rel_id };
  instanceWithRetry
    .post("stripe/bank/accounts?mutate=1", requestObj)
    .then(() => {
      getStoredBanks(showModal, true);
      getStoredCards(false, true);
      getStoredMicroAccounts(false, true);
      if (
        bank.account_id &&
        bank.plaid_link_public_token &&
        !bank.is_selected_for_payment
      ) {
        Toaster(
          getLocaleString(
            "customer_details.new_bank_account_added_successfully",
            "New bank account added successfully"
          ),
          "success"
        );
        return;
      }
      if (bank.is_selected_for_payment) {
        Toaster(
          getLocaleString(
            "customer_details.autopay_enabled_successfully",
            "Autopay enabled successfully"
          ),
          "success"
        );
      }
      if (bank.is_disabled) {
        Toaster(
          getLocaleString(
            "customer_details.bank_account_disabled_successfully",
            "Bank account disabled successfully"
          ),
          "success"
        );
      }
    })
    .catch((e) => handleAPIError(e));
};

export const setAutoPayment = (card, newCard, micro = null, type = "") => {
  const customerDetails = getcustomerDetailsFromStore();
  const { client_customer_rel_id } = customerDetails;

  const requestObj = card
    ? { ...card, client_customer_rel_id }
    : {
        client_customer_rel_id,
        is_local_redirect: window.location.href.includes("localhost"),
        payment_method: type,
      };
  const method = enableWorldPayPG() ? "put" : "post";
  const URL = micro
    ? enableWorldPayPG()
      ? "wpg/addbankaccount?mutate=1"
      : "saved/accounts?mutate=1"
    : enableWorldPayPG()
    ? "wpg/addcard?mutate=1"
    : "stripe/card?mutate=1";
  instanceWithRetry[method](URL, requestObj)
    .then((res) => {
      if (newCard) {
        setCustomerDetails({
          activeKey: "#accounts",
        });
        Toaster(
          `${
            micro
              ? getLocaleString(
                  "customer_details.micro_deposit_account_saved_successfully",
                  "Micro Deposit Account saved successfully"
                )
              : getLocaleString(
                  "customer_details.new_card_saved_successfully",
                  "New Card saved successfully"
                )
          }`,
          "success"
        );
      }
      if (card) {
        if (card.is_disabled) {
          if (micro) getStoredMicroAccounts(true, true);
          else getStoredCards(true, true);

          getStoredBanks(false, true);
          Toaster(
            `${
              micro
                ? getLocaleString(
                    "customer_details.micro_deposit_account_removed_successfully",
                    "Micro deposit account removed successfully"
                  )
                : getLocaleString(
                    "customer_details.card_removed_successfully",
                    "Card removed successfully"
                  )
            }`,
            "success"
          );
        } else if (!card.session_id) {
          getStoredCards();
          getStoredBanks(false, true);
          getStoredMicroAccounts();
        }
      } else if (get(res, "data.checkout_session_url", "")) {
        window.location.href = get(res, "data.checkout_session_url", "");
      }
    })
    .catch((e) => handleAPIError(e));
};

export const getAchStripeLinkToken = () => {
  const customerDetails = getcustomerDetailsFromStore();
  const { client_customer_rel_id } = customerDetails;
  return instanceWithRetry
    .get(`stripe/linktoken?client_customer_rel_id=${client_customer_rel_id}`)
    .then((res) => res.data.link_token)
    .catch((e) => handleAPIError(e));
};

export const expireAccount = (id) =>
  instanceWithRetry
    .post("plaid/expiretoken?mutate=1", { customer_access_token: id })
    .then(() => getStoredBanks(false, true));

export const reauthAccount = (id) =>
  instanceWithRetry
    .get(`stripe/renewtoken?customer_access_token=${id}`)
    .then((response) => response.data.public_token)
    .catch((e) => handleAPIError(e));

export function getEasyPayPaymentMethod(id) {
  setCustomerDetails({
    isStoredMicroDepositFetching: true,
  });
  instanceWithRetry
    .get(`/easypay/methods?client_customer_rel_id=${id}`)
    .then((response) => {
      setCustomerDetails({
        customerCards: response.data
          .filter((d) => d.last4)
          .filter((d) => !d.is_disabled),
        customerMicroDeposits: response.data
          .filter((d) => d.last_4_digits)
          .filter((d) => !d.is_disabled),
        isStoredMicroDepositFetching: false,
      });
    })
    .catch((e) => {
      handleAPIError(e);
      setCustomerDetails({
        isStoredMicroDepositFetching: false,
      });
    });
}

export function addOrMakeEasyPayPayment(reqObj, callback, isPayment) {
  instanceWithRetry
    .post("easypay?mutate=1", reqObj)
    .then((res) => {
      if (callback) {
        callback();
      }
      if (isPayment) {
        if (get(res, "data.receipt_url", "")) {
          openNewTab(get(res, "data.receipt_url", ""), "_blank");
        }
        Toaster(
          getLocaleString(
            "customer_details.your_payment_is_initiated",
            "Your payment is initiated. Please check status after sometime"
          ),
          "success"
        );
      } else {
        if (reqObj.payment_method === "CARD") {
          Toaster(
            getLocaleString(
              "customer_details.new_card_saved_successfully",
              "New Card saved successfully"
            ),
            "success"
          );
        } else {
          Toaster(
            getLocaleString(
              "customer_details.new_bank_account_added_successfully",
              "New bank account added successfully"
            ),
            "success"
          );
        }
        getEasyPayPaymentMethod(reqObj.client_customer_rel_id);
      }
    })
    .catch((e) => {
      handleAPIError(e, true);
      if (callback) {
        Toaster(e, "error");
        callback(e);
      }
    });
}

export function updateEasyPayPaymentMethod(reqObj, callback) {
  instanceWithRetry
    .put("easypay/methods?mutate=1", reqObj)
    .then(() => {
      if (callback) {
        callback();
      }
      if (reqObj.is_selected_for_payment) {
        Toaster(
          getLocaleString(
            "customer_details.autopay_enabled_successfully",
            "Autopay enabled successfully"
          ),
          "success"
        );
      }
      if (reqObj.is_disabled) {
        if (reqObj.payment_method === "ACH") {
          Toaster(
            getLocaleString(
              "customer_details.bank_account_removed_successfully",
              "Bank account removed successfully"
            ),
            "success"
          );
        }
        if (reqObj.payment_method === "CARD") {
          Toaster(
            getLocaleString(
              "customer_details.card_removed_successfully",
              "Card removed successfully"
            ),
            "success"
          );
        }
      }
      getEasyPayPaymentMethod(reqObj.client_customer_rel_id);
    })
    .catch((e) => handleAPIError(e, true));
}

export const getCustomerDeposits = (params = null) => {
  const customerDetails = getcustomerDetailsFromStore();
  const { client_customer_rel_id } = customerDetails;
  const isExport = get(params, "is_export", false);
  setCustomerDetails({
    isDepositFetching: !isExport,
  });
  return instanceWithRetry
    .get(
      `/customer/deposit${generateQueryString({
        ...params,
        client_customer_rel_id,
      })}`
    )
    .then((response) => {
      if (!isExport) {
        setCustomerDetails({
          isDepositFetching: false,
          prev_num: get(response, "data.prev_num", null),
          next_num: get(response, "data.next_num", null),
          totalRecords: get(response, "data.total", 0),
          depositList: response.data.result,
        });
      }
      return response.data.result;
    })
    .catch((e) => {
      setCustomerDetails({
        isDepositFetching: false,
      });
      handleAPIError(e);
    });
};

export const getCustomerDepositsChartData = async () => {
  const customerDetails = getcustomerDetailsFromStore();
  const {
    filter: { from_date, to_date, invoice_id = null, order_backlog_id = null },
    client_customer_rel_id,
  } = customerDetails;

  const payload = {
    status: ["deposited", "fully applied", "other"],
    client_customer_rel_id: client_customer_rel_id,
    from_date: from_date,
    to_date: to_date,
    ar_invoice_header_id: get(invoice_id, "id", ""),
    order_backlog_id: get(order_backlog_id, "id", ""),
  };
  return instanceWithRetry.post("customer/deposit/total", payload);
};

export const getCustomerFiles = (params = "") => {
  const customerDetails = getcustomerDetailsFromStore();
  const { client_customer_rel_id } = customerDetails;
  setCustomerDetails({
    isFilesListFetching: true,
  });
  instanceWithRetry
    .get(`/files/upload?id=${client_customer_rel_id}&level=customer${params}`)
    .then((response) => {
      setCustomerDetails({
        isFilesListFetching: false,
        filesList: response.data,
      });
    })
    .catch((e) => {
      setCustomerDetails({
        isFilesListFetching: false,
      });
      handleAPIError(e);
    });
};

export const uploadCustomerFiles = (id, fileData) =>
  instanceMultipart
    .post(`/uploadfile?id=${id}&level=customer&mutate=1`, fileData)
    .then(() => {
      Toaster(
        getLocaleString(
          "common.file_upload_success",
          "File uploaded successfully"
        ),
        "success"
      );
      getCustomerFiles("&mutate=1");
    })
    .catch((e) => handleAPIError(e, true));

export const deleteCustomerFiles = (id, fileData) =>
  instanceWithRetry
    .delete(`files/delete?id=${id}&level=customer&mutate=1`, fileData)
    .then(() => {
      Toaster(
        getLocaleString("common.file_deleted", "File deleted"),
        "success"
      );
      getCustomerFiles("&mutate=1");
    })
    .catch((e) => handleAPIError(e));

export const getPaymentPlan = (client_customer_rel_id, id, isMutate) => {
  const url = `customer/paymentplan${generateQueryString({
    client_customer_rel_id,
    id,
    mutate: isMutate ? "1" : "",
  })}`;
  return instanceWithRetry
    .get(url)
    .then((response) => response)
    .catch((e) => handleAPIError(e));
};

export const createWPGpayment = (params) =>
  instanceWithRetry.post("wpg/createpayment?mutate=1", params).catch((e) => {
    handleAPIError(e, true);
    return e;
  });

export const processWPGCardPayment = (params) =>
  instanceWithRetry
    .post("wpg/processcardpayment?mutate=1", params)
    .catch((e) => {
      handleAPIError(e);
      return e;
    });

export const processWPGACHPayment = (params) =>
  instanceWithRetry
    .post("wpg/processachpayment?mutate=1", params)
    .catch((e) => {
      handleAPIError(e);
      return e;
    });

export const processFailureWPGToken = (params) =>
  instanceWithRetry.post("wpg/checkout_failed?mutate=1", params).catch((e) => {
    handleAPIError(e);
    return e;
  });

export const addWpgCard = (params) =>
  instanceWithRetry[params.method || "post"](
    "wpg/addcard?mutate=1",
    params
  ).catch((e) => {
    handleAPIError(e, true);
    return e;
  });

export const getWpgCheckoutDetails = (params) =>
  instanceWithRetry
    .get(
      `wpg/checkout_details?client_customer_rel_id=${params.client_customer_rel_id}`
    )
    .catch((e) => {
      handleAPIError(e, true);
      return e;
    });

export const addWpgBank = (params) =>
  instanceWithRetry[params.method || "post"](
    "wpg/addbankaccount?mutate=1",
    params
  ).catch((e) => {
    handleAPIError(e, true);
    return e;
  });

export const deleteCustomerPaymentPlan = (id) =>
  instanceWithRetry
    .delete(`customer/paymentplan?id=${id}&mutate=1`)
    .then((response) => response)
    .catch((e) => handleAPIError(e));

export const getStripeERPPaymentURL = (id) =>
  instanceWithRetry
    .get(`stripe/paylink?erp_internal_id=${id}`)
    .then((response) => response)
    .catch((e) => handleAPIError(e));

// reducer functions
export const getcustomerDetailsFromStore = () => {
  const { customerDetails } = store.getState();
  return customerDetails;
};

export const resetCustomerDetails = () => {
  const customerDetails = getcustomerDetailsFromStore();
  store.dispatch({
    type: ReducersTypes.RESET_CUSTOMER_DETAILS,
    payload: {
      sort_by: get(customerDetails, "filter.sort_by", "total_amount_due"),
      sort_order: get(customerDetails, "filter.sort_order", "desc"),
      sort_by_keys: get(customerDetails, "filter.sort_by_keys", {}),
    },
  });
};

export const switchUserRole = async (role, id, corp_entity_id) => {
  const loggedInUserData = JSON.parse(TokenManager.getUserData());
  const clientData = await instanceWithRetry.get(
    `client${generateQueryString({ corp_entity_id })}`
  );

  TokenManager.setUserData({
    ...loggedInUserData,
    role: !role ? "customer" : role,
    oldRole: loggedInUserData.role === "customer" ? "" : loggedInUserData.role,
    client_customer_rel_id: !role ? id : null,
    client_logo: get(clientData, "data.result.logo_url", ""),
  });
  window.location.reload();
};

export const setCustomerDetails = (data) => {
  store.dispatch({
    type: ReducersTypes.CUSTOMER_DETAILS,
    payload: data,
  });
};

export const setCustomerDetailsRelId = (data) => {
  store.dispatch({
    type: ReducersTypes.CUSTOMER_DETAILS_REL_ID,
    payload: data,
  });
};

export const resetCustomerDetailsRelId = (data) => {
  store.dispatch({
    type: ReducersTypes.RESET_CUSTOMER_DETAILS_REL_ID,
    payload: data,
  });
};

export const setCustomerDetailsId = (data) => {
  store.dispatch({
    type: ReducersTypes.CUSTOMER_DETAILS_ID,
    payload: data,
  });
};

export const resetCustomerDetailsId = (data) => {
  store.dispatch({
    type: ReducersTypes.RESET_CUSTOMER_DETAILS_ID,
    payload: data,
  });
};

export const setCustomerRelData = (data) => {
  store.dispatch({
    type: ReducersTypes.CUSTOMER_DETAILS_CUSTOMER_REL_DATA,
    payload: data,
  });
};

export const resetCustomerRelData = (data) => {
  store.dispatch({
    type: ReducersTypes.RESET_CUSTOMER_DETAILS_CUSTOMER_REL_DATA,
    payload: data,
  });
};

export const setCustomerDetailsListdata = (data) => {
  store.dispatch({
    type: ReducersTypes.CUSTOMER_DETAILS_LISTDATA,
    payload: data,
  });
};

export const resetCustomerDetailsListdata = () => {
  store.dispatch({
    type: ReducersTypes.CUSTOMER_DETAILS_LISTDATA,
    payload: [],
  });
};

export const setCustomerDetailsFilter = (data) => {
  store.dispatch({
    type: ReducersTypes.CUSTOMER_DETAILS_FILTER,
    payload: data,
  });
  store.dispatch({
    type: ReducersTypes.SET_UX,
    payload: {
      CUSTOMER_DETAILS_FILTERS: {
        ...getuxvalue("CUSTOMER_DETAILS_FILTERS"),
        ...data,
      },
    },
  });
};

export const resetCustomerDetailsFilter = () => {
  store.dispatch({
    type: ReducersTypes.RESET_CUSTOMER_DETAILS_FILTER,
  });
  store.dispatch({
    type: ReducersTypes.SET_UX,
    payload: {
      CUSTOMER_DETAILS_FILTERS: initialCustomerDetailsFilters,
    },
  });
};

export const refreshInvoices = (refreshInvoices) =>
  instanceWithRetry
    .post(
      "client/fetchdata?mutate=1",
      {
        internal_id_list: refreshInvoices,
        data_type: "ar_invoice",
      },
      {
        headers: {
          client_id_context: TokenManager.getClientId(),
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        Toaster(
          getLocaleString(
            "common.data_will_be_updated_in_while",
            "Data will be updated in a while"
          ),
          "success"
        );
      } else {
        Toaster(
          getLocaleString(
            "common.failed_to_refresh_invoices",
            "Failed to refresh invoices"
          ),
          "error"
        );
      }
    })
    .catch((e) => {
      if (e.message === "Network Error") {
        Toaster(getLocaleString("common.api_timeout", "API timeout"), "error");
      } else {
        Toaster(
          `${getLocaleString(
            "common.error_occured_while_fetching_data",
            "Error occured while fetching data"
          )}${e.message || ""}`,
          "error"
        );
      }
      handleAPIError(e);
    });

export const modifiedCustomerNameForAIRClient = (customerName) => {
  if (!customerName) return;
  if (customerName.includes("/")) {
    const splitedCustomerName = customerName.split("/");
    const finalCustomerName = get(splitedCustomerName, `[${splitedCustomerName.length - 1}]`, "");
    return finalCustomerName ? finalCustomerName : customerName;
  }
  return customerName;
}