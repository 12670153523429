import { ReducersTypes } from "../constants/ReducersTypes";
import TokenManager from "utils/TokenManager";

const initconfirmationbox = {
  ...{
    msg: "",
    variant: "info", // "success", "error", "warning", "info",
    onSave: null,
  },
};

const confirmationbox = (state = initconfirmationbox, action) => {
  switch (action.type) {
    case ReducersTypes.CONFIRMATION_BOX:
      return { ...state, ...action.payload };
    case ReducersTypes.CONFIRMATION_BOX_RESET:
      return initconfirmationbox;
    default:
      return state;
  }
};

const initux = {};

const ux = (state = initux, action) => {
  switch (action.type) {
    case ReducersTypes.SET_UX:
      return { ...state, ...action.payload };
    case ReducersTypes.RESET_UX:
      return initux;
    default:
      return state;
  }
};

const initemail = {
  ...{
    show: false,
    from: "",
    to: "",
    cc: TokenManager.getUserEmail(),
    subject: "",
    body: "",
    emailTemplateData: [],
    customerVendorName: "",
    files: [],
    skip_attachments: false,
    attachments_skipped: false,
  },
};

const email = (state = initemail, action) => {
  switch (action.type) {
    case ReducersTypes.EMAIL_DATA:
      return { ...state, ...action.payload };
    case ReducersTypes.EMAIL_RESET:
      return initemail;
    default:
      return state;
  }
};

export const dynamicformReducer = (
  state = {
    forms: [],
    count: 0,
  },
  action
) => {
  switch (action.type) {
    case ReducersTypes.DYNAMIC_FORM:
      return { ...state, forms: action.payload.forms };
    case ReducersTypes.ADD_DYNAMIC_FORM:
      return {
        ...state,
        forms: [...state.forms, ...action.payload.forms],
        count: state.count + 1,
      };
    default:
      return state;
  }
};

const initpreview = {
  show: false,
  type: "", // doc, policy, info
  data: "",
  name: "",
  isbase64: false,
  ext: "",
  uri: "",
};

export const preview = (state = initpreview, action) => {
  switch (action.type) {
    case ReducersTypes.PREVIEW_DOCUMENT:
      return { ...state, ...action.payload };
    case ReducersTypes.RESET_PREVIEW_DOCUMENT:
      return { ...initpreview };
    default:
      return state;
  }
};

const initheader = {
  refresh_header: false,
};

export const header = (state = initheader, action) => {
  switch (action.type) {
    case ReducersTypes.HEADER:
      return { ...state, ...action.payload };
    case ReducersTypes.RESET_HEADER:
      return { ...initheader };
    case ReducersTypes.HEADER_REFRESH_TOGGLE:
      return { ...state, refresh_header: state.refresh_header ? false : true };
    default:
      return state;
  }
};

const inittag = {
  allTags: "",
};

const tag = (state = inittag, action) => {
  switch (action.type) {
    case ReducersTypes.SET_TAG:
      return { ...state, ...action.payload };
    case ReducersTypes.RESET_TAG:
      return { ...inittag };
    default:
      return state;
  }
};

const client = {
  clientData: {},
  initialClientData: {},
  isFetching: false,
};

const clientDetails = (state = client, action) => {
  switch (action.type) {
    case ReducersTypes.FETCH_CLIENT_DATA:
      return { ...state, ...action.payload };
    case ReducersTypes.UPDATE_CLIENT_DATA:
      return initux;
    case ReducersTypes.UPDATE_DISCOUNT_STATE:
      return {
        ...state,
        clientData: {
          ...state.clientData,
          client_settings: {
            ...state.clientData.client_settings,
            ...action.payload,
          },
        },
      };
    default:
      return state;
  }
};

const payrollSetting = {
  payrollSettings: [],
  isFetching: false,
};

const payrollSettingsData = (state = payrollSetting, action) => {
  switch (action.type) {
    case ReducersTypes.PAYROLL_SETTINGS_FETCH:
      return { ...state, ...action.payload };
    case ReducersTypes.PAYROLL_SETTINGS_UPDATE:
      return state;
    default:
      return state;
  }
};

export const initialConfigSettings = {
  customerSettings: {
    CUSTOMER_SHOW_DISPUTE_BUTTON: 0,
    CUSTOMER_SHOW_UNAPPLIED_PAYMENT: 0,
    CUSTOMER_SHOW_UNAPPLIED_CREDIT_MEMO: 0,
    CUSTOMER_SHOW_PAYFERENCE_PAYMENTS: 0,
    CUSTOMER_SHOW_ORDER_BACKLOG: 0,
    CUSTOMER_SHOW_DEPOSITS: 0,
    CUSTOMER_DSO_PERIOD: 0,
    CUSTOMER_SHOW_PAYIQ_COLUMN: 0,
    CUSTOMER_SHOW_PO_NUMBER_COLUMN: 0,
    CUSTOMER_SHOW_MEMO_COLUMN: 0,
    CUSTOMER_SHOW_PROJECT_ID_COLUMN: 0,
    CUSTOMER_SHOW_ENTITY_COLUMN: 0,
    CUSTOMER_SHOW_MARK_PAID_BUTTON: 0,
    CUSTOMER_SHOW_EXPORT_BUTTON: 1,
    CUSTOMER_SHOW_STATEMENT_BUTTON: 1,
    CUSTOMER_SHOW_EMAIL_BUTTON: 1,
    CUSTOMER_SHOW_NOTES_BUTTON: 1,
    CUSTOMER_SHOW_FILE_UPLOAD: 0,
  },
  statementSettings: {
    STATEMENT_INCLUDE_UNAPPLIED_PAYMENT: 0,
    STATEMENT_INCLUDE_UNAPPLIED_CREDIT_MEMO: 0,
    STATEMENT_ATTACH_INVOICE: 0,
    STATEMENT_SORT_INVOICE_ASC: 0,
    STATEMENT_SHOW_RUNNING_TOTAL: 0,
    STATEMENT_SHOW_CUSTOMER_PO: 0,
    STATEMENT_ATTACH_EXCEL_FILE: 0,
    STATEMENT_ATTACH_PDF_FILE: 0,
    STATEMENT_SHOW_DAYS_PAST_DUE: 0,
    STATEMENT_SHOW_CUSTOMER_NAME: 0,
    STATEMENT_ERP_SYNC: 0,
    STATEMENT_SHOW_MEMO_FIELD: 0,
  },
  invoiceLineSettings: {
    PAYABLES_SHOW_INVOICE_LINE: 0,
    PAYABLES_EDIT_INVOICE_LINE: 0,
    RECEIVABLES_SHOW_INVOICE_LINE: 0,
    RECEIVABLES_EDIT_INVOICE_LINE: 0,
  },
  workflowSettings: {
    ENTITY_LEVEL_WORKFLOW_ENABLED: 0,
    SELECT_HIGH_SEVERITY_WORKFLOW_STEP: 0,
  },
  generalSettings: {
    SHOW_CENTS: 0,
    PUSH_EMAILS_TO_ERP: 0,
    MULTI_FACTOR_AUTHENTICATION_ENABLED: 0,
  },
  csvSettings: {
    CSV_EMAIL_LIST: "",
  },
  paymentSettings: {
    SYNC_PAYMENT_METHOD_ID: "",
  },
  workflowInvoiceSettings: {
    INVOICE_AUTO_ATTACH: 1,
    INVOICE_SHOW_CUSTOMER_PO: 0,
    INVOICE_SHOW_RUNNING_TOTAL: 0,
    INVOICE_SHOW_CUSTOMER_NAME: 0,
    INVOICE_SHOW_DAYS_PAST_DUE: 0,
  },
  clientFeatures: {
    CLIENT_EMAIL_SENDER_NAME: "CORP_ENTITY_NAME",
    CLIENT_JOURNAL_SECTION_ENABLED: 1,
    CLIENT_PAYROLL_SECTION_ENABLED: 1,
    CLIENT_VENDORS_SECTION_ENABLED: 1,
    CLIENT_CASHFLOW_SECTION_ENABLED: 1,
    CLIENT_PAYABLES_SECTION_ENABLED: 1,
    CLIENT_CUSTOMERS_SECTION_ENABLED: 1,
    CLIENT_RECEIVABLES_SECTION_ENABLED: 1,
    CLIENT_CASHFLOW_BYPAYMENT_ENABLED: 0,
    CLIENT_CASHFLOW_BYINVOICE_ENABLED: 0,
  },
};

initialConfigSettings["initialCustomerSettings"] =
  initialConfigSettings["customerSettings"];
initialConfigSettings["initialStatementSettings"] =
  initialConfigSettings["statementSettings"];
initialConfigSettings["initialLineSettings"] =
  initialConfigSettings["invoiceLineSettings"];

const configurableSettings = (
  state = initialConfigSettings,
  { type, payload }
) => {
  switch (type) {
    case ReducersTypes.CONFIG_SETTINGS:
      const obj = {};
      Object.keys(payload).forEach((key) => {
        obj[key] = payload[key];
      });
      return {
        ...state,
        ...obj,
      };
    default:
      return state;
  }
};

const globalReducers = {
  ux,
  tag,
  email,
  header,
  preview,
  clientDetails,
  confirmationbox,
  dynamicformReducer,
  payrollSettingsData,
  configurableSettings,
};

export default globalReducers;
