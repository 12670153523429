import React, { memo, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { getLocaleString } from "utils/localization/locale";

const SimplePagination = memo(function SimplePagination({
  page,
  totalPages,
  onSelectPage,
}) {
  const [inputValue, setInputValue] = useState(page);
  const [value, setValue] = useState({ value: page, label: page });

  const pagesArray = useMemo(
    () =>
      Array.from({ length: totalPages }, (_, i) => ({
        value: i + 1,
        label: i + 1,
      })),
    [totalPages]
  );

  const handleInputChange = (value) => {
    // Allow only digits (0-9) and empty string
    if (value && /^\d*$/.test(value)) {
      setInputValue(value);
    }
  };

  useEffect(() => {
    setInputValue(page);
    setValue({ value: page, label: page });
  }, [page]);

  return (
    <div className="d-inline-block min-width-200">
      <span>{getLocaleString("common.page", "Page")}:</span>
      <div className="d-inline-block mx-2">
        <Select
          options={pagesArray}
          blurInputOnSelect
          onInputChange={handleInputChange}
          value={value}
          filterOption={(num) => {
            const numInputValue = Number(inputValue);
            const start = Math.max(1, Math.floor(numInputValue / 10) * 10 + 1);
            const end = Math.min(start + 9, totalPages);
            return num.value >= start && num.value <= end;
          }}
          onChange={(selectedPage) => {
            if (selectedPage) {
              setInputValue(selectedPage.value);
              onSelectPage(selectedPage.value);
            }
            setValue(selectedPage);
          }}
          noOptionsMessage={() => "No Page"}
          isSearchable={true}
          isClearable={true}
          placeholder={getLocaleString("common.page", "Page")}
          styles={{
            menuPortal: (base) => ({
              ...base,
              zIndex: 100,
            }),
          }}
          menuPlacement={"auto"}
          menuPortalTarget={document.body}
        />
      </div>
      <span>
        {getLocaleString("common.of", "of")} {totalPages}
      </span>
    </div>
  );
});

export default SimplePagination;
