/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { instanceWithRetry } from "actions/axiosInstance";
import get from "lodash/get";
import Toaster from "components/Toaster";
import {
  setconfirmationboxData,
  resetconfirmationboxData,
} from "components/ConfirmationBox";
import { handleAPIError } from "handlers/setters";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Button from "components/Button";
import { getLocaleString } from "utils/localization/locale";

const renderText = (text) => text;

export default function EmailTemplateCreator(props) {
  const { emailTemplate, clientData, fetchEmailTemplateList } = props;
  const emptyTemplateData = {
    body: "",
    subject: "",
    name: "",
  };
  const [currentTemplateData, setCurrentTemplateData] =
    useState(emptyTemplateData);
  const [templateMode, setTemplateMode] = useState("");
  const [bodyLimitError, setBodyLimitError] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [currPage, setCurrPage] = useState(1);

  const emailBody = get(currentTemplateData, "body", "")
    ? get(currentTemplateData, "body", "")
        .replaceAll(
          `<table border="0" cellpadding="0" cellspacing="0" width="100%" style="background: white; padding: 10px">`,
          ""
        )
        .replaceAll("</table>", "")
        .replaceAll(`<figure className="table"><table><tbody>`, "")
        .replaceAll(`</tbody></table></figure>`, "")
    : "";

  useEffect(() => {
    fetchEmailTemplateList();
  }, []);

  const resetData = () => {
    setTemplateMode("");
    setCurrentTemplateData(emptyTemplateData);
  };

  const deleteTemplate = (id) =>
    instanceWithRetry
      .delete(`arworkflow/template?id=${id}&mutate=1`)
      .then(() => {
        Toaster(
          getLocaleString(
            "common.deleted_successfully",
            "Deleted successfully"
          ),
          "success"
        );
        fetchEmailTemplateList(true);
      })
      .catch((e) => {
        handleAPIError(e);
        Toaster(
          getLocaleString(
            "receivables.template_is_in_use",
            "This template is in use"
          ),
          "error"
        );
      });

  const submitEmailTemplateEntry = (mode, currentTemplateData) => {
    const URL =
      mode === "add"
        ? "arworkflow/template?mutate=1"
        : `arworkflow/template?id=${currentTemplateData.id}&mutate=1`;

    instanceWithRetry
      .post(URL, { ...currentTemplateData })
      .then((response) => {
        if (get(response, "data.message", "") === "success") {
          Toaster(
            `${
              mode === "add"
                ? getLocaleString(
                    "receivables.new_template_created_successfully",
                    "New template created successfully"
                  )
                : getLocaleString(
                    "receivables.template_updated_successfully",
                    "Template updated successfully"
                  )
            }`,
            "success"
          );
          fetchEmailTemplateList(true);
          resetData();
        } else {
          Toaster(
            getLocaleString(
              "receivables.unable_to_save_template",
              "Unable to save template"
            ),
            "error"
          );
        }
      })
      .catch((e) => handleAPIError(e, true));
  };

  const getEmailTemplateDetails = (id) => {
    const URL = `arworkflow/template?id=${id}`;
    setIsFetching(true);
    instanceWithRetry
      .get(URL)
      .then((response) => {
        setCurrentTemplateData(get(response, "data.result[0]", null));
        setTemplateMode("edit");
        setIsFetching(false);
      })
      .catch((e) => handleAPIError(e));
  };

  const paginate = (array, page_size, page_number) =>
    array.slice((page_number - 1) * page_size, page_number * page_size);

  const disableNext =
    paginate(get(emailTemplate, "data", []), 20, currPage).length < 20;

  return (
    <div>
      {isFetching && (
        <div className="postion-absolute full-page-spinner justify-content-center d-flex align-items-center">
          <div className="spinner-border" />
        </div>
      )}
      <div className="row mt-3 px-5">
        {!templateMode ? (
          <div className="col-12">
            <Button onClick={() => setTemplateMode("add")}>
              {getLocaleString("receivables.create_new", "Create New")}
            </Button>
            <table className="paynewdetsectran mb-3 mt-2">
              <tr>
                <th>
                  {getLocaleString(
                    "receivables.template_name",
                    "Template Name"
                  )}
                </th>
                <th>
                  {getLocaleString(
                    "receivables.email_subject",
                    "Email Subject"
                  )}
                </th>
                <th>{getLocaleString("common.action", "Action")}</th>
              </tr>
              {paginate(get(emailTemplate, "data", []), 20, currPage).map(
                (d) => (
                  <tr>
                    <td>{d.name}</td>
                    <td>{d.subject}</td>
                    <td>
                      {d.name === "introduction_email_template" &&
                      !clientData.client_settings
                        ?.intro_email_enabled ? null : (
                        <>
                          <Button
                            title={getLocaleString(
                              "receivables.edit_template",
                              "Edit Template"
                            )}
                            onClick={() => getEmailTemplateDetails(d.id)}
                            className="p-0 me-2"
                            variant="icon"
                            startIcon={
                              <i className="fa fa-pencil color-blue" />
                            }
                          />
                          <Button
                            title={getLocaleString(
                              "receivables.delete_template",
                              "Delete Template"
                            )}
                            onClick={() =>
                              setconfirmationboxData({
                                variant: "warning",
                                msg: getLocaleString(
                                  "receivables.are_you_sure_you_want_to_delete_this_template",
                                  "Are you sure you want to delete this template?"
                                ),
                                onSave: () => {
                                  resetconfirmationboxData();
                                  deleteTemplate(d.id);
                                },
                              })
                            }
                            className="p-0"
                            variant="icon"
                            startIcon={
                              <i className="fa fa-trash-o color-blue" />
                            }
                          />
                        </>
                      )}
                    </td>
                  </tr>
                )
              )}
            </table>
            <div className="float-end mt-3">
              <Button
                onClick={() => setCurrPage(currPage - 1)}
                className="me-2 minwidth-94px"
                disabled={currPage === 1}
              >
                {getLocaleString("common.previous", "Previous")}
              </Button>
              <Button
                onClick={() => setCurrPage(currPage + 1)}
                disabled={disableNext}
                className="minwidth-94px"
              >
                {getLocaleString("common.next", "Next")}
              </Button>
            </div>
          </div>
        ) : (
          <>
            <h5 className="col-md-12 mb-3">
              <Button
                title={getLocaleString(
                  "receivables.back_to_templates",
                  "Back to templates"
                )}
                className="p-0"
                variant="icon"
                onClick={resetData}
                startIcon={
                  <i
                    className="fa fa-arrow-left t-black me-2"
                    aria-hidden="true"
                  />
                }
              />
              {templateMode === "add"
                ? getLocaleString(
                    "receivables.create_new_template",
                    "Create New Template"
                  )
                : getLocaleString("receivables.edit_template", "Edit Template")}
            </h5>
            <div className="col-md-6">
              <div className="form-group mb-3">
                <strong>
                  {getLocaleString(
                    "receivables.template_name",
                    "Template Name"
                  )}
                  :
                </strong>
                <input
                  disabled={
                    get(currentTemplateData, "name", "") ===
                    "introduction_email_template"
                  }
                  defaultValue={get(currentTemplateData, "name", "")}
                  data-testid="Enter Template Name"
                  type="text"
                  onChange={(e) =>
                    setCurrentTemplateData({
                      ...currentTemplateData,
                      name: e.target.value,
                    })
                  }
                  className="form-control t-12"
                  placeholder={getLocaleString(
                    "receivables.enter_template_name",
                    "Enter Template Name"
                  )}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mb-3">
                <strong>
                  {getLocaleString(
                    "receivables.email_subject",
                    "Email Subject"
                  )}
                  :
                </strong>
                <input
                  data-testid="Enter Email Subject Line"
                  defaultValue={get(currentTemplateData, "subject", "")}
                  type="text"
                  onChange={(e) =>
                    setCurrentTemplateData({
                      ...currentTemplateData,
                      subject: e.target.value,
                    })
                  }
                  className="form-control t-12"
                  placeholder={getLocaleString(
                    "receivables.enter_email_subject",
                    "Enter Email Subject Line"
                  )}
                />
              </div>
            </div>
            <div className="col-md-12">
              <strong>
                {getLocaleString("receivables.email_body", "Email Body")}:
              </strong>
              <div>
                {getLocaleString(
                  "receivables.use_following_tags",
                  "Use following tags for dynamic data"
                )}
                :{" "}
                {getLocaleString(
                  "receivables.use_following_tags_example",
                  "Dear {{customer.name}}, your following invoices are due: {{invoice_list_with_details}}"
                )}
                <ol>
                  <li>
                    {getLocaleString("receivables.ar_email", "AR Emai")}l:{" "}
                    <code>{renderText("{{CLIENT_EMAIL}}")}</code>
                  </li>
                  <li>
                    {getLocaleString(
                      "order_backlog.customer_name",
                      "Customer Name"
                    )}
                    : <code>{renderText("{{customer.name}}")}</code>
                  </li>
                  <li>
                    {getLocaleString(
                      "common.invoiceDetails",
                      "Invoice Details"
                    )}
                    : <code>{renderText("{{invoice_list_with_details}}")}</code>
                  </li>
                  <li>
                    {getLocaleString(
                      "receivables.amount_due_current_split",
                      "Amount Due, Current and Overdue Split"
                    )}
                    :{" "}
                    <code>
                      {renderText("{{total_due_overdue_current_split}}")}
                    </code>
                  </li>
                  <li>
                    {getLocaleString(
                      "receivables.logged_in_username",
                      "Logged in User Name"
                    )}
                    : <code>{renderText("{{logged_in_user_name}}")}</code>
                  </li>
                  <li>
                    {getLocaleString(
                      "receivables.logged_in_useremail",
                      "Logged in User Email"
                    )}
                    : <code>{renderText("{{logged_in_user_email}}")}</code>
                  </li>
                  <li>
                    {getLocaleString(
                      "receivables.logged_in_userphone",
                      "Logged in User Phone"
                    )}
                    : <code>{renderText("{{logged_in_user_phone}}")}</code>
                  </li>
                  <li>
                    {getLocaleString(
                      "receivables.terms_and_conditions",
                      "Terms and Condition"
                    )}
                    : <code>{renderText("{{terms_and_conditions}}")}</code>
                  </li>
                  <li>
                    {getLocaleString("receivables.client_name", "Client Name")}:{" "}
                    <code>{renderText("{{CLIENT_NAME}}")}</code>
                  </li>
                </ol>
              </div>
            </div>
            <div className="col-md-12">
              <CKEditor
                data-testid="CKeditor"
                editor={ClassicEditor}
                data={emailBody}
                config={{
                  toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "numberedList",
                    "bulletedList",
                  ],
                }}
                onChange={(_, editor) => {
                  const data = editor.getData();
                  setBodyLimitError(data.length >= 4000);
                  setCurrentTemplateData({
                    ...currentTemplateData,
                    body: data,
                  });
                }}
              />
              <span className="error t-12">
                {bodyLimitError
                  ? getLocaleString(
                      "receivables.text_limit_exceeded",
                      "Text limit exceeded"
                    )
                  : ""}
              </span>
            </div>
            <div className="col-md-12 mt-3">
              <Button
                type="button"
                data-testid="Submit"
                className="me-2"
                disabled={
                  !get(currentTemplateData, "name", "") ||
                  !get(currentTemplateData, "subject", "") ||
                  !emailBody ||
                  bodyLimitError
                }
                onClick={() =>
                  submitEmailTemplateEntry(templateMode, currentTemplateData)
                }
              >
                {getLocaleString("common.submit", "Submit")}
              </Button>
              <Button
                type="button"
                variant="secondary"
                data-testid="Cancle"
                disabled={!currentTemplateData}
                className="me-2"
                onClick={resetData}
              >
                {getLocaleString("common.cancel", "Cancel")}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
