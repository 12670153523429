import React, { useState } from "react";
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getExpandedRowModel,
} from "@tanstack/react-table";

import {
  useSensor,
  DndContext,
  useSensors,
  TouchSensor,
  MouseSensor,
  closestCenter,
  KeyboardSensor,
} from "@dnd-kit/core";

import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Button from "components/Button";
import { getLocaleString } from "utils/localization/locale";
import { paginationNextButtonStatus } from "utils";
import SimplePagination from "components/Pagination";
import "./table.css";
import get from "lodash/get";

const DraggableTableHeader = ({ header }) => {
  const { attributes, isDragging, listeners, setNodeRef, transform } =
    useSortable({
      id: header.column.id,
    });

  const isResizeble = get(header, "column.columnDef.resizable", true);
  const isDragable = get(header, "column.columnDef.dragable", true);
  const isExpander = get(header, "column.columnDef.id", "") === "expander";

  const style = {
    opacity: isDragging ? 0.8 : 1,
    position: "relative",
    transform: CSS.Translate.toString(transform),
    transition: "width transform 0.2s ease-in-out",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: header.column.getSize(),
    flex: `${header.column.getSize()} 0 auto`,
    zIndex: isDragging ? 1 : 0,
  };

  return (
    <th
      className="color-white show-ellipses"
      colSpan={header.colSpan}
      ref={setNodeRef}
      style={style}
    >
      <div
        onClick={() => header.column.getToggleSortingHandler()}
        className={`sort_${header.column.getIsSorted()} ${get(
          header,
          "column.columnDef.headerClassName",
          ""
        )}`}
      >
        {header.isPlaceholder
          ? null
          : flexRender(header.column.columnDef.header, header.getContext())}
      </div>
      {isDragable && !isExpander ? (
        <div
          {...listeners}
          {...attributes}
          className="drag fas fa-arrows-alt t-15"
        />
      ) : null}
      {isResizeble && !isExpander ? (
        <div
          {...{
            onDoubleClick: () => header.column.resetSize(),
            onMouseDown: header.getResizeHandler(),
            onTouchStart: header.getResizeHandler(),
            className: `resizer ltr ${
              header.column.getIsResizing() ? "isResizing" : ""
            }`,
          }}
        />
      ) : null}
    </th>
  );
};

const DragAlongCell = ({ row, cell, onCellClick }) => {
  const { isDragging, setNodeRef, transform } = useSortable({
    id: cell.column.id,
  });

  const style = {
    opacity: isDragging ? 0.8 : 1,
    position: "relative",
    transform: CSS.Translate.toString(transform),
    transition: "width transform 0.2s ease-in-out",
    width: cell.column.getSize(),
    flex: `${cell.column.getSize()} 0 auto`,
    zIndex: isDragging ? 1 : 0,
  };

  return (
    <td
      style={style}
      onClick={() => {
        if (
          get(cell, "column.columnDef.id", "") !== "expander" &&
          !get(cell, "column.columnDef.disableClick", false)
        ) {
          onCellClick(row.original);
        }
      }}
      className={`show-ellipses ${get(cell, "column.columnDef.className", "")}`}
      ref={setNodeRef}
    >
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  );
};

function DNDTable(props) {
  const {
    data,
    sorting,
    columns,
    setPage,
    currPage,
    prevPage,
    nextPage,
    totalPages,
    isFetching,
    hideHeader,
    setSorting,
    subRowsKey = "subRows",
    onRowClick = () => {},
    noDataText = getLocaleString("common.nodataFound", "No data found"),
    setPrevPage,
    setNextPage,
    hidePagination,
    selectedRowClass = "",
    defaultColumnOrder,
    enableRowSelection,
    renderFooter = false,
    showPaginationSwitch = false,
    onDraggedColumnChange = () => {},
  } = props;

  const [columnOrder, setColumnOrder] = useState(() =>
    columns.reduce((acc, cur, index) => {
      if (defaultColumnOrder && defaultColumnOrder[cur.columnName]) {
        let newIndex = defaultColumnOrder[cur.columnName] - 1;
        if (acc[newIndex]) {
          acc.splice(index, 0, cur.id);
        } else {
          acc[newIndex] = cur.id;
        }
      } else {
        acc[index] = cur.id;
      }
      return acc;
    }, [])
  );

  const table = useReactTable({
    data,
    state: {
      sorting,
      columnOrder,
    },
    columns: columns.filter((d) =>
      Object(d).hasOwnProperty("show") ? d.show : true
    ),
    manualSorting: true,
    sortDescFirst: true,
    enableRowSelection,
    getSubRows: (row) => row[subRowsKey],
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    columnResizeMode: "onChange",
    onSortingChange: setSorting,
    onColumnOrderChange: setColumnOrder,
    getExpandedRowModel: getExpandedRowModel(),
    columnResizeDirection: "ltr",
  });

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      setColumnOrder((columnOrder) => {
        const oldIndex = columnOrder.indexOf(active.id);
        const newIndex = columnOrder.indexOf(over.id);
        const newColumnOrder = arrayMove(columnOrder, oldIndex, newIndex);
        const columnObject = columns.reduce((acc, cur) => {
          if (newColumnOrder.includes(cur.id) && cur.columnName) {
            acc[cur.columnName] = newColumnOrder.indexOf(cur.id) + 1;
          }
          return acc;
        }, {});
        onDraggedColumnChange(columnObject);
        return newColumnOrder;
      });
    }
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToHorizontalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
      className="position-relative"
    >
      <div className="position-relative">
        <div className="overflow-auto">
          <table className="react-table">
            {!hideHeader ? (
              <thead>
                <div className="thead">
                  <tr className="d-flex">
                    {table.getHeaderGroups().map((headerGroup) => (
                      <SortableContext
                        items={columnOrder}
                        strategy={horizontalListSortingStrategy}
                      >
                        {headerGroup.headers.map((header) => (
                          <DraggableTableHeader
                            key={header.id}
                            header={header}
                          />
                        ))}
                      </SortableContext>
                    ))}
                  </tr>
                </div>
              </thead>
            ) : null}
            <tbody>
              <div className="tbody">
                {table.getRowModel().rows.map((row) => (
                  <tr
                    className={`${
                      row.getIsSelected() ? selectedRowClass : ""
                    } cursor-pointer d-flex`}
                    key={row.id}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <SortableContext
                        key={cell.id}
                        items={columnOrder}
                        strategy={horizontalListSortingStrategy}
                      >
                        <DragAlongCell
                          row={row}
                          onCellClick={onRowClick}
                          key={cell.id}
                          cell={cell}
                        />
                      </SortableContext>
                    ))}
                  </tr>
                ))}
                {!isFetching && !data.length ? (
                  <div className="my-4 text-center">{noDataText}</div>
                ) : null}
              </div>
            </tbody>
            {renderFooter ? (
              <tfoot>
                {table.getFooterGroups().map((footerGroup) => (
                  <tr className="d-flex" key={footerGroup.id}>
                    {footerGroup.headers.map((header) => (
                      <td
                        className={`show-ellipses ${get(
                          header,
                          "column.columnDef.className",
                          ""
                        )}`}
                        style={{
                          width: header.column.getSize(),
                          flex: `${header.column.getSize()} 0 auto`,
                        }}
                        key={header.id}
                      >
                        {flexRender(
                          header.column.columnDef.footer,
                          header.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tfoot>
            ) : null}
          </table>
          {isFetching ? (
            <div className="graph-loader">
              <div className="spinner-border" />
            </div>
          ) : null}
        </div>
      </div>
      {hidePagination ? null : (
        <div className="row">
          <div className="filterthree">
            <div className="float-end">
              <Button
                className="me-2 minwidth-94px"
                data-cy="previous"
                disabled={isFetching || currPage === 1}
                onClick={setPrevPage}
              >
                {getLocaleString("common.previous", "Previous")}
              </Button>
              {showPaginationSwitch ? (
                <SimplePagination
                  page={currPage}
                  totalPages={totalPages}
                  onSelectPage={setPage}
                />
              ) : null}
              <Button
                disabled={
                  isFetching ||
                  paginationNextButtonStatus(prevPage, nextPage, data.length)
                }
                className="minwidth-94px"
                onClick={setNextPage}
                data-cy="next"
              >
                {getLocaleString("common.next", "Next")}
              </Button>
            </div>
          </div>
        </div>
      )}
    </DndContext>
  );
}

export default DNDTable;
