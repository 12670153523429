import { ReducersTypes } from "constants/ReducersTypes";
import moment from "moment";

export const initActivityState = {
  activityList: [],
  isFetching: false,
  filters: {
    from_date: moment().subtract(7, "days").format("MM/DD/YYYY"),
    to_date: moment().format("MM/DD/YYYY"),
    assigned_to: "",
    label: "Past 7 Days",
  },
};

export const activity = (state = { ...initActivityState }, action) => {
  switch (action.type) {
    case ReducersTypes.FETCH_ACTIVITY_LIST:
      return {
        ...state,
        activityList: action.payload.activityList || state.activityList,
        isFetching: action.payload.isFetching,
        filters: {
          ...state.filters,
          ...(action.payload.filters || state.filters),
        },
      };
    default:
      return state;
  }
};

export default activity;
