import { ReducersTypes } from "constants/ReducersTypes";
import moment from "moment";
import { enableDefaultAllDateFilter} from "handlers/features";
import { getUXSettingsFromStorage } from "utils";
import { getDateByRangeInterval } from "utils/dates";

const dateRange = {
    from_date: enableDefaultAllDateFilter() ? moment(0).format("MM/DD/YYYY") : moment().subtract(365, "days").format("MM/DD/YYYY"), 
    to_date: enableDefaultAllDateFilter() ? moment().add(1, "year").format("MM/DD/YYYY") : moment().format("MM/DD/YYYY")
};

let savedFilters = getUXSettingsFromStorage("ANALYTICS_SMS_FILTERS");
if (savedFilters && savedFilters.interval) {
  const { interval, from_date, to_date } = savedFilters;
  const updatedKeys = {};
  const [sd, ed] = getDateByRangeInterval(
    interval === "All" ? "AllToDate" : interval,
    from_date,
    to_date
  );
  savedFilters = {
    ...savedFilters,
    ...updatedKeys,
    from_date: sd,
    to_date: ed,
  };
}

export const initialSmsListFilters = {
    page: 1,
    sort_by: "sent_at",
    sort_order: "desc",
    ...dateRange,
    invoice_id: "",
    status: "",
    client_supplier_rel_id: "",
};

const initialState = {
    listData: [],
    sendersList: [],
    isFetching: false,
    totalSMS: 0,
    filters: {
        ...initialSmsListFilters,
        ...savedFilters,
        ...dateRange,
        searchList: [],
    },
    exportedData: [],
    showexport: false,
    exporting: false,
};

const smsList = (state = initialState, { type, payload }) => {
    switch(type) {
        case ReducersTypes.SET_SMS_LIST:
            return {
                ...state,
                ...payload.data
            };
        case ReducersTypes.SET_SMS_LIST_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...payload.data
                }
            }
        case ReducersTypes.RESET_SMS_LIST:
            return {
                ...initialState,
                sendersList: state.sendersList,
                filters: {
                    ...initialState.filters
                }
            }
        default:
            return state;
    }
};

export default smsList;