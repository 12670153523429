import { handleAPIError } from "handlers/setters";
import { generateQueryString } from "utils/query";
import get from "lodash/get";
import moment from "moment";
import { getPastAndFutureDates, betweenDays } from "utils/dates";
import { trimText } from "utils/strings";
import { setReducerData, removeEmptyValueFromObject } from "utils";
import { instanceWithRetry } from "actions/axiosInstance";
import { ReducersTypes } from "constants/ReducersTypes";
import { sendExportedReport } from "actions/global";
import TokenManager from "utils/TokenManager";

export const setCustomerListData = (data) => (dispatch) =>
  dispatch(setReducerData(ReducersTypes.CUSTOMER_LIST, data));

export const setCustomerListFilters = (data) => (dispatch) =>
  dispatch(setReducerData(ReducersTypes.SET_NEW_CUSTOMER_FILTER, data));

export const resetCustomerListFilters = (data) => (dispatch) =>
  dispatch(setReducerData(ReducersTypes.RESET_NEW_CUSTOMER_FILTER, data));

export const getListingData = (params) => {
  const {
    page,
    mutate,
    tag_ids,
    sort_by,
    to_date,
    category,
    is_export,
    from_date,
    activeKey,
    sort_order,
    risk_score,
    spendCategory,
    selected_arrep,
    showAgingBuckets,
    send_custom_fields,
    appliedCustomFilters,
    include_all_customers,
    arAgingBuckets = [],
    client_customer_rel_id,
    send_child_customer_rels,
    include_custom_field_ids,
    ENTITY_LEVEL_WORKFLOW_ENABLED,
  } = params;

  const excluded_tag_id = getAgingTag();
  const [due_dates, notdue_dates] = getPastAndFutureDates(from_date, to_date);
  const days_to_subtract =
    due_dates[0] && due_dates[1]
      ? betweenDays({
          startdate: moment(from_date, "MM/DD/YYYY"),
          enddate: moment(to_date, "MM/DD/YYYY"),
        })
      : 0;

  const agingBucketRequestObject = arAgingBuckets.map(
    ({ min_days, max_days }) => ({
      to_date:
        due_dates[0] && days_to_subtract >= max_days
          ? moment().subtract(min_days, "days").format("MM/DD/YYYY")
          : "",
      label_name: `hierarchical_overdue_balance_${min_days}_${max_days}`,
      status: "open",
      from_date:
        max_days && due_dates[0] && days_to_subtract >= max_days
          ? moment().subtract(max_days, "days").format("MM/DD/YYYY")
          : !max_days
          ? due_dates[0]
          : "",
    })
  );

  let postobj = [
    {
      to_date: to_date,
      label_name: "total_balance",
      status: "open",
      subtract_credit_and_unapplied_payments: 1,
      from_date: from_date,
    },
    {
      to_date: due_dates[1],
      label_name: "overdue_balance",
      status: "open",
      from_date: due_dates[0],
    },
    ...(showAgingBuckets ? agingBucketRequestObject : []),
    // {
    //   to_date:
    //     showAgingBuckets && due_dates[0] && days_to_subtract >= 30
    //       ? moment().subtract(1, "days").format("MM/DD/YYYY")
    //       : "",
    //   label_name: "hierarchical_overdue_balance_1_30",
    //   status: "open",
    //   from_date:
    //     showAgingBuckets && due_dates[0] && days_to_subtract >= 30
    //       ? moment().subtract(30, "days").format("MM/DD/YYYY")
    //       : "",
    // },
    // {
    //   to_date:
    //     showAgingBuckets && due_dates[0] && days_to_subtract >= 60
    //       ? moment().subtract(31, "days").format("MM/DD/YYYY")
    //       : "",
    //   label_name: "hierarchical_overdue_balance_30_60",
    //   status: "open",
    //   from_date:
    //     showAgingBuckets && due_dates[0] && days_to_subtract >= 60
    //       ? moment().subtract(60, "days").format("MM/DD/YYYY")
    //       : "",
    // },
    // {
    //   to_date:
    //     showAgingBuckets && due_dates[0] && days_to_subtract >= 90
    //       ? moment().subtract(61, "days").format("MM/DD/YYYY")
    //       : "",
    //   label_name: "hierarchical_overdue_balance_60_90",
    //   status: "open",
    //   from_date:
    //     showAgingBuckets && due_dates[0] && days_to_subtract >= 90
    //       ? moment().subtract(90, "days").format("MM/DD/YYYY")
    //       : "",
    // },
    // {
    //   to_date:
    //     showAgingBuckets && due_dates[0] && days_to_subtract >= 91
    //       ? moment().subtract(91, "days").format("MM/DD/YYYY")
    //       : "",
    //   label_name: "hierarchical_overdue_balance_90",
    //   status: "open",
    //   from_date: showAgingBuckets && days_to_subtract >= 91 ? due_dates[0] : "",
    // },
    {
      from_date: notdue_dates[0],
      to_date: notdue_dates[1],
      label_name: "outstanding_balance",
      status: "open",
    },
  ];

  if (activeKey === "totalAR") {
    const categoryobj = { l30: "2", g30: "4" };
    postobj = [
      {
        from_date: from_date,
        to_date: to_date,
        label_name: "total_amount_paid",
        status: "closed",
        category: categoryobj[category],
      },
    ];
  }

  const updatedParams = {};
  if (selected_arrep) {
    updatedParams.role_name = "arrep";
    updatedParams.user_role_ids = selected_arrep;
  }
  if (["araging", "araginginsight"].includes(activeKey)) {
    if (tag_ids && tag_ids.length) {
      updatedParams.tag_ids = tag_ids;
    }
    if (include_custom_field_ids) {
      updatedParams.include_custom_field_ids = include_custom_field_ids;
    }
    if (spendCategory) {
      const categoryIdKey =
        spendCategory.includes(",0") || spendCategory === "0"
          ? "customer_category_id_not_in"
          : "customer_category_id";
      updatedParams[categoryIdKey] = spendCategory;
    }
  } else if (activeKey === "spendingCategory" && spendCategory) {
    const categoryIdKey =
      spendCategory.includes(",0") || spendCategory === "0"
        ? "customer_category_id_not_in"
        : "customer_category_id";
    updatedParams[categoryIdKey] = spendCategory;
  } else if (activeKey === "riskprofile" && risk_score) {
    updatedParams.risk_score = risk_score;
  }
  if (excluded_tag_id) {
    updatedParams.excluded_tag_id = excluded_tag_id.toString();
  }
  if (client_customer_rel_id) {
    updatedParams.client_customer_rel_id = client_customer_rel_id;
  }
  if (Object.keys(appliedCustomFilters).length) {
    let masterId = [];
    Object.keys(appliedCustomFilters).forEach((d) => {
      const filter = appliedCustomFilters[d];
      let flag = false;
      Object.keys(filter).forEach((f) => {
        if (f !== "custom_field_master_id" && filter[f]) {
          updatedParams[f] = filter[f];
          flag = true;
        }
      });
      if (flag) {
        masterId.push(d);
        flag = false;
      }
    });
    if (masterId.length) {
      updatedParams["custom_field_master_id"] = masterId.join();
    }
  }
  postobj = postobj.map((d) => ({
    ...d,
    ...updatedParams,
  }));

  const queryParams = {
    page,
    sort_by: getSortBy(sort_by, postobj),
    per_page: 20,
    sort_order,
    with_notes: 1,
    send_custom_fields,
    mutate,
    is_export: is_export || "",
    include_all_customers: include_all_customers ? "0" : "1",
    send_child_customer_rels,
    ENTITY_LEVEL_WORKFLOW_ENABLED: ENTITY_LEVEL_WORKFLOW_ENABLED || "",
  };
  if (get(params, "isEmailExport", false)) {
    return sendExportedReport(
      removeEmptyValueFromObject({
        ...queryParams,
        filters: postobj.filter((x) => x.from_date || x.to_date),
        export_type: "csv",
        page_type: "customer",
      })
    );
  }
  return instanceWithRetry.post(
    `arinvoice/grouped${generateQueryString(queryParams)}`,
    {
      filters: postobj.filter((x) => x.from_date || x.to_date),
    }
  );
};

export const searchCustomer = (text) =>
  instanceWithRetry
    .get(`customer/rel/search?name=${trimText(text)}&per_page=100`)
    .catch((e) => handleAPIError(e));

export const fetchAccounts = () => {
  const account_type = get(TokenManager.getPaymentAccountTypeData(), "account_type", "");
  const isStripe = ["STANDARD", "EXPRESS", "STRIPE"].includes(account_type);
  const url = `payment/account${generateQueryString({ account_type : isStripe ? "" : account_type })}`;
  return instanceWithRetry.get(url).catch((e) => handleAPIError(e));
};

export const getAgingTag = () => {
  const allTags = localStorage.getItem("tag_list")
    ? JSON.parse(localStorage.getItem("tag_list"))
    : [];
  const excluded_tag_id = allTags.find((d) =>
    ["EXCLUDE_IN_AGING"].includes(d.name)
  );
  if (excluded_tag_id) {
    return get(excluded_tag_id, "id", "");
  }
  return false;
};

export const getCustomFieldDropdownData = (data, callback) => {
  return instanceWithRetry
    .get(`/customfield${generateQueryString(data)}`)
    .then((response) => {
      const mappedValues = response.data.result
        .filter((d) => d)
        .map((d) => ({ label: d, value: d }));
      callback && callback(mappedValues);
      return {
        id: data.custom_field_master_id,
        values: mappedValues,
      };
    })
    .catch((e) => {
      handleAPIError(e);
      callback && callback([]);
      return {
        id: data.custom_field_master_id,
        values: [],
      };
    });
};

export const getCustomFieldFilters = () =>
  instanceWithRetry
    .get("customfields?model_name=ClientCustomerRelationship")
    .then(async (res) => {
      const result = res.data.result;
      const masterFieldsData = result
        .filter((d) => d.field_type === "STRING")
        .map((d) => {
          return getCustomFieldDropdownData({
            custom_field_master_id: d.id,
            per_page: 10,
          });
        });
      const masterFieldValues = (
        masterFieldsData.length ? await Promise.all(masterFieldsData) : []
      ).reduce((acc, cur) => ({ ...acc, [cur.id]: cur.values }), {});
      return {
        customFields: result,
        masterFieldValues,
      };
    })
    .catch((e) => handleAPIError(e));

export const scheduleWorkflow = (workflowId, status) => {
  const requestObj = {
    is_enabled: status,
  };
  if (status) {
    requestObj.workflow_schedule_type = "IMMEDIATE";
  }
  return instanceWithRetry
    .post(`arworkflow?id=${workflowId}&mutate=1`, requestObj)
    .catch((e) => handleAPIError(e));
};

export const fetchWorkflowSteps = (params = {}) =>
  instanceWithRetry.get(`arworkflow/sequence${generateQueryString(params)}`).catch((e) => handleAPIError(e));

export const bulkAssignWorkflow = (requestObject) =>
  instanceWithRetry
    .post("arworkflow/bulk?mutate=1", requestObject)
    .catch((e) => {
      handleAPIError(e, true);
      return e;
    });

export const bulkAssignCustomerARrep = (postData, isDelete = false) => {
  return instanceWithRetry[isDelete ? "delete" : "post"](
    "customer/bulk?mutate=1",
    postData
  ).catch((e) => handleAPIError(e));
};

export const assignWorkflow = (props) => {
  const {
    sequenceId,
    client_customer_rel_id,
    workflowId,
    corporate_entity_id,
  } = props;

  const requestObj = {
    workflow_name: sequenceId,
    workflow_sequence_id: sequenceId,
    workflow_schedule_type: "IMMEDIATE",
    client_customer_rel_id,
  };
  if (sequenceId === "0") {
    delete requestObj.workflow_name;
    delete requestObj.workflow_sequence_id;
    requestObj.client_customer_rel_id = 0;
  }
  if (corporate_entity_id) {
    requestObj.corporate_entity_id = corporate_entity_id;
  }

  const apiUrl = workflowId
    ? `arworkflow?id=${workflowId}&mutate=1`
    : "arworkflow?mutate=1";
  return instanceWithRetry
    .post(apiUrl, requestObj)
    .catch((e) => handleAPIError(e, true));
};

function getSortBy(sort_by, postobj) {
  const updatedSortBy =
    postobj.some((e) => e.label_name === sort_by) ||
    ![
      "total_balance",
      "overdue_balance",
      "total_amount_paid",
      "outstanding_balance",
      "hierarchical_overdue_balance_90",
      "hierarchical_overdue_balance_1_30",
      "hierarchical_overdue_balance_30_60",
      "hierarchical_overdue_balance_60_90",
    ].includes(sort_by) ||
    !postobj
      ? sort_by
      : get(postobj, "[0].label_name", "");

  return updatedSortBy;
}

export const getLatestNote = (notes) => {
  if (!notes || notes.length === 0) {
    return "-";
  }

  const latestNote = notes.reduce((latest, current) => {
    const currentDate = new Date(current.date);
    const latestDate = new Date(latest.date);

    return currentDate > latestDate ? current : latest;
  }, notes[0]);

  return latestNote.date;
};
