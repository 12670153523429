import React from "react";

const CodeSplitLoader = () => (
  <div className="row px-15" id="dashboardBody">
    <div className="col">
      <div className="">
        <div className="bg-white d-flex justify-content-center middle-panel-height">
          <div
            className="spinner-border align-self-center"
            style={{
              width: "3em",
              height: "3em",
            }}
          />
        </div>
      </div>
    </div>
  </div>
);
export default CodeSplitLoader;
