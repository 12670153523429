import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import login from "containers/login";
import get from "lodash/get";
import ForgotPassword from "containers/forgetPassword";
import resetPassword from "containers/resetPassword";
import TokenManager from "utils/TokenManager";
import NavWrapper from "./navWrapper";
import ConfirmationBox from "components/ConfirmationBox";
import { fetchEmailTemplateList } from "containers/settings/receivables/ARworkflow/actions";
import { check_ux_properties, syncUserSettings, ux_refresh, uxempty } from "handlers/ux";
import fe_config from "constants/Configs";
import EmailCreator from "components/Email/EmailCreator";
import Preview from "components/Preview";
import ErrorBoundary from "components/ErrorBoundry";
import { trackEvent } from "utils/loggly-tracker";
import getNewToken from "utils/token";
import { refresh_tag } from "components/Tag";
import CustomerLoginRedirect from "containers/customer-login/login";
import VendorLoginRedirect from "containers/vendors/invoicing-portal/login";
import { getClientData, getPayrollSettings, getPaymentAccountType, getAccountTypes } from "actions/client";
import { getUsersList } from "containers/dashboard/TaskManagement/actions";
import { getConfigSettings, getAgingBucket } from "containers/settings/action";
import { createScriptTag } from "utils/createScriptTag";
import { getInvoicingFields } from  "containers/settings/payables/actions";
import { getUserSettings, refreshClients } from "actions/user";
import { removeEmptyValueFromObject } from "utils";
import { loadMomentLocale } from  "utils/dates";

const openRoutes = ["/customer/rel/", "receivables", "payables", "/settings/profile", "/vendors/rel/"]; // routes which are open for customer & vendor

const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      render={(props) =>
        TokenManager.get() && localStorage.getItem("loggedInUserData") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
      {...rest}
    />
  );
};

let isAfterWarnEvent = false;
let API_IN_PROGRESS = false;
const refreshTokenTimeout = 300000; // 5 mins
class Root extends Component {
  constructor(props) {
    super(props);
    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];
    this.setRefreshTimeout = this.setRefreshTimeout.bind(this);
    this.storageChange = this.storageChange.bind(this);
    this.lastUserAction = null;
    this.minTimeout = null;
    this.lastEventTime = null;
    this.warnTimeout = null;
    this.isTokenRefreshedOnce = false;
    window.addEventListener("storage", this.storageChange, false);
    this.state = {
      path: "",
    };
    this.events.forEach(d => window.addEventListener(d, this.setRefreshTimeout));
  }

  componentWillMount() {
    this.setState({
      path: window.location.pathname,
    });
  }

  componentDidMount() {
    loadMomentLocale(localStorage.getItem("locale") || "en");
    if (TokenManager.get() && localStorage.getItem("loggedInUserData")) {
      try {
        document.getElementById("root").addEventListener("DOMNodeInserted", (e) => {
          if(e) {
            document.querySelectorAll("*").forEach((item) => item.addEventListener("click", this.logClickEvents));
          }
        });
      } catch(e){}
      
      if (!get(this.props, "clients", []).length) {
        getUserSettings(refreshClients);
      }
      this.setRefreshTimeout();
      getConfigSettings();
      getAgingBucket({ bucket_type: "ar" });
      getAgingBucket({ bucket_type: "ap" });
      this.props.getClientData();
      this.props.getUsersList();
      if (uxempty()) {
        ux_refresh();
      }
      refresh_tag();
      this.props.getPayrollSettings();
      getPaymentAccountType().then(createScriptTag);
      if (!TokenManager.isCustomer() && !TokenManager.getIsAP_REP() && !TokenManager.getIsAR_REP() && !TokenManager.isReadOnlyARUser()) {
        this.props.fetchEmailTemplateList();
        getInvoicingFields();
        if (!TokenManager.isVendor()) {
          getAccountTypes();
        }
      }
      check_ux_properties();
    }
  }

  // get new token when timeout
  refreshToken = (flag) => {
    if (isAfterWarnEvent && !this.props.refresh_header) {
      // if entity or client is changed then don't call this function
      isAfterWarnEvent = false;
      if (API_IN_PROGRESS) {
        return;
      }
      API_IN_PROGRESS = true;
      const clientId = TokenManager.getClientId();
      const corpId = TokenManager.getCorporateEntityId();
      const hierarchical_corporate_entity_list_context = localStorage.getItem("hierarchical_corporate_entity_list_context");
      const headers = {
        client_id_context: clientId
      };
      
      if (TokenManager.getClientCustomerRelId()) {
        headers['client_customer_rel_id_context'] = TokenManager.getClientCustomerRelId();
      }
      if (TokenManager.getClientSupplierRelId()) {
        headers['client_supplier_rel_id_context'] = TokenManager.getClientSupplierRelId();
      }
      if (TokenManager.getARrepContext()) {
        headers['client_customer_ar_rep_list'] = TokenManager.getARrepContext();
      }
      if (hierarchical_corporate_entity_list_context && hierarchical_corporate_entity_list_context !== "null") {
        headers['hierarchical_corporate_entity_list_context'] = hierarchical_corporate_entity_list_context
      }
      if (corpId) {
        headers['corporate_entity_id_context'] = corpId;
      }

      getNewToken({ headers: removeEmptyValueFromObject(headers) }, () => {
        API_IN_PROGRESS = false;
        isAfterWarnEvent = false;
        if (!this.isTokenRefreshedOnce) {
          this.clearSetTimeout();
        }
        syncUserSettings();
        this.setRefreshTimeout(flag);
      });
    }
  };

  validateTokenTimeout = () => {
    this.lastEventTime = null;
    isAfterWarnEvent = true;
    if (!this.isTokenRefreshedOnce) {
      if (!document.hasFocus()) {
        this.refreshToken(true);
        this.isTokenRefreshedOnce = true;
      } else {
        this.setRefreshTimeout(true);
      }
    }
  };

  setRefreshTimeout = (flag) => {
    API_IN_PROGRESS = false;
    isAfterWarnEvent = false;
    const tokenExpiryTime = TokenManager.getExpiredAt();
    if (!tokenExpiryTime) {
      return;
    }
    const currentTime = Math.round(new Date().getTime());
    this.lastEventTime = currentTime;
    const reduceRefreshTime =
      fe_config.MODE === "DEV" && localStorage.getItem("refreshTokenTime")
        ? Number(localStorage.getItem("refreshTokenTime")) * 60 * 1000
        : 0; // for testing in local we can reduce refresh token timeout
    const REFRESH_TOKEN_TIME = reduceRefreshTime
      ? reduceRefreshTime
      : refreshTokenTimeout; // refresh before 5 mins
    const warnTime = tokenExpiryTime - currentTime - REFRESH_TOKEN_TIME;
    if (!this.warnTimeout) {
      this.warnTimeout = setTimeout(() => {
        isAfterWarnEvent = true;
        this.refreshToken(true);
        this.isTokenRefreshedOnce = true;
      }, warnTime);
    }
    if (typeof flag == "boolean") {
      return;
    }
    if (this.minTimeout) clearTimeout(this.minTimeout);
    this.isTokenRefreshedOnce = false;
    this.minTimeout = setTimeout(() => {
      this.validateTokenTimeout();
    }, refreshTokenTimeout);
  };

  
  logClickEvents = (e) => {
    const dataAttributeValue = get(e, 'target.dataset.tlabel', '');
    const eventObj = {
      clicked_element: dataAttributeValue || e.target.outerText || e.target.textContent || e.target.outerHTML,
    };
    const existingElement =
      localStorage.getItem("lastAction") &&
      JSON.parse(localStorage.getItem("lastAction"));
      
    if (
      eventObj.clicked_element !==
        (existingElement && existingElement.clicked_element)
    ) {
      const message = `Clicked on ${eventObj.clicked_element}`;
      trackEvent(message, 'click');
    }
    localStorage.setItem("lastAction", JSON.stringify(eventObj));
  };

  storageChange(event) {
    if (event.key === "REFRESHED") {
      window.location.reload(true);
    }
    if (event.key === "loggedout") {
      window.sessionStorage.setItem("oldUrl", window.location.href);
      window.sessionStorage.setItem("oldUser", event.newValue);
      window.location.pathname !== "/login" && window.open("/login", "_self");
    }
    if (event.key === "loggedin") {
      if (event.newValue === window.sessionStorage.getItem("oldUser")) {
        const URL = window.sessionStorage.getItem("oldUrl");
        if (URL) {
          window.open(URL, "_self");
        }
      } else {
        window.close();
      }
    }
  }

  clearSetTimeout() {
    if (this.warnTimeout) {
      clearTimeout(this.warnTimeout);
      this.warnTimeout = null;
    }
  }

  componentWillUnmount() {
    this.destroy();
  }

  destroy() {
    this.clearSetTimeout();

    for (var i in this.events) {
      window.removeEventListener(this.events[i], this.resetTimeout);
    }
    try {
      document.getElementById("root").removeEventListener("DOMNodeInserted", (e) => {
        if (e) {
          document.querySelectorAll("*").forEach((item) => item.removeEventListener("click", this.logClickEvents));
        }
      });
    }catch(e) {}
  }

  render() {
    const openRouteAvailable = openRoutes.some(d => this.state.path.includes(d)) ? this.state.path  : "";
    const isVendorRouteAvailable = openRouteAvailable.includes("vendors");

    return (
      <ErrorBoundary>
        <ConfirmationBox />
        <EmailCreator />
        <Preview />
        <Router>
          <Switch>
            <Route exact path="/login" component={login} />
            {/* {<Route exact path="/signup" component={signup} />} */}
            <Route
              exact path="/forgot-password"
              render={(props) => <ForgotPassword operation="forgot-password" {...props} />}
            />
            <Route
              exact path="/user-signup"
              render={(props) => <ForgotPassword text="user-signup" {...props} />}
            />
            <Route exact path="/reset-password" component={resetPassword} />
            {!TokenManager.get() && openRouteAvailable && (
              <Route
                exact
                path={openRouteAvailable}
                component={isVendorRouteAvailable ? VendorLoginRedirect : CustomerLoginRedirect}
              />
            )}
            <ProtectedRoute path="/" component={NavWrapper} />
          </Switch>
        </Router>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state) => ({
  refresh_header: state.header.refresh_header,
  clients: state.user.clients
});

const mapDispatchToProps = { getClientData, getPayrollSettings, getUsersList, fetchEmailTemplateList };

export default connect(mapStateToProps, mapDispatchToProps)(Root);
