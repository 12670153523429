/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import get from "lodash/get";
import "../containers/settings/profile/makePhoneCall/index.css";
import { connect } from "react-redux";
import Draggable from "react-draggable";
import {
  resetCall,
  getPhoneList,
  setPhoneData,
} from "../containers/settings/profile/makePhoneCall/actions";
import states from "containers/settings/profile/makePhoneCall/states";
import { setCustomerDetails } from "containers/customers/details/actions";
import dial from "assets/images/dial.png";
import Button from "./Button";
import { getLocaleString } from "utils/localization/locale";

function CallDialog(props) {
  const {
    showDialog,
    call_status,
    muted,
    device,
    counter,
    connection,
    callInterval,
  } = props.phoneCall;
  let callInterval3;
  let localCounter = counter;

  useEffect(() => {
    setCustomerDetails({
      showCallPopUp: false,
    });
    if (call_status === states.ON_CALL) {
      return () => {
        if (callInterval) {
          clearInterval(callInterval);
          setPhoneData({
            callInterval: "",
          });
        }
      };
    }
  }, [props.phoneCall]);

  useEffect(() => {
    device &&
      device.on("disconnect", () => {
        clearInterval(callInterval);
        setPhoneData({
          callInterval: "",
          extensionCall: "",
        });
      });

    if (call_status === "IN_PROGRESS") {
      callInterval3 = setInterval(() => {
        props.setPhoneData({
          counter: localCounter + 1,
        });
        localCounter++;
      }, 1000);
    } else {
      clearInterval(callInterval3);
    }

    return () => {
      clearInterval(callInterval3);
    };
  }, [showDialog, call_status]);

  function str_pad_left(string, pad, length) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  }

  const getStatus = (state) => {
    switch (state) {
      case "RINGING":
        return (
          <div className="lds-ellipsiss mt-2 ms-n3">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        );
      case "IN_PROGRESS":
        var minutes = Math.floor(counter / 60);
        var seconds = counter - minutes * 60;
        return (
          <div>
            {str_pad_left(minutes, "0", 2) +
              ":" +
              str_pad_left(seconds, "0", 2)}
          </div>
        );
      default:
        return (
          <div className="lds-ellipsiss mt-2 ms-n3">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        );
    }
  };

  const handleMute = () => {
    connection.mute(!muted);
    props.setPhoneData({
      muted: !muted,
      connection: connection,
    });
  };

  const handleHangup = () => {
    localCounter = 0;
    clearInterval(callInterval);
    setPhoneData({
      callInterval: "",
    });
    device.disconnectAll();
  };

  return (
    <Draggable>
      <div className="callDialog">
        <div>
          <div
            className="blurScreen mt-2"
            onMouseEnter={(e) => {
              e.stopPropagation();
              const element = document.querySelector(".blurScreen");
              element.style.opacity = 0.9;
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
              const element = document.querySelector(".blurScreen");
              element.style.opacity = 0;
            }}
          >
            <img
              src={dial}
              alt="Keypad"
              title="Keypad"
              onClick={(e) => {
                setPhoneData({
                  flipCard: "showOnDialPad",
                });
                props.history.push("/settings/profile#phonecall");
              }}
            />
          </div>
          <div>
            <i className="fa fa-user" aria-hidden="true"></i>
            <div className="mt-2">{get(connection, "message.To", "User")}</div>
            {getStatus(call_status)}
          </div>
        </div>
        <div>
          <Button
            variant="success"
            onClick={handleMute}
            data-tlabel={muted ? "Unmute" : "Mute"}
            startIcon={
              muted ? (
                <i
                  title={getLocaleString("common.unmute", "Unmute")}
                  className="fa fa-microphone-slash"
                  aria-hidden="true"
                ></i>
              ) : (
                <i
                  title={getLocaleString("common.Mute", "Mute")}
                  className="fa fa-microphone"
                  aria-hidden="true"
                ></i>
              )
            }
          >
            {muted
              ? getLocaleString("common.unmute", "Unmute")
              : getLocaleString("common.Mute", "Mute")}
          </Button>
          <Button
            variant="danger"
            onClick={handleHangup}
            data-tlabel="End Call"
            startIcon={<i className="fa fa-phone" aria-hidden="true"></i>}
            title="End Call"
          >
            {getLocaleString("common.end", "End")}
          </Button>
        </div>
      </div>
    </Draggable>
  );
}

const mapStateToProps = (state) => ({
  phoneCall: state.phoneCall,
});

const mapDispatchToProps = {
  resetCall,
  getPhoneList,
  setPhoneData,
};

export default connect(mapStateToProps, mapDispatchToProps)(CallDialog);
