import { ReducersTypes } from "../../constants/ReducersTypes";
import { instanceWithRetry } from "actions/axiosInstance";
import { store } from "store";
import { handleAPIError } from "handlers/setters";
import { initialConfigSettings } from "reducers/global";
import get from "lodash/get";
import { generateQueryString } from "utils/query";

const dispatch = store.dispatch;
const setData = (type, data) => ({
  type,
  payload: {
    ...data,
  },
});

export const updateUserData = (data) => ({
  type: ReducersTypes.SETTINGS,
  payload: data,
});

export function getEntityLabels() {
  instanceWithRetry
    .post("cf/lable/rename", {
      lables: [
        "AP",
        "AR",
        "Journal Inflow",
        "Journal Outflow",
        "Purchase Order",
        "Order Backlog",
        "Cash Inflow",
        "Cash Outflow",
        "Net Operating Cashflow",
        "Ending Cash Balance",
        "ABL",
        "Borrowing Base",
        "Liquidity",
        "Non Operating Cashflow",
        "Non Invoiced Expenses",
        "New Sales",
        "Projected COGS",
        "Payroll, Taxes & Benefits (401K)",
        "Payment Plans & Partial Payments",
        "Customer Payment Plans",
        "AR Credits",
        "AP Credits",
        "Vendor Payments"
      ],
    })
    .then((res) => {
      dispatch({
        type: ReducersTypes.LABEL_SETTINGS,
        payload: {
          entityLabels: res.data.result,
        },
      });
    });
}

export const updateSettings = (data) => dispatch(setData(ReducersTypes.CONFIG_SETTINGS, data));

export const saveConfigSettings = async (obj) => {
  await instanceWithRetry
    .put(
      "client/config/setting?mutate=1",
      JSON.stringify({
        config_setting: obj,
      })
    )
    .catch((e) => handleAPIError(e));
};

export const getSpecificConfigSettings = (description = "", userId) => {
  if (!description) return;
  const {
    csvSettings,
    clientFeatures,
    generalSettings,
    customerSettings,
    workflowSettings,
    statementSettings,
    invoiceLineSettings,
    workflowInvoiceSettings,
  } = initialConfigSettings;
  const initialDataMapping = {
    "customer setting": { value: customerSettings, name: "customerSettings" },
    "statement setting": {
      value: statementSettings,
      name: "statementSettings",
    },
    "invoice line setting": {
      value: invoiceLineSettings,
      name: "invoiceLineSettings",
    },
    "workflow setting": { value: workflowSettings, name: "workflowSettings" },
    "general setting": { value: generalSettings, name: "generalSettings" },
    "csv setting": { value: csvSettings, name: "csvSettings" },
    "client setting": { value: clientFeatures, name: "clientFeatures" },
    "invoice setting": {
      value: workflowInvoiceSettings,
      name: "workflowInvoiceSettings",
    },
  };

  let url = `/client/config/setting?description=${description}`;
  if (userId) {
    url += `&user_id=${userId}`; 
  }
  return instanceWithRetry
    .get(url)
    .then((res) => {
      const res1 = res.data.result;
      let updatedSettings = get(
        initialDataMapping,
        `${[description]}.value`,
        {}
      );
      res1.forEach((element) => {
        updatedSettings[element.name] = element.type === "STRING" ? element.value || "" : Number(element.value);
      });
      updateSettings({
        [get(initialDataMapping, `${[description]}.name`, "")]: updatedSettings,
      });
      return updatedSettings;
    })
    .catch((e) => handleAPIError(e));
};

export const getConfigSettings = (isMutate = false) => {
  instanceWithRetry.get(isMutate ? "client/config/setting?mutate=1" : "client/config/setting")
  .then(response => {
    const data = response.data.result;
    const apiCsvSettings = data.filter(d => d.description === 'csv setting');
    const apiclientFeatures = data.filter(d => d.description === 'client setting');
    const apigeneralSettings = data.filter(d => d.description === 'general setting');
    const apiworkflowSettings = data.filter(d => d.description === 'workflow setting');
    const apicustomerSettings = data.filter(d => d.description === 'customer setting');
    const apistatementSettings = data.filter(d => d.description === 'statement setting');
    const apiinvoiceLineSettings = data.filter(d => d.description === 'invoice line setting');
    const apiworkflowInvoiceSettings = data.filter(d => d.description === 'invoice setting');

    const convertSettingsToObject = (settings = [], initialDataFlag) => {
      const initialData = initialConfigSettings[initialDataFlag];
      const settingsObject = settings.reduce((acc, cur) => {
        acc[cur.name] = cur.type === "STRING" ? cur.value || "" : Number(cur.value);
        return acc;
      }, {})
      return {
        ...initialData,
        ...settingsObject,
      };
    };

    updateSettings({
      csvSettings: convertSettingsToObject(apiCsvSettings, "csvSettings"),
      clientFeatures: convertSettingsToObject(apiclientFeatures, "clientFeatures"),
      generalSettings: convertSettingsToObject(apigeneralSettings, "generalSettings"),
      customerSettings: convertSettingsToObject(apicustomerSettings, "customerSettings"),
      workflowSettings: convertSettingsToObject(apiworkflowSettings, "workflowSettings"),
      statementSettings: convertSettingsToObject(apistatementSettings, "statementSettings"),
      invoiceLineSettings: convertSettingsToObject(apiinvoiceLineSettings, "invoiceLineSettings"),
      initialLineSettings: convertSettingsToObject(apiinvoiceLineSettings, "invoiceLineSettings"),
      initialCustomerSettings: convertSettingsToObject(apicustomerSettings, "customerSettings"),
      initialStatementSettings: convertSettingsToObject(apistatementSettings, "statementSettings"),
      workflowInvoiceSettings: convertSettingsToObject(apiworkflowInvoiceSettings, "workflowInvoiceSettings"),
    });

  }).catch((e) => handleAPIError(e));
};

export const getAgingBucket = (data, cb) => {
  return instanceWithRetry
    .get(`client/config/agingbucket${generateQueryString(data)}`)
    .then(response => {
      const bucketKey = `${get(data, "bucket_type", "")}AgingBuckets`;
      updateSettings({
        [bucketKey]: get(response, "data.result", []).sort((a, b) => a.min_days - b.min_days)
      });
      cb && cb();
      return response;
    })
    .catch((e) => handleAPIError(e));
};

export const postAgingBucket = (data) => {
  return instanceWithRetry
    .put(`client/config/agingbucket?mutate=1`, data)
    .catch((e) => handleAPIError(e, true));
};

export const deleteAgingBucket = (id) => {
  return instanceWithRetry
    .delete(`client/config/agingbucket?id=${id}&mutate=1`)
    .catch((e) => handleAPIError(e, true));
};
