import { ReducersTypes } from "constants/ReducersTypes";
import states from "./states.js";
import { instance } from "actions/axiosInstance";
import get from "lodash/get";
import Toaster from "components/Toaster";

export const resetCall = (device) => (dispatch) => {
  dispatch({
    type: ReducersTypes.SET_CALL_DATA,
    payload: {
      device: device,
      connection: false,
      call_status: states.READY,
      muted: false,
      counter: 0,
      showDialog: false,
      extensionCall: "",
    },
  });
};

export const setPhoneData = (data) => (dispatch) => {
  dispatch({
    type: ReducersTypes.SET_CALL_DATA,
    payload: data,
  });
};

export const getPhoneList =
  (params = false, props = false) =>
  (dispatch) => {
    instance
      .get(`phonecall/list?${params ? params : "per_page=10000"}`)
      .then((res) => {
        if (params)
          dispatch({
            type: ReducersTypes.SET_CALL_DATA,
            payload: {
              device: get(props, "device", null),
              connection: get(props, "connection", null),
              call_status: get(res, "data.result.status", null),
            },
          });
        else
          dispatch({
            type: ReducersTypes.SET_CALL_DATA,
            payload: {
              recentCalls: get(res, "data.result", []),
            },
          });
      })
      .catch(() => {
        Toaster("Unable to fetch recent contacts", "error");
      });
  };

export const getPhoneToken = () => () => {
  return instance.get("phonecall/token");
};

export const setNotes = (client_customer_rel_id, number, call_sid) => () => {
  instance.get(`phonecall/list?call_sid=${call_sid}`).then((res) => {
    instance.put("customer/note?mutate=1", {
      client_customer_rel_id: client_customer_rel_id,
      note: `A call was made to ${number}`,
      phonecall_log_id: get(res, "data.result.id", null),
    });
  });
};
