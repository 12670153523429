import i18n from "./config";

export const getLocaleString = (key, secondaryString, args_dict={}) => {
  if (i18n.exists(key)) {
    return i18n.t(key, args_dict);
  } else if (secondaryString) {
    return secondaryString;
  } else {
    return key || "";
  } 
}
