import { instanceWithRetry } from "actions/axiosInstance";
import Toaster from "components/Toaster";
import { ReducersTypes } from "constants/ReducersTypes";
import get from "lodash/get";
import { store } from "store";
import axios from "axios";
import fe_config from "constants/Configs";

export const initClients = () => {
  const _store = store.getState();
  if (!get(_store, "user.clients[0]", "")) {
    instanceWithRetry.get("client/accessible").then((resp) => {
      setUserData({ clients: resp.data.result });
    });
  }
};

export const initCorporateEntities = () => {
  const _store = store.getState();
  if (!get(_store, "user.corporateEntities[0]", "")) {
    instanceWithRetry.get("user/entities").then((resp) => {
      setUserData({ corporateEntities: resp.data.result });
    });
  }
};

export const refreshClients = (settings) => {
  // const { create_hierarchical_names = 0} = settings || get(store.getState(), 'user.userSettings', {});
  let URL = 'client/accessible?add_corp_entity_hierarchy_level=1';
  // if (create_hierarchical_names) {
  //   URL += '?create_hierarchical_names=1';
  // }
  setUserData({ isFetchingAccessibleClients: true });
  instanceWithRetry.get(URL).then((resp) => {
    setUserData({ clients: resp.data.result, isFetchingAccessibleClients: false });
  }).catch(() => setUserData({ isFetchingAccessibleClients: false }));
};

export const getCorpHierarchy = (corpId, cb) => {
  instanceWithRetry.get(`corp/hierarchy?corp_entity_id=${corpId}`).then((resp) => {
    if (resp.data.result.length > 1) {
      localStorage.setItem("hierarchical_corporate_entity_list_context", resp.data.result);
    }
    cb && cb(resp.data.result);
  });
};

export const refreshCorporateEntities = () => {
  instanceWithRetry.get("user/entities").then((resp) => {
    setUserData({ corporateEntities: resp.data.result });
  });
};

export const getUserStatus = (user) => {
  return axios.post(`${fe_config.API_URL}user/status`,{ username_or_token: user }, { headers: {
    'X-Auth-Token': fe_config.AUTH_TOKEN
  }})
};

export const getUserSettings = (cb, isMutate) => {
  const url = isMutate ? 'user/settings?mutate=1' : 'user/settings';
  instanceWithRetry.get(url).then((resp) => {
    setUserData({ userSettings: resp.data.result });
    cb && cb(resp.data.result)
  });
};

export const updateUserSettings = (reqObj, showNotification) => {
  instanceWithRetry.post("user/settings?mutate=1", reqObj).then(() => {
    getUserSettings('', true);
    if (showNotification) {
      Toaster("Settings updated", "success");
    }
  });
};

export const setUserData = (data) => {
  store.dispatch({
    type: ReducersTypes.USER,
    payload: data,
  });
};

export const setPayrollSettings = (data) => {
  store.dispatch({
    type: ReducersTypes.PAYROLL_SETTINGS_FETCH,
    payload: { 
      payrollSettings: data,
      isFetching: false
    },
  });
};
